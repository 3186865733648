<template>
    <div>
        <div class="container mt-3 mb-3">
            <div class="p-3">
                <img src="./../assets/logo.png" width="220" class="mb-3"/>
            </div>

            <b-card title="Card Title" no-body>
                <b-card-header header-tag="nav">
                    <b-nav card-header tabs>
                        <b-nav-item :to="{ name: 'web_app_application_list'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_application_list'">App
                            list
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('basic_info')" :to="{ name: 'web_app_basic_info'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_basic_info'">Basic info
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('documents')" :to="{ name: 'web_app_documents'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_documents'">Documents
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('photo')" :to="{ name: 'web_app_photo'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_photo'">Photo
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('questions')" :to="{ name: 'web_app_questions'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_questions'">Questions
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('student')" :to="{ name: 'web_app_student'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_student'">Student
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('additional_documents')"
                                    :to="{ name: 'web_app_additional_documents'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_additional_documents'">
                            Additional
                        </b-nav-item>

                        <b-nav-item :disabled="!isTabEnabled('decision')" :to="{ name: 'web_app_decision'}"
                                    v-bind:active="this.$router.currentRoute.name === 'web_app_decision'">Decision
                        </b-nav-item>

                    </b-nav>
                </b-card-header>

                <b-card-body class="text-left">
                    <router-view/>
                </b-card-body>
            </b-card>

            <div class="p-3 text-center">
                <router-link :to="{name : 'dashboard'}"> <b-icon-arrow-left-circle></b-icon-arrow-left-circle>
                        Back to dashboard</router-link>
            </div>

        </div>



    </div>
</template>

<script>
    import Vue from "vue";

    Vue.prototype.$webApp = 'webApp';

    export default {
        name: 'webApp',
        methods: {
            isTabEnabled(name) {
                var enabled = true;
                switch (name) {
                    case 'basic_info':
                        enabled = this.$router.currentRoute.name === 'web_app_decision' ||
                            this.$router.currentRoute.name === 'web_app_additional_documents' ||
                            this.$router.currentRoute.name === 'web_app_student' ||
                            this.$router.currentRoute.name === 'web_app_questions' ||
                            this.$router.currentRoute.name === 'web_app_photo' ||
                            this.$router.currentRoute.name === 'web_app_documents';
                        break;
                    case 'documents':
                        enabled = this.$router.currentRoute.name === 'web_app_decision' ||
                            this.$router.currentRoute.name === 'web_app_additional_documents' ||
                            this.$router.currentRoute.name === 'web_app_student' ||
                            this.$router.currentRoute.name === 'web_app_questions' ||
                            this.$router.currentRoute.name === 'web_app_photo';
                        break;
                    case 'photo':
                        enabled = this.$router.currentRoute.name === 'web_app_decision' ||
                            this.$router.currentRoute.name === 'web_app_additional_documents' ||
                            this.$router.currentRoute.name === 'web_app_student' ||
                            this.$router.currentRoute.name === 'web_app_questions';
                        break;
                    case 'questions':
                        enabled = this.$router.currentRoute.name === 'web_app_decision' ||
                            this.$router.currentRoute.name === 'web_app_additional_documents' ||
                            this.$router.currentRoute.name === 'web_app_student';
                        break;
                    case 'student':
                        enabled = this.$router.currentRoute.name === 'web_app_decision' ||
                            this.$router.currentRoute.name === 'web_app_additional_documents';
                        break;
                    case 'additional_documents':
                        enabled = this.$router.currentRoute.name === 'web_app_decision';
                        break;
                    case 'decision':
                        enabled = false;
                        break;
                }

                //CHECK THAT ROUTE IS NOT DECISION AND ALSO THAT IT IS NOT REJECTED

                if (this.$route.name === 'decision' && this.$store.state.currentResult === 'fail') {
                    return false;
                }

                return enabled;
            },
        },
        components: {},
    }
</script>

