<template>
    <div  class="d-flex flex-column flex-fill bg-light">
        <header v-if="!this.$store.getters.isWorkspace" class="w-100 bg-white p-2 py-1 d-flex flex-row align-items-center top-nav flex-wrap">
            <a class="h3 m-0 flex-wrap" href="#" @click="(e) => { e.preventDefault(); toggleMenu() }">
                <b-icon-list></b-icon-list>
            </a>
            <div  class="flex-wrap">
                <img class="flex-wrap ml-1" src="../../assets/logo.png" style="width: 220px"/>
            </div>
            <div v-if="this.$store.getters.isAuthenticated" class="flex-fill text-center">

                    <b-button size="sm" pill variant="outline-primary" class="mr-2 px-3" :to="{name : 'flightPath'}" v-if="this.$store.state.user.organisation.flight_path_step !== undefined && this.$store.state.user.organisation.flight_path_step.show_link === 1">
                        <b-icon-check-circle></b-icon-check-circle>
                        FlightPath - <strong>{{ this.$store.state.user.organisation.flight_path_step.name }}</strong>, click to view details
                    </b-button>
            </div>
            <div class="flex-wrap">
                <user-menu></user-menu>
            </div>
        </header>

        <div class="d-flex flex-row flex-fill">
            <transition name="slide">
                <div v-if="menuOpen === 'open'" class="flex-wrap bg-secondary d-flex flex-column align-content-between">

                    <div class="flex-fill">
                    <left-menu>

                    </left-menu>
                    </div>

                    <div class="flex-wrap" v-if="this.$store.getters.isWorkspace && this.$store.getters.isAuthenticated">
                        <div class="text-center">
                        <b-button size="sm" variant="outline-primary" style="max-width: 180px;" class="mb-2 mx-2" :to="{name : 'flightPath'}" v-if="this.$store.state.user.organisation.flight_path_step !== undefined && this.$store.state.user.organisation.flight_path_step.show_link === 1">
                            <b-icon-check-circle></b-icon-check-circle>
                            <span class="ml-2" style="word-break: break-word;">FlightPath - <strong>{{ this.$store.state.user.organisation.flight_path_step.name }}</strong>, click to view details</span>
                        </b-button>
                        </div>
                        <user-menu></user-menu>
                    </div>

                </div>
            </transition>
            <div class="flex-fill position-relative">
                <div class="position-absolute" style="top: 0; right:0; bottom:0; left:0; overflow: auto; z-index:10;">
                    <div v-if="this.$store.getters.isPaymentRequired" class="pl-3 pt-3 pr-3">
                        <div class="alert alert-danger">
                            <div class="mb-2">Your account has been suspended due to non-payment. Please follow these steps to unlock your account.</div>
                            <div class="mb-2"><b-button variant="outline-danger" :to="{name:'billing'}" size="sm">1. Ensure payment details are correct</b-button></div>
                            <div class="mb-2"><b-button variant="outline-danger" :to="{name:'invoices'}" size="sm">2. Pay outstanding invoices</b-button></div>


                        </div>
                    </div>
                    <slot name="main">
                    </slot>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import LeftMenu from "../menus/LeftMenu"
    import UserMenu from "../menus/UserMenu";

    export default {
        name: "BackendLayout",
        components: {
            'left-menu': LeftMenu,
            'user-menu' : UserMenu
        },
        data: function () {
            return {
                menuOpen: localStorage.getItem('menu-open') || "open",
            }
        },
        methods: {
            toggleMenu() {
                localStorage.setItem('menu-open', this.menuOpen === "open" ? "closed" : "open")
                this.menuOpen = this.menuOpen === "open" ? "closed" : "open";
            }
        }
    }
</script>

<style scoped>

</style>
