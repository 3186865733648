<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-0">{{ item.name }}</h5>
                <div>
                    <b-badge variant="primary">{{ item.nationality_group.name }}</b-badge>
                </div>
            </div>
            <div class="col-2 text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'nationalityEdit', params : { id : this.item.id }}).catch(error => {}) }" href="#">Edit nationality</b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); deleteModel(item.id); }" href="#">Delete nationality</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import CellLoader from "../ui/CellLoader";
    import {deleteResource} from "../../modules/api/methods";
    import {nationalityDetail} from "../../modules/api/endpoints";
    export default {
        name: "NationalityCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?.')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(nationalityDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
