<template>

    <paginated-result-loader :claimed="claimed" endpoint="applications" cell-type="applications_simple"
                             :filters="request"></paginated-result-loader>

</template>

<script>

    import {getResource} from "../../modules/api/methods";
    import PaginatedResultLoader from "../PaginatedResultLoader";
    import moment from "moment";
    import {applicationDetail} from "../../modules/api/endpoints";

    export default {
        name: "ApplicationSelect",
        components: {PaginatedResultLoader},
        data: function () {
            return {
                request: [
                    {api_key : 'status', value : 'in_progress'},
                    {api_key : 'claimed', value : this.claimed},
                ],
            }
        },
        props: {
            claimed: null,
            selectedApp: null,
        },
        created() {
            if (this.selectedApp != null) {
                this.startApplication(this.selectedApp);
            }
        },
        methods: {
            startApplication(application) {
                getResource(applicationDetail(application)).then((res) => {
                    var application = res.data.success.data;
                    application.dob = moment(application.dob).format("DD/MM/YYYY");
                    this.$store.dispatch('updateApplication', application).then(() => {
                        this.$router.push({name: 'web_app_basic_info'}).catch(error => {})
                    }).catch((error) => {
                        this.$root.$children[0].handleApiError(error,() => { return this.startApplication(application) });
                    }).finally(() => {

                    });
                }).catch()
            }
        }
    }
</script>
