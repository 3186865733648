<template>
    <loader-panel :isLoading="isLoading">
        <b-card v-if="documents.length > 0" no-body class="bg-white" sub-title="">
            <b-card-body>
                <b-card-sub-title>
                    <div class="mt-1 text-primary">
                        <i class="fa fa-file-upload"></i>
                        Additional documents:
                    </div>
                </b-card-sub-title>
            </b-card-body>

            <b-list-group flush>

                <b-list-group-item v-for="(capture,captureKey) in documents" v-bind:key="captureKey">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="mr-3 flex-wrap" v-if="captureComplete(capture)">
                            <b-icon-check variant="success"></b-icon-check>
                        </div>
                        <div class="flex-fill">
                            <strong>{{ capture.name }} {{ capture.required == 1 ? "(required)" : "(optional)"
                                }}</strong>
                            <div class="text-muted font-italic">
                                {{ capture.instructions }}
                            </div>
                            <!--{{ JSON.stringify(capture) }}-->
                        </div>
                        <image-uploader :ref="'capture-'+capture.id" v-on:on_upload="onUpload"
                                        v-model="capture.selected"
                                        class="flex-wrap"></image-uploader>
                    </div>
                    <error-display v-model="errors" :ident="capture.id"></error-display>
                </b-list-group-item>

            </b-list-group>

            <b-card-footer v-if="documentsFinished" class="d-block d-flex justify-content-end align-items-center">
                <b-button class="flex-wrap" variant="success" v-on:click="saveForm">Save and continue</b-button>
            </b-card-footer>

        </b-card>
    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import ImageUploader from "../ImageUploader";
    import {saveResource} from "../../../modules/api/methods";
    import {additionalDocuments, applicationAdditionalDocuments} from "../../../modules/api/endpoints";
    import ErrorDisplay from "../../form_inputs/ErrorDisplay";
    import _ from 'lodash';

    export default {
        name: 'additional_documents',
        components: {
            ErrorDisplay,
            "image-uploader": ImageUploader,
            "loader-panel": LoaderPanel,
        },
        data: function () {
            return {
                isLoading: false,
                errors : {},
                documents: [],
            }
        },
        mounted() {
            this.loadForm();
        },
        computed: {
            documentsFinished() {
                var isCompleted = true;
                for (var i = 0; i < this.documents.length; i++) {
                    var capture = this.documents[i];
                    if (capture.required == 1 && !this.captureComplete(capture)) {
                        isCompleted = false;
                    }
                }
                return isCompleted;
            }
        },
        methods: {
            onUpload() {

            },
            captureComplete(capture) {
                if (capture.selected != null) {
                    return true;
                }
                return false;
            },
            loadForm() {
                this.isLoading = true;
                //CHANGE FOR API CALL
                saveResource(additionalDocuments,{nationality_id : this.$store.state.application.nationality_id, student : this.$store.state.application.is_student }).then(response => {
                    this.documents = response.data.success.data
                    if (this.documents.length === 0) {
                        //NO DOCS TO COMPLETE SKIP IT
                        this.$root.$children[0].approveApplication();
                    }
                }).catch(error => {
                    //this.$root.$children[0].handleApiError(error,this.loadForm)
                }).finally(() => {
                    this.isLoading = false
                });
            },
            saveForm(ev) {
                ev.preventDefault();
                this.isLoading = true;
                var postData = {};

                _.each(this.documents,function(doc) {
                    postData[doc.id] = doc.selected;
                });

                saveResource(applicationAdditionalDocuments(this.$store.state.application.id), postData).then(response => {
                    this.$root.$children[0].approveApplication();
                }).catch(error => {
                    console.log(error);
                    this.$root.$children[0].handleApiError(error,this.saveForm)
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>
