<template>
    <div class="d-flex no-gutters align-items-center w-100 flex-column flex-md-row">
        <div class="col-md-5">
            <b-card class="text-center">
                <div class="display-4">
                    <surround-loader :is-loading="isLoading">
                    {{ cost_model.application_cost != null ? "£" + parseFloat(cost_model.application_cost).toFixed(2) : "" }}
                    </surround-loader>
                </div>
                <div class="text-muted mb-2">
                    <b-badge variant="light">
                        - per -
                    </b-badge>
                </div>
                <div class="text-muted">
                    <b-badge variant="success m-1">
                        Right to work application
                    </b-badge>
                </div>
            </b-card>
        </div>
        <div class="col-md-2 text-center">
            <b-icon-plus class="h1 text-primary"></b-icon-plus>
        </div>
        <div :class="docCheckClasses()">
            <div class="text-center position-absolute" style="left:0px; top:-12px; right:0px; z-index: 10;" v-if="isFaded()" ><b-badge  variant="warning"  >Disabled in system setup</b-badge></div>
            <b-card class="text-center">
                <div class="display-4">
                    <surround-loader  :is-loading="isLoading">
                    {{ cost_model.doc_check_cost != null ? "£" + parseFloat(cost_model.doc_check_cost).toFixed(2) : " " }}
                    </surround-loader>
                </div>
                <div class="text-muted mb-2">
                    <b-badge variant="light">
                        - per -
                    </b-badge>
                </div>
                <div class="text-muted">
                    <b-badge variant="info">
                        Document check
                    </b-badge>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
    import {getResource} from "../../modules/api/methods";
    import {costModel} from "../../modules/api/endpoints";
    import SurroundLoader from "../SurroundLoader";

    export default {
        name: "PricingDisplay",
        components: {SurroundLoader},
        data: function () {
            return {
                isLoading: false,
                cost_model: {
                    application_cost: null,
                    doc_check_cost: null,
                    frequency: null,
                },
            }
        },
        created() {
            this.loadCostModel();
        },
        methods : {
            isFaded() {
                if(this.$store.getters.isAuthenticated && !!this.$store.state.user.organisation && this.$store.state.user.organisation.doc_check_mode === 'off'){
                    return true;
                }
                return false;
            },
            docCheckClasses() {
                if(this.isFaded()){
                    return "col-md-5 align-items-center doc-check-off";
                }
                return "col-md-5 align-items-center";
            },
            loadCostModel() {
                this.isLoading = true;
                getResource(costModel).then((resp) => {
                    this.cost_model = resp.data.success.data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>
