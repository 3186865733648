<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h6 class="mb-0">List {{ item.list_type }} : {{ item.name }}</h6>
                <div v-if="item.nationality_group == null">
                    <b-badge variant="danger">NEEDS ATTENTION NATIONALITY GROUP DELETED</b-badge>
                </div>
                <div v-else>
                    <b-badge variant="light">Available for {{ item.nationality_group.name }} candidates</b-badge>
                </div>
                <div>
                    <b-badge class="mb-1 mr-1" variant="primary">{{ item.captures_count }} captures</b-badge>
                    <b-badge class="mb-1 mr-1" variant="secondary">{{ item.questions_count }} questions</b-badge>
                </div>
            </div>
            <div class="col-2 text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'documentTypeEdit', params : { id : this.item.id }}).catch(error => {}) }" href="#">Edit document type</b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.deleteModel(item.id);}"  href="#">Delete document type</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {deleteResource} from "../../modules/api/methods";
    import {documentTypeDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";

    export default {
        name: "DocumentTypeCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods : {
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(documentTypeDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
