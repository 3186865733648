<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h6 class="mb-1">User : {{ item.user_name }}</h6>
                <div>
                    <b-badge class="mr-2" :variant="actionVariant()">
                        {{ actionText() }}
                    </b-badge>
                    <b-badge variant="light">
                        <b-icon-stopwatch></b-icon-stopwatch>
                        {{ createdDate }}
                    </b-badge>
                </div>
                <p class="mb-0 mt-1" style="font-size: 12px;" v-html="item.notes">Notes : {{ item.notes }}</p>
            </div>
            <div class="col">

            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import moment from "moment"
    import CellLoader from "../ui/CellLoader";
    import {logToText, logToVariant} from "../../modules/helpers/helpers";

    export default {
        name: "OrganisationLogCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        computed: {
            createdDate() {
                return moment(this.item.created_at).format("DD/MM/YYYY HH:mm");
            }
        },
        methods: {
            actionVariant() {
                return logToVariant(this.item.action)
            },
            actionText() {
                return logToText(this.item.action)
            }
        }

    }
</script>

<style scoped>

</style>
