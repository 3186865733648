<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <frontend-layout>
        <template v-slot:main>
            <div class="p-4">
                <h1 class="mb-0">Support</h1>
            </div>
            <support-menu></support-menu>
            <div>
                <router-view></router-view>
            </div>
        </template>
    </frontend-layout>
</template>


<script>


    import FrontendLayout from "../components/layouts/FrontendLayout";
    import SupportMenu from "../components/support/SupportMenu";

    export default {
        name: 'support',
        components: {
            SupportMenu,
            FrontendLayout

        },
        created() {

        }
    }
</script>
