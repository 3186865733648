<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-6">
                <div class="d-flex mb-3 align-items-center">
                    <img class="flex-wrap" src="../../assets/logo.png" style="width: 220px"/>
                </div>

                <surround-loader :is-loading="isLoading" class="flex-wrap rounded p-4 d-block bg-white">
                    <p>It looks as though your session has ended or your user is no longer available.</p>
                </surround-loader>
            </div>
        </div>
    </div>
</template>

<script>
    import {getResource} from "../../modules/api/methods";
    import {userCurrent} from "../../modules/api/endpoints";
    import SurroundLoader from "../../components/SurroundLoader";

    export default {
        name: 'workspaceSelect',
        data: function () {
            return {
                isLoading : false,
                error : false,
            }
        },
        components: {SurroundLoader},
        methods: {

        },
        computed: {},
        created() {

        }
    }
</script>
