<template>
    <div class="text-center">

        <div class="d-flex align-items-center justify-content-center">
            <div class="col-3 p-4 d-none d-md-block">
                <img class="flex-wrap" src="../../assets/home_screen_image.png" style="width: 100%"/>
            </div>
            <div class="col my-2 text-left">
                <h1 class="display-4 mb-3">Instant <span class="text-primary">Right to Work</span> results
                </h1>
                <p style="font-size: 1.2rem;" class="ml-1">Access Right to Work can help save time and costs whilst keeping you compliant</p>
            </div>
        </div>

        <div class="row justify-content-center font-weight-bold mb-3 ">
            <div class="col-12 col-md-6 col-lg-4 col-xl p-2 d-flex flex-column">
                <div class="p-4 border bg-white rounded flex-fill d-flex flex-column justify-content-center align-items-center">
                    <b-icon-layout-text-sidebar class="h3 text-success mb-2"></b-icon-layout-text-sidebar>
                    <p class="mb-0">Less than 5 steps to confirm a candidate’s Right to Work status</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl p-2 d-flex flex-column">
                <div class="p-4 border bg-white rounded flex-fill d-flex flex-column justify-content-center align-items-center">
                    <b-icon-patch-check class="h2 text-success mb-2"></b-icon-patch-check>
                    <p class="mb-0">Comply with Home Office regulations</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl p-2 d-flex flex-column">
                <div class="p-4 border bg-white rounded flex-fill d-flex flex-column justify-content-center align-items-center">
                    <b-icon-stopwatch class="h2 text-success mb-2"></b-icon-stopwatch>
                    <p class="mb-0">Get up and running in minutes</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl p-2 d-flex flex-column">
                <div class="p-4 border bg-white rounded flex-fill d-flex flex-column justify-content-center align-items-center">
                    <b-icon-credit-card class="h2 text-success mb-2"></b-icon-credit-card>
                    <p class="mb-0">Only pay for the checks you do</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xl p-2 d-flex flex-column">
                <div class="p-4 border bg-white rounded flex-fill d-flex flex-column justify-content-center align-items-center">
                    <b-icon-chat-text class="h2 text-success mb-2"></b-icon-chat-text>
                    <p class="mb-0">24/7 online support and training</p>
                </div>
            </div>
        </div>
        <p>For more information visit the <a href="https://www.theaccessgroup.com/candidate-screening/right-to-work-app/"
                            target="_blank">Access Right to Work website</a></p>
    </div>

</template>

<script>
    export default {
        name: "ProductFeatures"
    }
</script>

<style scoped>

</style>
