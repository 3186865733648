<template>
    <div v-if="isLoading" class="cell-loader d-flex align-items-center p-3">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <div class="text-muted ml-3">Working, won't be long <span v-for="i in counter" v-bind:key="i">.</span></div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        name: "CellLoader",
        props : {
            isLoading : null,
        },
        data : function() {
            return {
                counter : 1,
                counterMax : 3,
            }
        },
        watch : {
            isLoading : function(newVal){
                if(newVal) {
                    this.startCounter();
                }
            }
        },
        methods : {
            startCounter() {
                if(!this.isLoading) return;
                if(this.counter >= this.counterMax) this.counter = 0;
                this.counter ++;
                _.delay(() => {
                    this.startCounter()
                },500)
            }
        }
    }
</script>

<style scoped>
    .cell-loader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #fff;
        z-index: 10;
    }
</style>
