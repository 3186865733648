<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-select
            :placeholder="placeholder"
            :multiple="multiple"
            :reduce="option => option.value"
            :filterable="true" label="name" v-model="input"
            :options="loadedOptions" @search="fetchOptions">
        <slot name="no-options">Search for options</slot>
    </v-select>

</template>


<script>

    import vSelect from 'vue-select'
    import axios from "axios";
    import {appendObjectsTo} from "../../modules/helpers/helpers";
    import _ from 'lodash'

    export default {
        name: "ApiSelectInput",
        components: {
            "v-select": vSelect,
        },
        props: {
            originalOptions : null,
            multiple : {
                type: Boolean,
                default : false
            },
            apiEndpoint: null,
            apiEndpointParam: null,
            apiAdditionalParams: {
                type: Array,
                default: () => []
            },
            placeholder : null,
            value : null,
        },
        data: function () {
            return {
                loadedOptions: this.originalOptions != null ? this.originalOptions : [],
                input : this.value,
                debouncer : null,
            }
        },
        watch:{
            input: function(newVal,oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            fetchOptions(search, loading) {
                this.loadOptions(search,loading,this);
            },
            loadOptions: _.debounce((search, loading,vm) => {
                loading(true);
                var postData = {};
                postData[vm.apiEndpointParam] = search;

                if(vm.apiAdditionalParams != null) {
                    _.each(vm.apiAdditionalParams, function (param) {
                        postData[param.key] = param.value;
                    });
                }

                axios.post(vm.apiEndpoint, postData, {
                    headers: {
                        Accept: "application/json"
                    }
                })
                    .then(resp => {
                        if (resp.data.success !== undefined) {
                            vm.loadedOptions = appendObjectsTo(resp.data.success,vm.loadedOptions,'value');
                        } else {
                            throw "oops";
                        }
                        loading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        loading(false);
                    });
            },350)
        }
    }
</script>

<style scoped>

</style>
