<template>
    <b-list-group-item class="application-cell text-left">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row no-gutters">
            <div class="col-5">
                <h5 class="mb-1">{{ item.first_name }} {{ item.middle_names }} {{ item.surname }}</h5>
                <div>
                    <b-badge class="mr-1 mb-1" variant="light" v-for="(tag,tagKey) in item.tags" v-bind:key="tagKey">{{
                        tag.tag_type != null ? tag.tag_type.name : "unknown" }} : {{ tag.tag_value }}
                    </b-badge>
                </div>

            </div>

            <div class="col-4" style="font-size: 12px">
                <div>
                    <strong>Nationality :</strong> {{ item.nationality != null ? item.nationality.name : "" }}
                </div>
            </div>
            <div class="col-3 text-right">
                <b-button variant="outline-primary" size="sm" class="m-2" @click="onApplicationClick">
                    <b-icon-chevron-right></b-icon-chevron-right>
                    {{ claimed === "true" ? "Continue application" : "Claim application"}}
                </b-button>

                <b-button v-if="claimed === 'true'" variant="outline-secondary" size="sm" class="m-2" @click="() => { claimApplication('false') }">
                    <b-icon-x></b-icon-x>
                    Unclaim application
                </b-button>
            </div>

        </div>

    </b-list-group-item>
</template>
<script>
    import {statusIsComplete} from "../../modules/helpers/helpers";
    import CellLoader from "../ui/CellLoader";
    import moment from "moment";
    import {getResource} from "../../modules/api/methods";
    import {applicationClaim} from "../../modules/api/endpoints";

    export default {
        name: "ApplicationSimpleCell",
        components: {CellLoader},
        props: {
            item: {},
            claimed : null
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        computed: {
            isComplete: function () {
                return statusIsComplete(this.item.status)
            }
        },
        methods: {
            claimApplication(claim) {
                this.isLoading = true;
                getResource(applicationClaim(this.item.id,claim)).then(() => {

                }).catch((err) => {
                    this.$root.$children[0].handleApiError(error,() => { this.claimApplication(claim) });
                }).finally(() => {
                    this.$emit("delete");
                    this.isLoading = false;
                })
            },
            onApplicationClick() {
                var application = this.item;
                console.log(application);

                if(this.claimed != "true") {
                    this.claimApplication("true");
                    return;
                }

                application.dob = moment(application.dob).format("DD/MM/YYYY");
                this.$store.dispatch('updateApplication', application).then(() => {
                    this.$router.push({name: 'web_app_basic_info'}).catch(error => {})
                }).catch((error) => {
                    ////this.$root.$children[0].handleApiError(error,this.startApplication(application));
                }).finally(() => {

                });
            },
        }

    }
</script>

<style scoped>

</style>
