<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <backend-layout>
    <template v-slot:main>
      <router-view>

      </router-view>
    </template>
  </backend-layout>
</template>

<script>
import BackendLayout from "../components/layouts/BackendLayout"

export default {
  name: 'dashboard',
  components: {
    'backend-layout' : BackendLayout
  },
  created() {

  }
}
</script>
