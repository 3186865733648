<template>
    <div>
        <div v-if="this.$store.getters.isAuthenticated"
             :class="this.$store.getters.isWorkspace ? ' bg-white d-flex align-items-center flex-column  py-3 pb-4' : 'd-flex align-items-center'">
            <div :class="this.$store.getters.isWorkspace ? 'font-weight-bold mb-1 border-bottom pb-2 d-flex' : 'font-weight-bold mr-3 mt-1'"
                 style=" max-width: 150px;">{{ this.$store.state.user.organisation.name }}
            </div>
            <div id="admin-toggle" class="flex-wrap">
                <b-form-checkbox @input="switchActingAdmin" v-if="$store.getters.canBeAdmin" v-model="actingAdmin"
                                 switch size="md" :class="$store.getters.isWorkspace ? 'm-3 d-block' : 'mt-1'">
                    Act as admin
                </b-form-checkbox>
            </div>

            <b-nav-item-dropdown
                    :class="this.$store.getters.isWorkspace? 'nav user-menu flex-wrap' : 'nav user-menu'"
                    :text="this.$store.state.user.name"
                    :right="!this.$store.getters.isWorkspace"
                    :left="this.$store.getters.isWorkspace"
                    id="account-menu"
            >
                <b-dropdown-item  disabled>
                    <div style="font-size: 10px; font-weight: bold; line-height:10px;">{{ this.$store.state.user.email
                        }}
                    </div>
                </b-dropdown-item>
                <b-dropdown-divider ></b-dropdown-divider>
                <b-dropdown-item :to="{name : 'dashboard'}">My dashboard</b-dropdown-item>
                <b-dropdown-item :to="{name : 'web_app_application_list'}">Go to web app</b-dropdown-item>
                <b-dropdown-item v-if="this.$store.state.user.organisation.force_notifications !== 1"
                                 @click="() => { this.notificationSettings = true }">Notification settings
                </b-dropdown-item>

                <b-dropdown-divider v-if="!this.$store.getters.isWorkspace"></b-dropdown-divider>
                <b-dropdown-item v-if="this.$store.getters.isImpersonating" @click="leaveImpersonation"
                                 class="text-primary">Leave impersonation
                </b-dropdown-item>
                <b-dropdown-item v-if="!this.$store.getters.isWorkspace" @click="logout" class="text-primary">Logout
                </b-dropdown-item>

                <template v-if="!this.$store.getters.isWorkspace">
                    <b-dropdown-item variant="primary" v-for="(user,userKey) in this.$store.state.user.organisation_users.filter((obj) => { return obj.organisation_id !== this.$store.state.user.organisation.id; })" v-bind:key="user.id" @click="switchOrganisation(user.organisation_id)" class="text-primary">Switch to {{ user.organisation_name }}
                    </b-dropdown-item>
                </template>

            </b-nav-item-dropdown>
        </div>
        <div v-if="!this.$store.getters.isAuthenticated" class="d-flex align-items-center">
            <b-button size="sm" pill variant="outline-primary" class="mr-2" :to="{name : 'login'}">
                Login to portal
            </b-button>
        </div>

        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="notificationSettings">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => notificationSettings = false">
                    <b-icon-x-circle></b-icon-x-circle>
                </a>
            </div>
            <notification-settings v-on:success="() => { notificationSettings = false }"></notification-settings>
        </b-modal>

    </div>
</template>

<script>
    import NotificationSettings from "../notification_settings/NotificationSettings";
    import impersonateMixin from "../mixins/impersonateMixin";

    export default {
        name: "UserMenu",
        components: {NotificationSettings},
        data: function () {
            return {
                actingAdmin: false,
                notificationSettings: false,
                isLoading : false,
            }
        },
        mixins : [impersonateMixin],
        created() {
            this.actingAdmin = this.$store.state.actingAdmin;
        },
        methods: {
            logout() {
                if (!this.$store.getters.isWorkspace) {
                    //VERIFY WHICH TYPE OF TOKEN AS COULD BE OPEN ID TOKEN
                    this.$store.commit('logout');
                    this.$router.replace({'name': 'home'}).then(() => {
                        this.$root.$children[0].showLogoutMessage();
                    });

                }
            },

            switchActingAdmin() {
                this.$store.commit('actingAdmin', this.actingAdmin);
                location.reload();
            },
            switchOrganisation(organisationId) {
                this.isLoading = true;
                this.$router.replace({ path : this.$router.currentRoute.path, query: { session_organisation_id : organisationId }}).then(() => {
                    //UPDATE THE USER
                    this.$store.dispatch('refreshUserCall').then(() => {
                        location.reload();
                        this.isLoading = false;
                    }).catch((err) => {
                        this.isLoading = false;
                    });
                }).catch((err) => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
