<template>
    <div class="user-cell p-3 border-bottom position-relative">
        <cell-loader :is-loading="isLoading"></cell-loader>

        <div v-if="currentStep !== null" class="p-2 align-items-center">
            <div v-if="currentStep === STEP_USER_TYPE" class="d-flex align-items-center">
                <div class="flex-fill">
                    <b-form-group
                            class="mb-0"
                            label-size="sm"
                            :label="'Select user type for ' + item.first_name + ' ' + item.last_name">
                        <b-select size="sm" v-model="editingUser.user_type" :options="['supervisor','user']"></b-select>
                    </b-form-group>
                </div>
            </div>
            <div v-else-if="currentStep === STEP_USERS || currentStep === STEP_SUPERVISORS"
                 class="d-flex align-items-center">
                <div class="flex-fill">
                    <b-form-group
                            class="mb-0"
                            label-size="sm"
                            :label="'Select '+ (currentStep === STEP_USERS ? 'users' : 'supervisors' ) +' for ' + item.first_name + ' ' + item.last_name">
                        <div v-if="isLoadingUsers" class="d-flex align-items-center border p-2 rounded">
                            <b-spinner small variant="primary" class="mr-2"></b-spinner>
                            Loading options
                        </div>
                        <div v-else>
                            <div v-if="currentStep === STEP_SUPERVISORS">
                                <div v-if="supervisors.length === 0" class="alert alert-warning">
                                    No supervisors added yet.
                                </div>
                                <div v-else>
                                    <v-select
                                            ref="supervisorSelect"
                                            label="name"
                                            v-model="editingUser.supervisor_ids"
                                            :multiple="true"
                                            :reduce="option => option.value"
                                            :options="supervisors"
                                            :filterable="true"
                                            placeholder="Search for supervisor">
                                    </v-select>
                                    <div class="mt-2 text-right">
                                        <b-button :pill="true" variant="secondary" size="sm"
                                                  @click="deselectSupervisor">
                                            Deselect
                                            all
                                        </b-button>
                                        <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                                  @click="selectAllSupervisors">Select all
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="users.length === 0" class="alert alert-warning">
                                    No users added yet.
                                </div>
                                <div v-else>
                                    <v-select
                                            ref="userSelect"
                                            label="name"
                                            v-model="editingUser.user_ids"
                                            :multiple="true"
                                            :reduce="option => option.value"
                                            :options="users"
                                            :filterable="true"
                                            placeholder="Search for user">
                                    </v-select>
                                    <div class="mt-2 text-right">
                                        <b-button :pill="true" variant="secondary" size="sm"
                                                  @click="deselectSupervisor">
                                            Deselect
                                            all
                                        </b-button>
                                        <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                                  @click="selectAllSupervisors">Select all
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form-group>
                </div>
            </div>

            <div v-else-if="currentStep === STEP_ROLES">
                <b-form-group
                        class="mb-0"
                        label-size="sm"
                        :label="'Additional permissions for ' + item.first_name + ' ' + item.last_name">
                    <div class="d-flex align-items-center p-2 border rounded">
                        <div class="flex-fill">
                            <b-form-checkbox
                                    class="font-weight-bold"
                                    v-model="editingUser.billing"
                                    value="1"
                                    unchecked-value="0"
                            >
                                <b-badge variant="success">
                                    Billing access
                                </b-badge>
                            </b-form-checkbox>
                        </div>
                        <div v-if="editingUser.user_type === 'supervisor'" class="flex-fill">
                            <b-form-checkbox
                                    class="font-weight-bold"
                                    v-model="editingUser.admin"
                                    value="1"
                                    unchecked-value="0"
                            >
                                <b-badge variant="danger">
                                    Admin access
                                </b-badge>
                            </b-form-checkbox>
                        </div>
                    </div>
                </b-form-group>
            </div>

            <error-display v-model="errors" ident="global"></error-display>
            <div class="d-flex justify-content-between mt-2">
                <span class="text-primary clickable" @click="onCheck(false)">
                    <b-icon-arrow-left-circle
                    ></b-icon-arrow-left-circle>
                    Back
                    </span>
                <span class="text-success clickable" @click="onCheck(true)">
                    {{ currentStep === STEP_ROLES ? "Finish" : "Next" }}
                <b-icon-arrow-right-circle></b-icon-arrow-right-circle>
                </span>
            </div>
        </div>

        <div v-else>
            <div  class="d-flex align-items-center">
                <div class="flex-wrap">
                    <div class="rounded-circle bg-info text-white text-center d-flex justify-content-center align-items-center font-weight-bold"
                         style="width: 30px; height: 30px; font-size: 14px;">
                        <span>{{ initials }}</span>
                    </div>
                </div>
                <div class="flex-fill ml-2">
                    <div style="font-size:12px; word-break: break-all;" class="mb-0 font-weight-bold" >{{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div style="font-size:10px; word-break: break-all;" class="mb-0">{{ item.email }}</div>
                    <div v-if="item.user != null" style="font-size:12px;" class="mb-0">
                        <b-badge class="mr-1" variant="light" v-if="item.user.user_type === 'supervisor'">Supervisor</b-badge>
                        <b-badge class="mr-1" variant="light" v-if="item.user.user_type === 'user'">User</b-badge>
                        <b-badge class="mr-1" variant="primary" v-if="isAdmin()">Admin access</b-badge>
                        <b-badge class="mr-1" variant="success" v-if="isBilling()">Billing access</b-badge>
                    </div>
                    <div v-if="item.user != null && this.$store.getters.isOrgSetup">
                        <b-badge :variant="item.user.approved_devices_count === item.user.devices_count ? 'light' : 'danger'">
                            {{ item.user.approved_devices_count }} / {{ item.user.devices_count }} devices approved
                        </b-badge>
                    </div>
                </div>
                <div v-if="item.user == null" class="flex-wrap">
                    <b-icon-plus-circle class="text-primary clickable"
                                        @click="onCheck(true)"></b-icon-plus-circle>
                </div>
                <div v-else class="flex-wrap">
                    <div v-if="this.$store.getters.isOrgSetup" class="flex-wrap text-right">
                        <b-dropdown size="sm" right text="Actions" variant="light" class="m-2" id="user-actions">
                            <b-dropdown-item
                                    @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'userDevices', params : { userId : this.item.user.id }}).catch(error => {}) }"
                                    href="#">User devices
                            </b-dropdown-item>
                            <b-dropdown-item
                                    @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'userEdit', params : { userId : this.item.user.id }}).catch(error => {}) }"
                                    href="#">Edit user
                            </b-dropdown-item>
                            <b-dropdown-item v-if="this.$store.getters.isSuper"
                                             @click="(ev) => { ev.preventDefault(); this.impersonateUser(item.user.id);}" href="#">
                                Impersonate user
                            </b-dropdown-item>
                            <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.deleteModel(item.user.id);}" href="#">Delete
                                user
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <b-icon-trash v-else-if="this.$store.state.user.id !== item.user.id" class="text-primary clickable"
                                  @click="deleteUser"></b-icon-trash>
                </div>

            </div>
            <error-display v-model="errors" ident="global"></error-display>
        </div>


    </div>
</template>
<script>

    import {containsErrors, roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource, getResource, saveResource} from "../../modules/api/methods";
    import {
        userDetail, userImpersonate, userResendLink,
        usersDropdown,
        workspaceUserAdd,
        workspaceUserDelete,
        workspaceUsers
    } from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import vSelect from "vue-select"
    import _ from 'lodash';
    import impersonateMixin from "../mixins/impersonateMixin";


    export default {
        name: "WorkspaceUserCell",
        components: {ErrorDisplay, CellLoader, "v-select": vSelect},
        props: {
            item: {}
        },
        mixins : [impersonateMixin],
        data: function () {
            return {
                isLoading: false,
                currentStep: null,
                editingUser: {},
                errors: {},
                isLoadingUsers: false,
                supervisors: [],
                users: []
            }
        },
        created() {
            this.STEP_USER_TYPE = 'user_type';
            this.STEP_SUPERVISORS = 'supervisors';
            this.STEP_USERS = 'users';
            this.STEP_ROLES = 'roles';
        },
        methods: {
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(userDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            resendLink: function (id) {
                this.isLoading = true;
                getResource(userResendLink(id)).then((resp) => {
                    this.isLoading = false;
                    this.$bvToast.toast('Invite link re-sent to user', {
                        title: 'Success',
                        variant: 'success',
                        solid: false
                    })
                }).catch((err) => {
                    this.isLoading = false;
                    this.$root.$children[0].handleApiError(err, () => {
                        return this.resendLink(id)
                    });
                })
            },
            isAdmin: function() {
                var hasAccess = false;
                if(this.item.user === undefined || this.item.user.roles === undefined){
                    return false
                }
                _.each(this.item.user.roles, function (role) {
                    if (role.admin === 1) hasAccess = true;
                });
                return hasAccess;
            },
            isBilling: function() {
                var hasAccess = false;
                if(this.item.user === undefined || this.item.user.roles === undefined){
                    return false
                }
                _.each(this.item.user.roles, function (role) {
                    if (role.billing === 1) hasAccess = true;
                });
                return hasAccess;
            },
            onCheck: function (yes) {
                this.errors = {};
                if (this.currentStep == null) {
                    this.editingUser = {id: this.item.id};
                    this.currentStep = this.STEP_USER_TYPE;
                    return;
                }
                if (this.currentStep === this.STEP_USER_TYPE) {
                    if (!yes) {
                        this.currentStep = null;
                        return;
                    } else {
                        if (this.editingUser.user_type !== 'supervisor' && this.editingUser.user_type !== 'user') {
                            this.errors = {global: ['Please select a user type']};
                            return;
                        }
                        this.loadAllUsers();
                        if (this.editingUser.user_type === 'user') {
                            this.currentStep = this.STEP_SUPERVISORS;
                        } else {
                            this.currentStep = this.STEP_USERS;
                        }
                        return;
                    }
                }
                if (this.currentStep === this.STEP_SUPERVISORS || this.currentStep === this.STEP_USERS) {
                    if (!yes) {
                        this.currentStep = this.STEP_USER_TYPE;
                        return;
                    } else {
                        this.currentStep = this.STEP_ROLES;
                        return;
                    }
                }
                if (this.currentStep === this.STEP_ROLES) {
                    if (!yes) {
                        if (this.editingUser.user_type === 'user') {
                            this.currentStep = this.STEP_SUPERVISORS;
                        } else {
                            this.currentStep = this.STEP_USERS;
                        }
                        return;
                    } else {
                        this.saveEditingUser();
                        return;
                    }
                }
            },
            deleteUser() {
                this.errors = {};
                this.isLoading = true;
                deleteResource(workspaceUserDelete(this.item.id)).then((res) => {
                    console.log(res);
                    this.$emit('delete');
                }).catch((ex) => {
                    console.log(ex);
                    if(containsErrors(ex)) {
                        this.errors = ex.data.errors;
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            saveEditingUser() {
                this.errors = {};
                this.isLoading = true;
                var data = this.editingUser;
                if(this.$store.getters.isOrgSetup){
                    data.send_email = 1;
                }
                saveResource(workspaceUserAdd, data).then((res) => {
                    console.log(res);
                    this.$emit('delete');
                }).catch((ex) => {
                    console.log(ex);
                    if(containsErrors(ex)) {
                        this.errors = ex.data.errors;
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            selectAllSupervisors() {
                var user = this.editingUser;
                user.supervisor_ids = _.map(this.supervisors, 'value');
                this.user = user;
                const select = this.$refs.supervisorSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectSupervisor() {
                var user = this.editingUser;
                user.supervisor_ids = [];
                this.user = user;
                const select = this.$refs.supervisorSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            selectAllUsers() {
                var user = this.editingUser;
                user.user_ids = _.map(this.users, 'value');
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectUser() {
                var user = this.editingUser;
                user.user_ids = [];
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            loadAllUsers() {
                this.isLoadingUsers = true;
                var vm = this;
                saveResource(usersDropdown, {}).then((resp) => {
                    this.users = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'user'
                    });
                    this.supervisors = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'supervisor'
                    });

                    if (vm.editingUser.user_ids !== undefined) {
                        var newUserIds = [];
                        _.each(vm.editingUser.user_ids, function (id) {
                            if (_.filter(vm.users, function (user) {
                                return user.value === id;
                            }).length > 0) {
                                newUserIds.push(id);
                            }
                        });
                        vm.editingUser.user_ids = newUserIds;
                    }
                    if (vm.editingUser.supervisor_ids !== undefined) {
                        var newSupervisorIds = [];
                        _.each(vm.editingUser.supervisor_ids, function (id) {
                            if (_.filter(vm.supervisors, function (user) {
                                return user.value === id;
                            }).length > 0) {
                                newSupervisorIds.push(id);
                            }
                        });
                        vm.editingUser.supervisor_ids = newSupervisorIds;
                    }
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadAllUsers);
                }).finally(() => {
                    this.isLoadingUsers = false;
                });
            },

            roleVariant: function (role) {
                return roleToVariant(role)
            },
        },
        computed: {
            initials: function () {
                let arr = [];
                if (this.item.first_name != null && this.item.first_name.length > 0) {
                    arr.push(this.item.first_name.charAt(0));
                }
                if (this.item.last_name != null && this.item.last_name.length > 0) {
                    arr.push(this.item.last_name.charAt(0));
                }
                if (arr.length > 0) {
                    return arr.join("");
                }
                return "?";
            },
        }

    }
</script>

<style scoped>

</style>
