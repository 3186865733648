<template>
    <b-badge v-if="status != 'archived'" class="mr-1 mb-1 text-uppercase font-weight-bold pr-2" style="font-size:12px;" :variant="variant">
        <b-icon :icon="icon"></b-icon> {{ statusText }}
    </b-badge>
</template>
<script>
    import {statusIcon, statusToText, statusToVariant} from "../../modules/helpers/helpers";

    export default {
        name: "ApplicationStatusBadge",
        props: {
            status: null
        },
        computed: {
            variant: function () {
                return statusToVariant(this.status);
            },
            statusText: function () {
                return statusToText(this.status);
            },
            icon: function () {
                return statusIcon(this.status);
            }
        }
    }
</script>

<style scoped>

</style>
