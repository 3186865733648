import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/auth/Login";
import LoginSuccess from "../views/auth/LoginSuccess";
import store from '../store'
import Dashboard from "../views/Dashboard";
import AwaitingSetup from "../views/auth/AwaitingSetup";
import ApplicationList from "../components/web_app/screens/ApplicationList";
import BasicInfo from "../components/web_app/screens/BasicInfo";
import Documents from "../components/web_app/screens/Documents";
import Photo from "../components/web_app/screens/Photo";
import Questions from "../components/web_app/screens/Questions";
import Student from "../components/web_app/screens/Student";
import AdditionalDocuments from "../components/web_app/screens/AdditionalDocuments";
import Decision from "../components/web_app/screens/Decision";
import WebApp from "../views/WebApp";
import Home from "../views/Home";
import WorkspaceLogin from "../views/auth/WorkspaceLogin";
import Setup from "../views/Setup";
import Support from "../views/Support";
import SupportFaqs from "../components/support/SupportFaqs";
import SupportMedia from "../components/support/SupportMedia";
import Features from "../views/Features";
import Register from "../views/auth/Register";
import OrganisationAccountCode from "../views/dashboard/admin/super_admin/OrganisationAccountCode";
import WorkspaceSelect from "../views/auth/WorkspaceSelect";
import WorkspaceNoAuth from "../views/auth/WorkspaceNoAuth";

Vue.use(VueRouter);

const GUARD_ORG_SETUP = "org_setup";
const GUARD_SETUP_AVAILABLE = "setup_available";
const GUARD_NOT_AUTHENTICATED = "not_authenticated";
export const GUARD_IS_AUTHENTICATED = "is_authenticated";
const GUARD_ROLE_ACCESS = "access_role";
const GUARD_ROLE_ADMIN = "admin_role";
const GUARD_ROLE_BILLING = "billing_role";
const GUARD_ROLE_SUPER = "super_role";
const GUARD_FLIGHT_PATH_EDITABLE = "flight_path_editable";
const GUARD_PAYMENT = "payment_required";
const GUARD_IS_WORKSPACE_TAB = "is_workspace_tab";
const GUARD_IS_NOT_WORKSPACE_TAB = "is_not_workspace_tab";
const GUARD_ORGANISATION_QUERY = "organisation_query";

const beforeEnter = (to, from, next) => {
    if (to.meta.guards !== undefined) {
        if (to.meta.guards.indexOf(GUARD_IS_AUTHENTICATED) !== -1) {
            if (!store.getters.isAuthenticated) {
                next('/login');
                return;
            }
            if(!!store.state.user.organisation === undefined){
                if(store.state.user.workspace_organisations !== undefined && store.state.user.workspace_organisations.length > 1){
                    next('/workspace-select');
                    return;
                }
            }
        }
        if (to.meta.guards.indexOf(GUARD_NOT_AUTHENTICATED) !== -1) {
            if (store.getters.isAuthenticated) {
                next('/dashboard');
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_ROLE_SUPER) !== -1) {
            if (!store.getters.isSuper) {
                next('/dashboard');
                return
            }
        }
        if (to.meta.guards.indexOf(GUARD_ROLE_ADMIN) !== -1) {
            if (!store.getters.isAdmin && !store.getters.isSuper) {
                next('/dashboard');
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_ROLE_BILLING) !== -1) {
            if (!store.getters.isBilling) {
                next('/dashboard');
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_PAYMENT) !== -1) {
            if (store.getters.isPaymentRequired) {

                if (store.getters.isBilling) {
                    next('/dashboard/admin/invoices');
                } else {
                    console.log("PAYMENT");
                    next('/awaiting-setup');
                }
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_ROLE_ACCESS) !== -1) {
            if (!store.getters.isSetup) {
                console.log("ROLE ACCESS");
                next('/awaiting-setup');
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_ORG_SETUP) !== -1) {
            if (!store.getters.isOrgSetup) {
                //CHECK HERE THAT USER HAS ACCESS
                //if (store.getters.isSetup && store.getters.isAdmin) {
                    next('/setup');
                    return;

            }
        }
        if (to.meta.guards.indexOf(GUARD_FLIGHT_PATH_EDITABLE) !== -1) {
            if (store.state.user.organisation !== undefined && store.state.user.organisation.flight_path_step !== undefined && store.state.user.organisation.flight_path_step.show_link !== 1) {
                next('/dashboard');
                return;
            }
        }
        if (to.meta.guards.indexOf(GUARD_SETUP_AVAILABLE) !== -1) {
            let org = store.state.user.organisation;
            if (store.getters.isOrgSetup) {
                next('/dashboard');
                return;
            }
            if (to.name.includes("setup_") && org == null) {
                if (!!store.state.user.organisation === undefined) {
                    if (store.state.user.workspace_organisations !== undefined && store.state.user.workspace_organisations.length > 1) {
                        next('/workspace-select');
                        return;
                    }
                } else {
                    console.log("ORG IS UNDEFINED");
                }
            }
            if (to.name === 'setup_system_setup' && org == null) {
                next('/setup/company-details');
                return;
            }
            if (to.name === 'setup_users' && org.setup_system_at == null) {
                next('/setup/system-setup');
                return;
            }
            if (to.name === 'setup_contract' && org.setup_users_at == null) {
                next('/setup/users-setup');
                return;
            }
            if (to.name === 'setup_payment' && org.setup_contract_at == null) {
                next('/setup/contract');
                return;
            }
            if (to.name === 'setup_launch' && org.setup_payment_at == null) {
                next('/setup/payment');
                return;
            }
        }
    }
    next();
}

const routes = [
    {
        path: '/workspace/:instanceId',
        name: 'workspace_login',
        props: true,
        component: WorkspaceLogin,
        meta: {
            bypassFlightpath: true,
        }
    },{
        path: '/organisation-billing/:token',
        name: 'account_codes',
        props: true,
        component: OrganisationAccountCode,
        meta: {
            bypassFlightpath: true,
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/features',
        name: 'features',
        component: Features,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/support',
        component: Support,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_NOT_WORKSPACE_TAB]
        },
        children: [
            {
                name: 'support',
                path: '',
                component: SupportFaqs,
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_IS_NOT_WORKSPACE_TAB]
                },
                children: [
                    {
                        path: 'faq/:id',
                        name: 'faqView',
                        component: () => import('../components/support/FaqView.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_IS_NOT_WORKSPACE_TAB]
                        }
                    }
                ]
            },
            {
                name: 'supportMedia',
                path: 'training-media',
                component: SupportMedia,
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_IS_NOT_WORKSPACE_TAB]
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_NOT_AUTHENTICATED,GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_NOT_AUTHENTICATED,GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/register/:token',
        name: 'register_confirm',
        component: Register,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/setup',
        name: 'setup',
        component: Setup,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
        },
        children: [
            {
                path: 'welcome',
                name: 'setup_welcome',
                component: () => import('../components/setup/SetupWelcome.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
            {
                path: 'company-details',
                name: 'setup_company_details',
                component: () => import('../components/setup/SetupCompanyDetails.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
            {
                path: 'system-setup',
                name: 'setup_system_setup',
                component: () => import('../components/setup/SetupSystemSetup.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
            {
                path: 'users-setup',
                name: 'setup_users_setup',
                component: () => import('../components/setup/SetupUserSetup.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                },
                children: [
                    {
                        path: 'add',
                        name: 'setupUserAdd',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE],
                        }
                    },
                    {
                        path: 'edit/:userId',
                        name: 'setupUserEdit',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                        }
                    },
                    ]
            },
            {
                path: 'contract',
                name: 'setup_contract',
                component: () => import('../components/setup/SetupContract.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
            {
                path: 'payment',
                name: 'setup_payment',
                component: () => import('../components/setup/SetupPayment.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
            {
                path: 'launch',
                name: 'setup_launch',
                component: () => import('../components/setup/SetupLaunch.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_SETUP_AVAILABLE]
                }
            },
        ]
    },
    {
        path: '/login-success',
        name: 'loginSuccess',
        component: LoginSuccess,
        meta: {
            bypassFlightpath: true,
        }
    },
    {
        path: '/awaiting-setup',
        name: 'awaitingSetup',
        component: AwaitingSetup,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP,GUARD_IS_NOT_WORKSPACE_TAB]
        }
    },
    {
        path: '/workspace-no-auth',
        name: 'workspaceNoAuth',
        component: WorkspaceNoAuth,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_WORKSPACE_TAB]
        }
    },
    {
        path: '/workspace-select',
        name: 'workspaceSelect',
        component: WorkspaceSelect,
        beforeEnter: beforeEnter,
        meta: {
            bypassFlightpath: true,
            guards: [GUARD_IS_AUTHENTICATED]
        }
    },
    {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: beforeEnter,
        children: [
            {
                name: 'DashboardSupport',
                path: 'support',
                component: SupportFaqs,
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY],
                },
                children: [
                    {
                        path: 'support/faq/:id',
                        name: 'DashboardFaqView',
                        component: () => import('../components/support/FaqView.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY]
                        }
                    }
                ]
            },
            {
                name: 'DashboardSupportMedia',
                path: 'support/training-media',
                component: () => import('../components/support/SupportMedia.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    bypassFlightpath: true,
                    guards: [GUARD_ORGANISATION_QUERY]
                }
            },

            {
                path: '',
                name: 'dashboard',
                component: () => import('../views/dashboard/DashboardHome.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT],
                    tourGroup : 'dashboard'
                }
            },
            {
                path: '/flight-path',
                name: 'flightPath',
                component: () => import('../views/dashboard/FlightPath.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_FLIGHT_PATH_EDITABLE, GUARD_PAYMENT]
                }
            },
            {
                path: 'expiring-documents',
                name: 'expiringDocuments',
                component: () => import('../views/dashboard/ExpiringDocuments.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT],
                    tourGroup : 'expiring_documents'
                }
            },
            {
                path: 'applications',
                name: 'applications',
                component: () => import('../views/dashboard/Applications.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT],
                    tourGroup : 'applications'
                },
                children: [
                    {
                        path: 'batch/:inputData',
                        name: 'applicationBatch',
                        component: () => import('../components/applications/BatchAction.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'add',
                        name: 'applicationAdd',
                        component: () => import('../components/applications/ApplicationEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:applicationId',
                        name: 'applicationEdit',
                        component: () => import('../components/applications/ApplicationEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:applicationId/users',
                        name: 'applicationUsersEdit',
                        component: () => import('../components/applications/ApplicationUsersEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'applications/:applicationId',
                name: 'applicationDetail',
                component: () => import('../views/dashboard/ApplicationDetail.vue'),
                beforeEnter: beforeEnter,
                props: true,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT],
                    tourGroup : 'application_detail'
                },
                children: [
                    {
                        path: 'edit/:applicationId',
                        name: 'applicationDetailEdit',
                        component: () => import('../components/applications/ApplicationEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:applicationId/users',
                        name: 'applicationDetailUsersEdit',
                        component: () => import('../components/applications/ApplicationUsersEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'batch/:inputData',
                        name: 'applicationBatchDetail',
                        component: () => import('../components/applications/BatchAction.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/users',
                name: 'users',
                component: () => import('../views/dashboard/admin/Users.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                    tourGroup : 'users',
                },
                children: [
                    {
                        path: 'add',
                        name: 'userAdd',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                        }
                    },
                    {
                        path: 'edit/:userId',
                        name: 'userEdit',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'devices/:userId',
                        name: 'userDevices',
                        component: () => import('../components/users/UserDevices.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/users-admin',
                name: 'usersAdmin',
                component: () => import('../views/dashboard/admin/Users.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                    tourGroup : 'users',
                },
                children: [
                    {
                        path: 'add',
                        name: 'userAdminAdd',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                        }
                    },
                    {
                        path: 'edit/:userId',
                        name: 'userAdminEdit',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'devices/:userId',
                        name: 'userAdminDevices',
                        component: () => import('../components/users/UserDevices.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/transactions',
                name: 'transactions',
                component: () => import('../views/dashboard/admin/Transactions.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_BILLING]
                },
                children: []
            },
            {
                path: 'admin/invoices',
                name: 'invoices',
                component: () => import('../views/dashboard/admin/Invoices.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_BILLING]
                },
                children: []
            },
            {
                path: 'admin/billing',
                name: 'billing',
                component: () => import('../views/dashboard/admin/BillingDetails.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_BILLING]
                },
                children: []
            },
            {
                path: 'admin/tags',
                name: 'tagTypes',
                component: () => import('../views/dashboard/admin/TagTypes.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                    tourGroup : 'tags',
                },
                children: [
                    {
                        path: 'add',
                        name: 'tagTypeAdd',
                        component: () => import('../components/tag_types/TagTypeEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'tagTypeEdit',
                        component: () => import('../components/tag_types/TagTypeEdit.vue'),
                        bbeforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/additional-documents',
                name: 'additionalDocuments',
                component: () => import('../views/dashboard/admin/AdditionalDocuments.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT],
                    tourGroup : 'additional_documents',
                },
                children: [
                    {
                        path: 'add',
                        name: 'additionalDocumentAdd',
                        component: () => import('../components/additional_documents/AdditionalDocumentEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'additionalDocumentEdit',
                        component: () => import('../components/additional_documents/AdditionalDocumentEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                        }
                    },
                ]
            }, {
                path: 'admin/additional-features',
                name: 'additionalFeatures',
                component: () => import('../views/dashboard/admin/AdditionalFeatures.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                },
            },
            {
                path: 'admin/config-log',
                name: 'configLog',
                component: () => import('../views/dashboard/admin/ConfigLog.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ADMIN, GUARD_PAYMENT]
                },
            },
            {
                path: 'admin/nationalities',
                name: 'nationalities',
                component: () => import('../views/dashboard/admin/super_admin/Nationalities.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'nationalityAdd',
                        component: () => import('../components/nationalities/NationalityEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'nationalityEdit',
                        component: () => import('../components/nationalities/NationalityEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]

            },
            {
                path: 'admin/nationality-groups',
                name: 'nationalityGroups',
                component: () => import('../views/dashboard/admin/super_admin/NationalityGroups.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'nationalityGroupAdd',
                        component: () => import('../components/nationality_groups/NationalityGroupEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'nationalityGroupEdit',
                        component: () => import('../components/nationality_groups/NationalityGroupEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/faqs',
                name: 'faqs',
                component: () => import('../views/dashboard/admin/super_admin/Faqs.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'faqAdd',
                        component: () => import('../components/support/FaqEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'faqEdit',
                        component: () => import('../components/support/FaqEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/announcements',
                name: 'announcements',
                component: () => import('../views/dashboard/admin/super_admin/Announcements.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'announcementAdd',
                        component: () => import('../components/announcements/AnnouncementEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'announcementEdit',
                        component: () => import('../components/announcements/AnnouncementEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/document-types',
                name: 'documentTypes',
                component: () => import('../views/dashboard/admin/super_admin/DocumentTypes.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'documentTypeAdd',
                        component: () => import('../components/document_types/DocumentTypeEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'documentTypeEdit',
                        component: () => import('../components/document_types/DocumentTypeEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/questions',
                name: 'questions',
                component: () => import('../views/dashboard/admin/super_admin/Questions.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'questionAdd',
                        component: () => import('../components/questions/QuestionEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'questionEdit',
                        component: () => import('../components/questions/QuestionEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/api-clients',
                name: 'clients',
                component: () => import('../views/dashboard/admin/super_admin/ApiClients.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'clientAdd',
                        component: () => import('../components/api_clients/ClientEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]
            },
            {
                path: 'admin/organisations',
                name: 'organisations',
                component: () => import('../views/dashboard/admin/super_admin/Organisations.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                },
                children: [
                    {
                        path: 'add',
                        name: 'organisationAdd',
                        component: () => import('../components/organisations/OrganisationEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'organisationEdit',
                        component: () => import('../components/organisations/OrganisationEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                    {
                        path: 'add-user/:organisationId/:organisationName',
                        name: 'organisationUserAdd',
                        component: () => import('../components/users/UserEdit.vue'),
                        beforeEnter: beforeEnter,
                        props: true,
                        meta: {
                            showModal: true,
                            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                        }
                    },
                ]

            },
            {
                path: 'admin/billing',
                name: 'billing',
                component: () => import('../views/dashboard/admin/super_admin/Billing.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                }
            },
            {
                path: 'admin/settings',
                name: 'settings',
                component: () => import('../views/dashboard/admin/super_admin/Settings.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                }
            },
            {
                path: 'admin/analytics',
                name: 'analytics',
                component: () => import('../views/dashboard/admin/super_admin/Analytics.vue'),
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_SUPER, GUARD_PAYMENT]
                }
            }
        ]
    },
    {
        path: '/web-app',
        component: WebApp,
        beforeEnter: beforeEnter,
        meta: {
            guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
        },
        children: [
            {
                path: '/web-app',
                component: ApplicationList,
                name: 'web_app_application_list',
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'start-app/:applicationId',
                name: 'web_app_start_app',
                component: ApplicationList,
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'basic_info',
                name: 'web_app_basic_info',
                component: BasicInfo,
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'documents',
                name: 'web_app_documents',
                component: Documents,
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'photo',
                name: 'web_app_photo',
                component: Photo,
                beforeEnter: beforeEnter,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'questions',
                name: 'web_app_questions',
                beforeEnter: beforeEnter,
                component: Questions,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
            {
                path: 'student',
                name: 'web_app_student',
                beforeEnter: beforeEnter,
                component: Student,
                meta: {
                    guards: [GUARD_ORGANISATION_QUERY,GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            }, {
                path: 'additional_documents',
                name: 'web_app_additional_documents',
                beforeEnter: beforeEnter,
                component: AdditionalDocuments,
                meta: {
                    guards: [GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            }, {
                path: 'decision',
                name: 'web_app_decision',
                beforeEnter: beforeEnter,
                component: Decision,
                props: true,
                meta: {
                    guards: [GUARD_IS_AUTHENTICATED, GUARD_ORG_SETUP, GUARD_ROLE_ACCESS, GUARD_ROLE_ACCESS, GUARD_PAYMENT]
                },
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
var routeParams;
router.beforeEach((to, from, next) => {
    console.log("GOIN TO ",to);
    console.log("COMING FROM ",from);

    if(to.params !== undefined && Object.keys(to.params).length) {
        routeParams = to.params;
    }
    console.log("PARAMS ARE",routeParams);
    if (from.query.session_organisation_id != null || to.query.session_organisation_id != null) {
        console.log("1");
        if(to.query.session_organisation_id != null){
            console.log("2");
            next();
            return;
        }
        if(to.path === from.path) {
            console.log("3");
            //STILL NEED TO ADD THE SESSION ORG ON
            to.query.session_organisation_id = from.query.session_organisation_id;
            next({path: to.path, query: to.query, params : routeParams});
        } else {
            if(to.query.session_organisation_id !== from.query.session_organisation_id) {
                console.log("4");
                to.query.session_organisation_id = from.query.session_organisation_id;
                next({path: to.path, query: to.query, meta : to.meta});
            } else {
                console.log("5");
                next({params : routeParams});
            }
        }
    } else {
        if (to.meta !== undefined && to.meta.guards !== undefined && to.meta.guards.indexOf(GUARD_ORGANISATION_QUERY) !== -1) {
            console.log("6");
            // console.log("TO IS",to);
            // console.log("FROM IS",from);
            //CHECK IF ORGANISATION IS ONLY ONE
            if(store.state.user !== undefined && store.state.user.organisation_users !== undefined && store.state.user.organisation_users.length === 1) {
                console.log("7");
                to.query.session_organisation_id = store.state.user.organisation_users[0].organisation_id;
                next({path: to.path, query: to.query, params : routeParams});
                return;
            }
            console.log("8");
            next('/workspace-select');
        } else {
            console.log("9");
            next({params : routeParams});
        }

    }
});



export default router
