<template>
    <div id="app">

        <div v-if="!hideApp" style="top: 0; bottom: 0; left: 0; right: 0;" class="position-absolute d-flex flex-column">

            <b-alert
                    :show="dismissCountDown"
                    dismissible
                    variant="warning"
                    @dismissed="dismissCountDown=0"
                    @dismiss-count-down="countDownChanged"
            >
                {{ errorMessage }}
            </b-alert>
            <div v-if="flightPathSetup" style="top: 0; bottom: 0; left: 0; right: 0;"
                 class="position-absolute d-flex flex-column">
                <router-view/>
            </div>
            <div v-else style="top: 0; bottom: 0; left: 0; right: 0;"
                 class="position-absolute d-flex flex-column justify-content-center align-items-center">
                <h1>Access is now restricted</h1>
                <div>{{ flightPathError }}</div>
            </div>

            <b-modal size="lg"
                     v-model="signupComplete"
                     title="Registration complete"
                     ok-title="Sign in now"
                     cancel-title="Not now, i'll sign in later"
                     @ok="signIn"
                     ok-variant="success"
                     footer-class="d-flex justify-content-between">
                <div slot="default">
                    <div class="alert alert-success mb-0">Thank you for completing your registration for Access Right To
                        Work.
                    </div>
                </div>
            </b-modal>

            <b-modal size="lg"
                     v-model="showFailure"
                     title="Reject application?"
                     ok-title="Yes, reject application"
                     cancel-title="No, I completed a question incorrectly"
                     @ok="rejectApplication"
                     @cancel="cancelRejection"
                     footer-class="d-block">
                <div slot="default">
                    {{ failureMessage }}
                </div>
            </b-modal>

            <b-modal size="lg"
                     v-model="showApiError"
                     title="There has been an error"
                     ok-title="Retry"
                     cancel-title="Cancel"
                     @ok="apiErrorRetry"
                     @cancel="apiErrorCancel"
                     footer-class="d-block">
                <div slot="default" v-html="apiError">
                    {{ apiError }}
                </div>
            </b-modal>
        </div>

        <b-tooltip v-for="(step,stepKey) in $store.getters.getTourSteps" triggers="" :show="isFirstStep(step.id)"
                   v-bind:key="step.id" ref="tooltip" variant="secondary" :target="step.target">
            <div class="font-weight-bold border-bottom border-dark py-2">{{ step.title }}</div>
            <div class="py-2 ">{{ step.content }}</div>
            <div class="py-2 border-top border-dark">
                <b-button v-if="$store.getters.getTourSteps.length > 1" variant="outline-light" size="sm" class="mx-1"
                          @click="completeSteps($store.getters.getTourSteps.map((obj) => {return obj.id}))">Skip
                </b-button>
                <b-button variant="outline-light" size="sm" class="mx-1" @click="completeSteps([step.id])">{{
                    $store.getters.getTourSteps.length > 1 ? "Next" : "Finish" }}
                </b-button>
            </div>
        </b-tooltip>

        <!--        <v-tour name="myTour" :options="tourOptions" :steps="$store.getters.getTourSteps" :callbacks="tourCallbacks"></v-tour>-->

        <b-modal :no-close-on-backdrop="announcement != null && announcement.is_compulsory == 1" :no-close-on-esc="announcement != null && announcement.is_compulsory == 1" :hide-header-close="announcement != null && announcement.is_compulsory == 1" v-model="showAnnouncement" ref="announcement-modal" hide-footer hide-title :title="announcement != null ? announcement.title : '' ">
            <div v-if="announcement != null" class="d-block mb-4" v-html="announcement.content">
                {{ announcement != null ? announcement.content : '' }}
            </div>
            <b-button class="mt-3" variant="success" block @click="announcementConfirm">
                <template v-if="loadingAnnouncement">
                    <div class="d-flex align-items-center">

                    <b-spinner style="width: 1rem; height: 1rem;" variant="dark" class="mr-2" type="grow" label="Spinning"></b-spinner> Loading

                    </div>
                </template>
                <template v-else>
                    {{ announcement != null ? announcement.button_text : '' }}
                </template>
                </b-button>
        </b-modal>

        <b-toast id="app-toast" :variant="toastVariant" :title="toastTitle">
            {{ toastMessage }}
        </b-toast>
    </div>
</template>

<script>
    import axios from "axios"
    import {deleteResource, getResource, saveResource} from "./modules/api/methods";
    import {
        announcementDetail, announcementDismiss,
        announcementsDue,
        ssoAuthorize,
        ssoAuthorizeHint,
        tour,
        userCurrent
    } from "./modules/api/endpoints";
    import {GUARD_IS_AUTHENTICATED} from "./router";
    import _ from "lodash";
    import {containsErrors} from "./modules/helpers/helpers";
    import {mapGetters} from "vuex";

    export default {
        data: function () {
            return {
                loadingAnnouncement : false,
                announcement : {},
                showAnnouncement: false,
                hideApp: false,
                signupComplete: false,
                toastTitle: null,
                toastMessage: null,
                toastVariant: null,
                dismissSecs: 10,
                dismissCountDown: 0,
                errorMessage: null,
                failureMessage: null,
                showFailure: false,
                showApiError: false,
                apiError: null,
                apiRetryMethod: null,
                flightPathError: null,
                tourOptions: {
                    labels: {
                        buttonSkip: 'Skip',
                        buttonPrevious: null,
                        buttonNext: 'OK',
                        buttonStop: 'OK'
                    },
                },
                tourCallbacks: {
                    onPreviousStep: this.onTourPrevious,
                    onNextStep: this.onTourNext,
                    onFinish: this.onFinish,
                    onSkip: this.onFinish
                }
            }
        },
        created: function () {
            //CHECK FOR A COMPLETE SIGN UP
            if (this.$route.query.register_complete) {
                this.$router.push({query: {}});
                this.signupComplete = true;
            }

            //CHECK FOR IFRAME

            if (window.location !== window.parent.location) {
                this.$store.commit('setWorkspace', true);
            } else {
                this.$store.commit('setWorkspace', false);
            }

            let workspaceId = localStorage.getItem('workspaceUserId');

            var vm = this;
            axios.interceptors.request.use(function (config) {
                let store = vm.$store;
                // Do something before request is sent
                if (store.state.token != null && (config.headers.Authorization === undefined || config.headers.Authorization == null)) {
                    console.log("ADDING NEW AUTH HEADER");
                    config.headers.Authorization = "Bearer " + store.state.token
                }
                if (workspaceId != null && config.url.indexOf("impersonate") === -1 && !store.getters.isImpersonating) {
                    config.headers.WorkspaceUserId = workspaceId;
                } else if (vm.$router.currentRoute.query.session_organisation_id != null) {
                    config.headers.OrganisationId = vm.$router.currentRoute.query.session_organisation_id;
                }
                if (store.getters.isWorkspace) {
                    config.headers.FromWorkspace = 1;
                }
                if (store.state.refreshToken != null && !store.getters.isImpersonating && (config.headers.IdentityRefresh === undefined || config.headers.IdentityRefresh == null)) {
                    //DONT WANT REFRESH WHEN IMPERSONATING
                    config.headers.IdentityRefresh = store.state.refreshToken
                }
                return config;
            }, function (error) {
                return Promise.reject(error);
            });
            axios.interceptors.response.use(
                (resp) => {
                    console.log("RESPONSE IS", resp);
                    if (resp.headers !== undefined) {
                        if (resp.headers['identitytoken'] !== undefined && resp.headers['identityrefreshtoken'] !== undefined && vm.$store.getters.isImpersonating !== true) {
                            if (vm.$store.getters.currentToken != null) {
                                console.log("REFRESHED TOKEN");
                                vm.$store.commit("updateToken", {
                                    token: resp.headers['identitytoken'],
                                    refreshToken: resp.headers['identityrefreshtoken'] !== undefined ? resp.headers['identityrefreshtoken'] : null
                                });
                            }
                        }
                        if (resp.headers['flight-path'] !== undefined) {
                            vm.updateFlightPath(resp.headers['flight-path']);
                        }
                    }
                    //CHECK FOR WORKSPACE ERRORS
                    if (resp.data !== undefined && resp.data.workspaces !== undefined && this.$router.currentRoute.meta.guards.indexOf(GUARD_IS_AUTHENTICATED) !== -1 && this.$router.currentRoute.name !== 'workspaceSelect') {
                        vm.$router.replace({'name': 'workspaceSelect'}).then(() => {
                            this.$root.$children[0].showToastMessage("Select workspace", "Please select a workspace organisation to proceed with, please try again", "danger");

                        });
                    }

                    if ((resp.response !== undefined && resp.response.data !== undefined && resp.response.data.maintenance !== undefined) || (resp.data !== undefined && resp.data.maintenance !== undefined)) {
                        this.$root.$children[0].showToastMessage("Ooops", "Access RTW is down for routine maintenance. Please check back later", "danger");
                        if (vm.$router.currentRoute.name !== 'workspaceNoAuth' && vm.$router.currentRoute.name !== 'home') {
                            if (!this.$store.getters.isWorkspace) {
                                this.$router.replace({'name': 'home'});
                            } else {
                                this.$router.replace({'name': 'workspaceNoAuth'});
                            }
                        }
                        return resp;
                    }

                    return resp
                },
                (err) => {
                    console.log("ERROR IS", err);
                    //CHECK FOR MIGRATION LINK FIRST
                    if (err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.migration_link !== undefined) {
                        this.hideApp = true;
                        window.location = err.response.data.migration_link;
                        return err;
                    } else if (err !== undefined && err.data !== undefined && err.data.migration_link !== undefined) {
                        this.hideApp = true;
                        window.location = err.data.migration_link;
                        return err;
                    }


                    if (containsErrors(err.response)) {
                        console.log("CONTAINS ERRORS IN REPSONSE");
                        this.showToastMessage("Ooops", err.response.data.errors.global.join("\n"), "danger");
                    } else if (containsErrors(err)) {
                        console.log("CONTAINS ERRORS IN ROOT");
                        this.showToastMessage("Ooops", err.data.errors.global.join("\n"), "danger");
                    }

                    if ((err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.maintenance !== undefined) || (err.data !== undefined && err.data.maintenance !== undefined)) {
                        this.$root.$children[0].showToastMessage("Ooops", "Access RTW is down for routine maintenance. Please check back later", "danger");
                        if (vm.$router.currentRoute.name !== 'workspaceNoAuth' && vm.$router.currentRoute.name !== 'home') {
                            if (!this.$store.getters.isWorkspace) {
                                this.$router.replace({'name': 'home'});
                            } else {
                                this.$router.replace({'name': 'workspaceNoAuth'});
                            }
                        }
                        return err;
                    }

                    if (err.response !== undefined && err.response.status === 401) {
                        console.log("LOGOUT RESPONSE", err.response);
                        vm.$store.commit("logout");
                        if (!this.$store.getters.isWorkspace) {

                        }
                        if (vm.$router.currentRoute.name !== 'workspaceNoAuth' && vm.$router.currentRoute.name !== 'home') {
                            if (!this.$store.getters.isWorkspace) {
                                this.$router.replace({'name': 'home'});
                            } else {
                                this.$router.replace({'name': 'workspaceNoAuth'});
                            }
                        }

                        if (err.response.data !== undefined && err.response.data.errors !== undefined && err.response.data.errors.global !== undefined) {
                            this.showToastMessage("Ooops", err.response.data.errors.global.join("\n"), "danger");

                        } else {
                            if (!this.$store.getters.isWorkspace) {
                                this.$root.$children[0].showToastMessage("Ooops", "Your session has expired or access has been revoked. Please sign in again to continue.", "danger");
                            }
                        }

                        return err;
                    }
                    return err;
                });
            // if (this.$store.state.token != null) {
            //     this.$store.dispatch('refreshUserCall');
            // }
            setInterval(function () {

                if (this.$store.getters.currentToken != null) {
                    this.$store.dispatch('refreshUser');
                }
            }.bind(this), 1000);
        },
        mounted() {

            if (this.$store.getters.isAuthenticated) {
                this.loadTour();
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.updateCurrentSteps(newVal);
                }
            },
            showAnnouncement: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    if(!newVal){
                        console.log("ANNOUNCEMENT CLOSED");
                        if(this.announcement !== undefined && this.announcement.id !== undefined) {
                            this.$store.dispatch("muteAnnouncement", this.announcement).catch((e)=> {
                                console.log("MUTED ERROR",e);
                            });
                        }
                    }
                }
            },
        },
        components: {},
        computed: {
            ...mapGetters(['isAnnouncementMuted']),
            flightPathSetup() {
                this.flightPathError = null;
                var store = this.$store;

                if (this.$router.currentRoute.meta.bypassFlightpath === true || store.state.user == null || store.state.user.organisation == null || store.state.user.organisation.setup_completed_at != null || store.state.user.organisation.flight_path_id == null) {
                    return true;
                }

                if (store.state.user.organisation.flight_path_step != null) {
                    var step = store.state.user.organisation.flight_path_step;
                    this.flightPathError = step.no_access_message;
                    if (step.show_link === 1) {
                        if (step.access_level == null) {
                            return false;
                        }
                        if (step.access_level === "super" && store.getters.isSuper || (store.state.impersonatorUser != null && store.state.impersonatorUser.super === 1)) {
                            return true;
                        }
                        if (step.access_level === "admin" && (store.getters.isSuper || (store.state.impersonatorUser != null && store.state.impersonatorUser.super === 1) || store.getters.isAdmin)) {
                            return true;
                        }
                        if (step.access_level === "supervisor" && (store.getters.isSuper || (store.state.impersonatorUser != null && store.state.impersonatorUser.super === 1) || store.getters.isAdmin || store.getters.isSupervisor)) {
                            return true;
                        }
                        if (step.access_level === "user" && (store.getters.isSuper || (store.state.impersonatorUser != null && store.state.impersonatorUser.super === 1) || store.getters.isAdmin || store.getters.isSupervisor || store.getters.isAuthenticated)) {
                            return true;
                        }


                        return false;
                    } else {
                        return true;
                    }
                }
                this.flightPathError = "Your organisation has a FlightPath configuration error. If the problem persists, please contact support";
                return false;
            },
        },
        methods: {
            displayAnnouncement(announcement) {
                this.showAnnouncement = true;
                this.announcement = announcement;
                console.log("SHOWING THE ANNOUNCEMENT");
            },
            checkAnnouncements() {
                var _this = this;
                console.log("CHECKING ANNOUNCEMENT");
                saveResource(announcementsDue,{platform : this.$store.getters.isWorkspace ? "workspace" : "web"}).then((resp) => {
                    console.log(resp.data.success);
                    if(resp.data.success.data.length > 0) {
                        _.each(resp.data.success.data,function(an) {
                            if(!_this.isAnnouncementMuted(an.id)) {
                                console.log("SHOWING ANNOUNCEMENT", an);
                                _this.displayAnnouncement(an);
                            }
                        });

                    } else {
                        console.log("DATA IS EMPTY");
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    //CLEAR ANNOUNCEMENTS
                });
            },
            announcementConfirm() {
                console.log("CONFIRMING ANNOUNCEMENTS",this.announcement);
                if(this.announcement != null && this.$router.currentRoute.name !== "announcements") {
                    this.loadingAnnouncement = true;
                    getResource(announcementDismiss(this.announcement.id)).then((resp) => {
                        console.log("ANNOUNCEMENT CONFIRM RESPONSE",resp);
                    }).catch((err) => {
                        console.log("ANNOUNCEMENT CONFIRM ERROR",err);
                    }).finally(() => {
                        this.showAnnouncement = false;
                        this.loadingAnnouncement = false;
                        //CLEAR ANNOUNCEMENTS
                        if(this.$router.currentRoute.name !== "announcements") {
                            this.checkAnnouncements();
                        }
                    });
                } else {
                    this.showAnnouncement = false;
                    if(this.$router.currentRoute.name !== "announcements") {
                        this.checkAnnouncements();
                    }
                }
            },
            signIn() {
                window.location = ssoAuthorizeHint("");
            },
            updateCurrentSteps(route) {
                this.$store.commit('updateCurrentSteps', _.clone(this.$store.state.currentSteps));
            },
            loadTour() {
                this.$store.commit('updateCurrentSteps', []);
                getResource(tour).then((res) => {
                    let data = res.data.success.data;
                    this.$store.commit('updateCurrentSteps', data);
                    this.updateCurrentSteps(null);
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {

                });
            },
            isFirstStep(stepId) {
                return this.$store.getters.getTourSteps.length > 0 && this.$store.getters.getTourSteps[0].id === stepId;
            },
            completeSteps(steps) {
                console.log("COMPLETING STEP", steps);
                this.$store.commit('completeSteps', steps);
                saveResource(tour, {step_ids: steps}).then((resp) => {
                    console.log(resp);
                }).catch((err) => {
                    console.log(err);
                });
            },
            flightPathLogout() {
                this.$root.$children[0].showToastMessage("Ooops", "Your flight path configuration has changed so you have been automatically logged out.", "danger");
            },
            logout() {
                this.$store.commit('logout');
                this.$router.replace({'name': 'login'})
            },
            updateFlightPath(flightPath) {

                var existing = this.$store.state.currentFlightPath;
                if (existing !== flightPath) {
                    if (!this.$store.getters.impersonatorIsSuper && !this.$store.getters.isSuper) {
                        if (flightPath === 'Launching') {
                            //IF IS LAUNCHING THEN SHOULDNT BE LOGGED IN
                            this.flightPathLogout();
                            this.logout();
                            return;
                        } else if (flightPath === 'Launched' && existing != null) {
                            //IF IS LAUNCHED AND WASNT PREVIOUSLY THEN SHOULDNT BE LOGGED IN
                            this.flightPathLogout();
                            this.logout();
                            return;
                        } else if (existing === 'Launched') {
                            //IF WAS PREVIOUSLY LAUNCHED AND HAS BEEN MOVED BACK, NEED TO WIPE DATA
                            this.flightPathLogout();
                            this.logout();
                            return;
                        }
                    }

                    //NOW UPDATE IT
                    this.$store.state.currentFlightPath = flightPath;

                }
            },

            showNewToastMessage(title, message, variant) {
                this.toastTitle = title;
                this.toastMessage = message;
                this.toastVariant = variant;
                this.$bvToast.toast(message, {
                    title: title,
                    variant: variant,
                    solid: true
                })
            },
            showToastMessage(title, message, variant) {
                this.toastTitle = title;
                this.toastMessage = message;
                this.toastVariant = variant;
                this.$bvToast.show('app-toast');
            },
            showLogoutMessage() {
                this.showToastMessage("Ooops", "You have been logged out", "danger");
            },
            apiErrorRetry(ev) {
                ev.preventDefault();
                if (this.apiRetryMethod != null) {
                    this.apiRetryMethod(ev);
                }
                this.showApiError = false;
                this.apiError = null;
            },
            apiErrorCancel(ev) {
                ev.preventDefault();
                this.apiRetryMethod = null;
                this.showApiError = false;
                this.apiError = null;
            },
            handleInvalidResponse(response, failedMethod) {
                this.apiError = "A server error has occured, please first check your internet connection and retry below. If the problem persists, please contact support.";
                this.apiRetryMethod = failedMethod;
                this.showApiError = true;
            },
            handleApiError(error, failedMethod) {
                //DEFAULT MESSAGE
                this.apiError = "There was an error with this request";

                //KNOWN RESPONSE CODES HERE
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        this.apiError = "Your session has expired, <a target='_blank' href='/'>please click here to re-authenticate</a>. Once you have re-authenticated you can click retry below";
                    }
                    if (error.response.status === 403) {
                        this.apiError = "You do not have access to this resource";
                    }
                    if (error.response.status === 404) {
                        this.apiError = "This resource cannot be found or you do not have access to it.";
                    }
                    if (error.response.status === 500) {
                        this.apiError = "A server error has occured, please first check your internet connection and retry below. If the problem persists, please contact support.";
                    }
                }

                this.apiRetryMethod = failedMethod;
                this.showApiError = true;

            },
            handleJsonResponse(response) {
                if (response.redirect !== undefined) {
                    this.$router.replace({name: response.redirect});
                }
                if (response.errors !== undefined) {
                    if (response.errors.global !== undefined) {
                        this.showErrorMessage(response.errors.global.join("\n"));
                    }
                }
            },
            cancelRejection(ev) {
                ev.preventDefault();
                this.failureMessage = null;
                this.showFailure = false;
            },
            rejectApplication() {
                this.failureMessage = null;
                this.showFailure = false;
                this.$store.dispatch('updateResult', 'fail').then(() => {
                    this.$router.push({name: 'web_app_decision', params: {decision: 'fail'}}).catch(error => {
                    })
                });
            },
            approveApplication() {
                this.failureMessage = null;
                this.showFailure = false;
                this.$store.dispatch('updateResult', 'pass').then(() => {
                    this.$router.push({name: 'web_app_decision', params: {decision: 'pass'}}).catch(error => {
                    })
                });
            },
            handleFailure(failureMessage) {
                this.failureMessage = failureMessage;
                this.showFailure = true;
            },
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            showErrorMessage(message) {
                this.errorMessage = message;
                this.dismissCountDown = this.dismissSecs;
            }
        }

    }
</script>

<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
