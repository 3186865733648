<template>
    <div class="p-2 bg-white border-top border-bottom d-flex">
        <a :class="faqClasses"  @click="goFaqs">
            <b-icon-question-circle class="mr-2"></b-icon-question-circle>
            FAQ's
        </a>
        <a :class="mediaClasses"  @click="goMedia">
            <b-icon-camera-video class="mr-2"></b-icon-camera-video>
            Training media
        </a>
        <a class="btn btn-sm mx-2  d-flex align-items-center"  href="https://docs.accessrtw.co.uk/" target="_blank">
            <b-icon-book class="mr-2"></b-icon-book>
            Manuals
        </a>
    </div>
</template>

<script>
    export default {
        name: "SupportMenu",
        data : function() {
            return {
                faqClasses : 'btn btn-sm mx-2  d-flex align-items-center',
                mediaClasses : 'btn btn-sm mx-2 d-flex align-items-center text-primary',
            }
        },
        created() {
            this.checkActiveLinks(this.$router.currentRoute);
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.checkActiveLinks(newVal);
                }
            },
        },
        methods : {
            checkActiveLinks(newVal) {
                if(newVal.name === 'support') {
                    this.faqClasses = 'btn btn-sm mx-2 d-flex align-items-center text-primary';
                } else {
                    this.faqClasses = 'btn btn-sm mx-2 d-flex align-items-center';
                }
                if(newVal.name === 'supportMedia') {
                    this.mediaClasses = 'btn btn-sm mx-2 d-flex align-items-center text-primary';
                } else {
                    this.mediaClasses = 'btn btn-sm mx-2 d-flex align-items-center';
                }
            },
            goMedia() {
                this.$router.push({name : 'supportMedia'}).catch(error => {})
            },
            goFaqs() {
                this.$router.push({name : 'support'}).catch(error => {})
            }
        }
    }
</script>

<style scoped>

</style>
