import axios from "axios";
import * as endpoint from "./endpoints.js";
import {baseApi} from "./endpoints";

const jsonConfig = {
    headers: {
        Accept: "application/json"
    }
};

export const login = (creds) => new Promise((resolve, reject) => {
    {
        let config = {
            headers: {
                Authorization: "Basic "+btoa(creds.email + ":" + creds.password),
                Accept: "application/json"
            }
        };
        console.log("LOGIN CONFIG",config);
        axios.get(endpoint.login, config)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const ssoCheck = (email) => new Promise((resolve, reject) => {
    {
        axios.post(endpoint.ssoCheck, {email : email},jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});
export const getEndpoint = (endpoint) => new Promise((resolve, reject) => {
    {
        axios.get(baseApi()+endpoint,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});
export const postEndpointCancellable = (endpoint,data,cancelSource) => new Promise((resolve, reject) => {
    {
        jsonConfig.cancelToken = cancelSource;
        axios.post(baseApi()+endpoint,data,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const applications = (filters) => new Promise((resolve, reject) => {
    {
        axios.post(endpoint.applications,filters,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const saveResource = (endpoint,model) => new Promise((resolve, reject) => {
    {
        axios.post(endpoint,model,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            });
    }
});

export const saveResourceWithHeaders = (endpoint,model,headers) => new Promise((resolve, reject) => {
    {
        var configCopy = jsonConfig;
        if(headers.typeof === "array") {
            for(var i = 0; i < headers.length; i++) {
                configCopy.headers.push(headers[i]);
            }
        }
        axios.post(endpoint,model,configCopy)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const getResource = (endpoint) => new Promise((resolve, reject) => {
    {
        axios.get(endpoint,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const getResourceWithRefresh = (endpoint,refreshToken) => new Promise((resolve, reject) => {
    {
        if(refreshToken != null) {
            jsonConfig.headers.IdentityRefresh = refreshToken;
        }
        axios.get(endpoint,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject);
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const deleteResource = (endpoint) => new Promise((resolve, reject) => {
    {
        axios.delete(endpoint,jsonConfig)
            .then(resp => {
                handlResponse(resp,resolve,reject)
            })
            .catch(err => {
                reject(err);
            })
    }
});

export const handlResponse = (resp,resolve,reject) => {
    if(resp !== undefined && resp.data !== undefined && resp.data.success !== undefined) {
        resolve(resp);
    } else {
        reject(resp);
    }
}



