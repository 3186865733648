import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueTreeNavigation from 'vue-tree-navigation';
import VueAuthImage from 'vue-auth-image';
import VueCookie from 'vue-cookie'
import browserDetect from "vue-browser-detect-plugin";
import * as Sentry from '@sentry/browser';
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
    id: 'UA-188151268-1',
    debug: {
        sendHitTask: process.env.NODE_ENV === 'production'
    },
    router
});

require('vue-tour/dist/vue-tour.css');

import {Promise} from 'es6-promise';
if (!('Promise' in window)) {
    window.Promise = Promise;
} else if (!('finally' in window.Promise.prototype)) {
    window.Promise.prototype.finally = Promise.prototype.finally;
}

Sentry.init({ dsn: process.env.VUE_APP_SENTRY_DSN });

Vue.use(VueTreeNavigation);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAuthImage);
Vue.use(VueCookie);
Vue.use(browserDetect);
import './assets/_style.scss'

Vue.prototype.$http = axios;

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

const token = store.state.token;
const actingAdmin = store.state.actingAdmin;
if (token) {
  axios.defaults.headers.common['Accept'] = "*/*" // ADDED DUE TO Vue-auth-image requesting application/json type
}
if(actingAdmin) {
    axios.defaults.headers.common['ActingAdmin'] = "true"
}
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
