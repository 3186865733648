<template>
    <loader-panel :isLoading="isLoading">

        <b-card v-if="studentRequired && studentQuestion != null" no-body class="bg-white">

            <b-list-group flush>

                <b-list-group-item>
                    <div class="d-flex">
                        <div class="mr-3 flex-wrap" v-if="student != null">
                            <i class="fa fa-check text-success"></i>
                        </div>
                        <div class="flex-fill">
                            <p class="font-weight-bold">{{ studentQuestion }}</p>
                            <p>
                                <b-select class="form-control" v-model="student">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </b-select>
                            </p>
                        </div>
                    </div>

                    <error-display v-model="errors" ident="student"></error-display>

                </b-list-group-item>

            </b-list-group>

            <b-card-footer v-if="student != null" class="d-block d-flex justify-content-end align-items-center">
                <b-button class="flex-wrap" variant="success" v-on:click="saveForm">Save and continue</b-button>
            </b-card-footer>

        </b-card>

    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import {saveResource} from "../../../modules/api/methods";
    import {applicationStudent} from "../../../modules/api/endpoints";
    import {containsErrors} from "../../../modules/helpers/helpers";
    import ErrorDisplay from "../../form_inputs/ErrorDisplay";

    export default {
        name: 'student',
        components: {
            ErrorDisplay,
            LoaderPanel

        },
        data: function () {
            return {
                errors: {},
                isLoading: false,
                studentRequired: false,
                student: null,
                studentQuestion: null,
            }
        },
        created() {
            if (this.$store.state.user.organisation.ask_student_question === 'never') {
                this.$router.push({name: 'web_app_additional_documents'}).catch(error => {})
            } else if (this.$store.state.user.organisation.ask_student_question === 'by_nationality') {
                if (this.$store.state.application.nationality.nationality_group.ask_student_question !== 1) {
                    this.$router.push({name: 'web_app_additional_documents'}).catch(error => {})
                }
            }

            this.studentRequired = true;
            this.studentQuestion = this.$store.state.user.organisation.student_question;

        },
        methods: {
            saveForm(ev) {
                this.isLoading = true;
                ev.preventDefault();
                saveResource(applicationStudent(this.$store.state.application.id), {student: this.student}).then(response => {
                    this.$store.dispatch('updateApplication', response.data.success.data).then(() => {
                        this.$router.push({name: 'web_app_additional_documents'}).catch(error => {})
                    });
                }).catch(error => {
                    if (containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error, () => {
                            return this.saveForm;
                        })
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>
