import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import moment from "moment";
import {getResource, getResourceWithRefresh} from "../modules/api/methods";
import {userCurrent} from "../modules/api/endpoints";
import router, {GUARD_IS_AUTHENTICATED} from "../router/index"
import createMutationsSharer from "vuex-shared-mutations";
import _ from 'lodash';

Vue.use(Vuex)

//STORAGE ACTION CONSTANTS

export default new Vuex.Store({
    state: {
        mutedAnnouncements : [],
        workspace: false,
        workspaceUserId: null,
        organisationId: null,
        actingAdmin: false,
        token: null,
        refreshToken: null,
        user: null,
        impersonatorToken: null,
        impersonatorRefreshToken: null,
        impersonatorUser: null,
        currentResult: null,
        application: {},
        lastPolledUser: 0,
        currentFlightPath: null,
        tourSteps: [],
        currentSteps: [],
    },
    getters: {
        isAnnouncementMuted(state){
            return (announcementId) => {
                var dateToMute = moment().subtract().subtract(1, "hours").toDate();
                console.log("MUTED DAte ",dateToMute.getTime());
                console.log("MUTED ID ",announcementId);
                console.log("MUTED FULL ",state.mutedAnnouncements);
                var muted =  _.filter(state.mutedAnnouncements, function(o) {
                    return (o.announcementId == announcementId && o.date > dateToMute.getTime());
                });
                console.log("MUTED ARE ",muted);
                return muted.length > 0
            }
        },
        currentToken: state => state.token,
        getTourSteps: state => {
            var finalSteps = [];
            _.each(state.currentSteps, function (step) {
                if (document.querySelector("#" + step.target) != null && step.completed_at == null) {
                    finalSteps.push(step);
                }
            });
            return _.sortBy(finalSteps, ['sort']);
        },
        isWorkspaceAccount: state => !!state.user && !!state.user.organisation && state.user.organisation.workspace_id != null,
        isWorkspace: state => state.workspace,
        isAuthenticated: state => !!state.token && !!state.user,
        isSetup: state => !!state.user && state.user.app_access === 1,
        isOrgSetup: state =>  {
            let hasUser = !!state.user;
            let hasOrganisation = hasUser && !!state.user.organisation;
            let hasSetup = hasOrganisation && !!state.user.organisation.setup_completed_at;
            let hasFlightpath = hasUser && hasOrganisation && state.user.organisation.flight_path != null;

            console.log("hasUser",hasUser);
            console.log("hasOrganisation",hasOrganisation);
            console.log("hasSetup",hasSetup);
            console.log("hasFlightpath",hasFlightpath);

            return hasUser && hasOrganisation && (hasSetup || hasFlightpath);
        },
        isPaymentRequired: state => {
            return !!state.user && !!state.user.organisation && state.user.organisation.non_payment_at != null
        },
        isAdmin: state => !!state.user && state.user.admin === 1 && state.actingAdmin,
        isSupervisor: state => !!state.user && state.user.user_type === 'supervisor',
        canBeAdmin: state => !!state.user && state.user.admin === 1,
        isSuper: state => !!state.user && state.user.super === 1,
        isBilling: state => !!state.user && state.user.billing === 1,
        impersonatorIsSuper: state => !!state.impersonatorToken && !!state.impersonatorUser && state.impersonatorUser.super === 1,
        isImpersonating: state => !!state.impersonatorUser && !!state.impersonatorToken,
        isIpRestrictionEnabled: state => !state.user && (state.user.organisation.ip_restriction_enabled === 1 || state.user.organisation.flight_path_id == null)
    },
    mutations: {
        completeSteps: (state, steps) => {
            var finalSteps = [];
            _.each(state.currentSteps, (obj) => {
                if (steps.indexOf(obj.id) !== -1) {
                    obj.completed_at = moment().format("Y-m-d H:m:s")
                }
                finalSteps.push(obj);
            });
            state.tourSteps = finalSteps
        },
        updateCurrentSteps: (state, steps) => {
            state.currentSteps = steps
        },
        actingAdmin: (state, isAdmin) => {
            state.actingAdmin = isAdmin
        },
        updateUser: (state, auth) => {
            state.user = auth.user
        },
        login: (state, auth) => {
            state.token = auth.token
            state.user = auth.user
            if (auth.refreshToken !== undefined && auth.refreshToken != null) {
                state.refreshToken = auth.refreshToken
            }
        },
        logout: (state) => {
            state.mutedAnnouncements = [];
            state.actingAdmin = false;
            state.token = null;
            state.refreshToken = null;
            state.user = null;
            state.impersonatorToken = null;
            state.impersonatorUser = null;
            state.currentResult = null;
            state.application = {};
            state.lastPolledUser = 0;
            state.currentFlightPath = null;
            state.workspaceUserId = null;
            state.organisationId = null;
            if (router.currentRoute.meta.guards !== undefined && router.currentRoute.meta.guards.indexOf(GUARD_IS_AUTHENTICATED) !== -1) {
                router.replace({name: 'home'})
            }
        },
        updateToken: (state, auth) => {
            state.token = auth.token;
            if (auth.refreshToken !== undefined && auth.refreshToken != null && !state.isImpersonating) {
                state.refreshToken = auth.refreshToken
            } else {
                state.refreshToken = null;
            }
        },
        impersonate: (state, auth) => {
            state.impersonatorToken = state.token;
            state.impersonatorRefreshToken = state.refreshToken;
            state.impersonatorUser = state.user;
            state.token = auth.token;
            state.refreshToken = null;
            state.user = auth.user;
        },
        endImpersonation: (state) => {

            state.token = state.impersonatorToken;
            state.refreshToken = state.impersonatorRefreshToken;
            state.user = state.impersonatorUser;
            state.impersonatorToken = null;
            state.impersonatorRefreshToken = null;
            state.impersonatorUser = null;

        },
        updateApplication(state, application) {
            state.application = application;
        },
        updateResult(state, result) {
            state.currentResult = result;
        },
        setWorkspace(state, isWorkspace) {
            state.workspace = isWorkspace;
        },
        setWorkspaceUserId(state, workspaceUserId) {
            state.workspaceUserId = workspaceUserId;
        },
        setOrganisationId(state, organisationId) {
            state.organisationId = organisationId;
        },
        updateOrganisation(state, org) {
            if (state.user != null) {
                console.log("I'M UPDATING IT NOW");
                state.user.organisation = org;
            }
        },
        muteAnnouncement(state,announcementId) {
            var currentMuted = state.mutedAnnouncements;
            currentMuted.push({announcementId : announcementId, date : moment().toDate().getTime() });
            //
            //currentMuted = [];

            state.mutedAnnouncements = currentMuted;
        },
        setLastPolledUser(state) {
            state.lastPolledUser = new Date().getTime();
        }
    },
    actions: {
        muteAnnouncement: (context,announcement) => {
            return new Promise((resolve, reject) => {
                if(announcement.is_compulsory == 1) {
                    reject();
                } else {
                    try {
                        context.commit("muteAnnouncement", announcement.id);
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }
            });
        },
        endImpersonation: (context) => {
            return new Promise((resolve, reject) => {
                var impersonatingOrg = _.clone(context.state.user);
                router.replace({
                    path: router.currentRoute.path,
                    query: {session_organisation_id: context.state.impersonatorUser.organisation.id}
                }).then(() => {
                    context.commit("endImpersonation");
                    resolve(impersonatingOrg);
                }).catch((err) => {
                    context.commit("endImpersonation");
                    resolve(impersonatingOrg);
                });

            });
        }
        ,
        impersonate: (context, auth) => {
            return new Promise((resolve, reject) => {
                router.replace({
                    path: router.currentRoute.path,
                    query: {session_organisation_id: auth.user.organisation.id}
                }).then(() => {
                    context.commit("impersonate", auth);
                    resolve(context.state.user);
                }).catch((err) => {
                    context.commit("impersonate", auth);
                    resolve(context.state.user);
                });
            });
        },
        login: (context, auth) => {
            return new Promise((resolve, reject) => {
                context.commit("login", auth);
                resolve(auth);
            });
        },
        resetApplication(context) {
            context.commit("updateApplication", {});
            context.commit("updateResult", null);
        },
        setOrganisationId(context, organisationId) {
            context.commit("setOrganisationId", organisationId);
        },
        setWorkspaceUserId(context, workspaceUserId) {
            context.commit("setWorkspaceUserId", workspaceUserId);
        },
        updateApplication(context, application) {
            context.commit("updateApplication", application)
        },
        updateResult(context, result) {
            context.commit("updateResult", result)
        },
        refreshUser(context) {
            if (context.getters.currentToken == null) {
                console.log("NO TOKEN, polling stopped");
                //NEED TO STOP POLLING
                return;
            }
            let time = new Date().getTime();
            let secondsBetweenPolls = 20000;
            let secondsSinceLastPoll = (time - this.state.lastPolledUser);
            if (secondsSinceLastPoll < secondsBetweenPolls) {
                //ONLY POLL EVERY 20 SECONDS TO PREVENT SERVER OVERLOAD ACROSS MULTIPLE TABS
                return;
            }
            console.log("Polling now");
            context.commit('setLastPolledUser');
            this.dispatch('refreshUserCall');
        },
        refreshUserCall(context) {
            return new Promise((resolve, reject) => {
                var refreshToken = null;
                //GET THE TOKEN
                if (context.state.token == null) {
                    context.commit("logout");
                    reject(error);
                    return;
                }
                if (context.state.refreshToken != null) {
                    refreshToken = context.state.refreshToken;
                }
                getResourceWithRefresh(userCurrent, refreshToken).then((resp) => {
                    context.commit("updateUser", resp.data.success);
                    resolve(resp);
                    //SET THE ROUTER VARIABLE
                    if (resp.data.success.user.organisation != null) {
                        router.replace({
                            path: router.currentRoute.path,
                            query: {session_organisation_id: resp.data.success.user.organisation.id}
                        }).catch((err) => {
                            console.log("SAME ORG DON'T CHANGE");
                        });
                    } else {
                        if (router.currentRoute.name.indexOf("setup_") === -1 && router.currentRoute.meta.guards !== undefined && router.currentRoute.meta.guards.indexOf(GUARD_IS_AUTHENTICATED) !== -1) {
                            router.replace({
                                name: 'setup_welcome'
                            }).catch((err) => {
                            });
                        }
                    }


                }).catch((ex) => {
                    reject(ex);
                });
            });

        },
        updateOrganisation(context, org) {
            context.commit("updateOrganisation", org);
        }
    },
    modules: {},
    plugins: [createPersistedState()]
})
