<template>
    <div>
        <h5 class="border-bottom pb-2 text-primary">Update your notification settings</h5>
        <b-form-group>
            <b-form-radio-group
                    v-model="notificationsEnabled"
                    :options="notificationOptions"
                    name="radio-inline"
                    size="lg"
            ></b-form-radio-group>
        </b-form-group>

        <b-form-group class="mb-0">
            <b-button @click="saveSetting">
                <b-spinner variant="white" small type="grow" v-if="isLoading"></b-spinner>
                Save
            </b-button>
        </b-form-group>
    </div>
</template>

<script>

    import {saveResource} from "../../modules/api/methods";
    import {containsErrors} from "../../modules/helpers/helpers";
    import {userCurrentNotifications} from "../../modules/api/endpoints";

    export default {
        name: "NotificationSettings",
        components: {},
        props: {

        },
        data: function () {
            return {
                isLoading: false,
                errors : {},
                notificationsEnabled : null,
                notificationOptions: [
                    {text: 'I want to receive email notifications', value: 1},
                    {text: 'I do not want to receive email notifications', value: 0},
                ],
            }
        },
        created() {
            this.notificationsEnabled = this.$store.state.user.notifications_enabled;
        },
        methods: {
            saveSetting() {
                this.isLoading = true;

                var postData = {
                    notifications_enabled: this.notificationsEnabled,
                }

                saveResource(userCurrentNotifications, postData).then(resp => {
                    //UPDATE THE USER
                    this.$store.commit('updateUser', resp.data.success);
                    this.$emit('success');
                }).catch(error => {
                    if (containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error, this.saveSetting)
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>

<style scoped>

</style>
