<template>
    <div class="p-2">
        <div class="p-3" v-if="!linkInvalid">
            <surround-loader :is-loading="isLoading">
                <div v-if="!isLoading">

                    <div class="border bg-white rounded p-3">
                        <div v-if="saved" class="alert alert-success">
                            Account code has successfully been saved. You will no longer receive email notifications
                            prompting to update this organisation. You can still update this code if you have made an
                            error until this link expires.
                        </div>
                        <h5>Organisation details: </h5>
                        <div class="my-3">
                            <b-table-simple striped bordered responsive>
                                <b-tr>
                                    <b-th>Company name:</b-th>
                                    <b-td>{{ organisation.name }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Address line 1:</b-th>
                                    <b-td>{{ organisation.address_line_1 }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Address line 2:</b-th>
                                    <b-td>{{ organisation.address_line_2 }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Town:</b-th>
                                    <b-td>{{ organisation.town }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Postcode:</b-th>
                                    <b-td>{{ organisation.post_code }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Key contact email:</b-th>
                                    <b-td>{{ organisation.notification_email }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Key contact telephone:</b-th>
                                    <b-td>{{ organisation.phone_number }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Accounts contact name:</b-th>
                                    <b-td>{{ organisation.accounts_name }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Accounts contact email:</b-th>
                                    <b-td>{{ organisation.accounts_email }}</b-td>
                                </b-tr>
                            </b-table-simple>

                        </div>
                        <b-form-group
                                label-size="lg"
                                label="Enter account code here:">
                            <b-form-input type="text" size="lg" v-model="organisation.dimensions_id"
                                          placeholder="Account code"></b-form-input>
                            <error-display ident="account_code" v-model="errors"></error-display>
                        </b-form-group>

                        <error-display ident="global" v-model="errors"></error-display>

                        <div class="d-flex justify-content-end">
                            <b-button class="flex-wrap" variant="success" @click="saveModel">Save
                                account code
                            </b-button>
                        </div>
                    </div>
                </div>
            </surround-loader>
        </div>
        <div class="p-3" v-else>
            <div class="alert alert-danger">
                The link is invalid or has now expired
            </div>
            <error-display ident="account_code" v-model="errors"></error-display>
        </div>

    </div>
</template>

<script>
    import ErrorDisplay from "../../../../components/form_inputs/ErrorDisplay";
    import SurroundLoader from "../../../../components/SurroundLoader";
    import {getResource, saveResource} from "../../../../modules/api/methods";
    import {accountCode} from "../../../../modules/api/endpoints";
    import {containsErrors} from "../../../../modules/helpers/helpers";
    import _ from 'lodash';

    export default {
        name: "organisation_account_code",
        components: {SurroundLoader, ErrorDisplay},
        props: {
            token: null,
        },
        data: function () {
            return {
                isLoading: false,
                linkInvalid: false,
                saved: false,
                errors: {},
                organisation: {}
            }
        },
        created() {
            if (this.token != null) {
                this.loadModel();
            } else {
                this.linkInvalid = true;
            }

        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(accountCode(this.token)).then((resp) => {
                    console.log(resp);
                    this.organisation = resp.data.success.data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    if (containsErrors(ex)) {
                        this.linkInvalid = true;
                        this.errors = ex.data.errors;
                    }
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(accountCode(this.token), {account_code: this.organisation.dimensions_id}).then((resp) => {
                    this.organisation = resp.data.success.data;
                    this.isLoading = false;
                    this.saved = true;
                }).catch((ex) => {
                    console.log(ex);
                    if (containsErrors(ex)) {
                        this.errors = ex.data.errors;
                    }
                    this.isLoading = false;
                });
            },
        }

    }
</script>
