<template>
    <div>
    <b-spinner v-if="isLoading" variant="primary" size="sm" type="grow" label="Spinning" class="mr-2"></b-spinner>
    <b-dropdown size="sm" :id="item.status === 'in_progress' ? 'quick-actions-progress' : 'quick-actions'" class="mr-2" right text="Quick actions" variant="outline-dark" >
        <b-dropdown-item @click="startApp(item.id)"
                         v-if="item.archived_at == null && !isExcluded('web_app') && item.on_hold === 0 && item.status === 'in_progress'" href="#">
            Complete in web app
        </b-dropdown-item>
        <b-dropdown-divider v-if="item.archived_at == null && !isExcluded('pdf') && item.status === 'in_progress'"></b-dropdown-divider>
        <b-dropdown-item v-if="!isExcluded('pdf') && isComplete" @click="downloadResource('pdf',item.id)">Download PDF
        </b-dropdown-item>
        <b-dropdown-item v-if="!isExcluded('json') && isComplete" @click="downloadResource('json',item.id)">Download
            JSON Export
        </b-dropdown-item>
        <b-dropdown-item v-if="item.archived_at == null && !isExcluded('edit')" @click="quickEdit(item.id)" href="#">Quick edit</b-dropdown-item>
        <b-dropdown-item v-if="item.archived_at == null && !isExcluded('hold') && item.on_hold === 0" @click="onHold(item.id,1)" href="#">Put on
            hold
        </b-dropdown-item>
        <b-dropdown-item v-if="item.archived_at == null && !isExcluded('hold') && item.on_hold === 1" @click="onHold(item.id,0)" href="#">Take off
            hold
        </b-dropdown-item>
        <b-dropdown-divider v-if="item.archived_at == null && !isExcluded('users') && (this.$store.getters.isAdmin || this.$store.getters.isSupervisor)"></b-dropdown-divider>
        <b-dropdown-item v-if="item.archived_at == null && !isExcluded('users') && (this.$store.getters.isAdmin || this.$store.getters.isSupervisor)" @click="usersEdit(item.id)"
                         href="#">Edit application users
        </b-dropdown-item>
        <b-dropdown-item v-if="!isExcluded('unarchive') && item.archived_at != null" @click="unarchiveApp(item.id)" href="#">
            Unarchive application
        </b-dropdown-item>
        <b-dropdown-item v-if="!isExcluded('cancelArchive') && item.archived_at == null && item.auto_archive_at != null" @click="cancelArchive(item.id)" href="#">
            Cancel auto archive
        </b-dropdown-item>
        <b-dropdown-item v-if="!isExcluded('delete') && item.archived_at != null" @click="deleteApp(item.id)" href="#">
            Delete application
        </b-dropdown-item>
    </b-dropdown>
    </div>
</template>
<script>

    import {statusIsComplete} from "../../modules/helpers/helpers";
    import {deleteResource, getResource} from "../../modules/api/methods";
    import {
        applicationCancelArchive,
        applicationDetail,
        applicationJson,
        applicationOnHold,
        applicationPdf, applicationUnarchive,
        userSingleUse
    } from "../../modules/api/endpoints";
    import _ from 'lodash';

    export default {
        name: "ApplicationActionDropdown",
        props: {
            item: {},
            value: null,
            excludeItems: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data: function () {
            return {
                isLoading: this.value,
            }
        },
        watch: {
            isLoading: function (newVal) {
                console.log("LOADING STATUS", newVal ? "LOADING" : "FINISHED LOADING");
                this.$emit("input", newVal);
            }
        },
        computed: {
            isComplete: function () {
                return statusIsComplete(this.item.status)
            }
        },
        methods: {
            isExcluded(listItem) {
                return _.indexOf(this.excludeItems, listItem) !== -1
            },
            deleteApp(appId) {
                this.isLoading = true;
                deleteResource(applicationDetail(appId)).then((resp) => {
                    this.isLoading = false;
                    this.$root.$children[0].showToastMessage("Application deleted","The application has been deleted","success");
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                }).finally(() => {
                    this.$emit('delete');
                });
            },
            unarchiveApp(appId) {
                this.isLoading = true;
                getResource(applicationUnarchive(appId)).then((resp) => {
                    this.isLoading = false;
                    this.$root.$children[0].showToastMessage("Application unarchived","The application has been unarchived","success");
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                }).finally(() => {
                    this.$emit('delete');
                });
            },
            cancelArchive(appId) {
                this.isLoading = true;
                getResource(applicationCancelArchive(appId)).then((resp) => {
                    this.isLoading = false;
                    this.$root.$children[0].showToastMessage("Auto archive cancelled","The application will no longer be auto archived","success");
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                }).finally(() => {
                    this.$emit('delete');
                });
            },
            onHold(appId) {
                this.isLoading = true;
                getResource(applicationOnHold(appId, this.item.on_hold === 0 ? 1 : 0)).then((resp) => {
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                }).finally(() => {
                    this.isLoading = false;
                    this.$emit('delete');
                });
            },
            usersEdit(appId) {
                if(this.$router.currentRoute.name === 'applications') {
                    this.$router.push({name: 'applicationUsersEdit', params: {applicationId: appId}}).catch(error => {})
                } else {
                    this.$router.push({name: 'applicationDetailUsersEdit', params: {applicationId: appId}}).catch(error => {})
                }
            },
            quickEdit(appId) {
                if(this.$router.currentRoute.name === 'applications') {
                    this.$router.push({name: 'applicationEdit', params: {applicationId: appId}}).catch(error => {})
                } else {
                    this.$router.push({name: 'applicationDetailEdit', params: {applicationId: appId}}).catch(error => {})
                }
            },
            startApp(appId) {
                let routeData = this.$router.resolve({name: 'web_app_start_app', params:
                        {applicationId: appId}, query : {session_organisation_id : this.$router.currentRoute.query.session_organisation_id}});
                window.open(routeData.href);
            },
            downloadResource(type, id) {
                this.isLoading = true;
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {
                    if (type === 'pdf') {
                        window.open(applicationPdf(id, resp.data.success.token, '_blank'));
                    } else if (type === 'json') {
                        this.$bvModal.msgBoxConfirm('This export can satisfy the GDPR Data Portability request from your applicant.\n' +
                            '\n' +
                            'Please ensure this export is provided to the applicant in a secure manner.', {'title': 'Download JSON export'})
                            .then(value => {
                                if (value) {
                                    window.open(applicationJson(id, resp.data.success.token, '_blank'));
                                }
                            })
                            .catch(err => {

                            });
                    }

                }).catch((ex) => {
                    console.log(ex);
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }

    }
</script>

<style scoped>

</style>
