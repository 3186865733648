<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-4">
                <div class="d-flex mb-3 align-items-center">
                    <img class="flex-wrap" src="../../assets/logo.png" style="width: 220px"/>
                </div>

                <b-button @click="onSignIn" :disabled="this.isLoading" block
                          class="mt-3 text-left d-flex align-items-center"
                          variant="outline-primary">
                    <b-spinner v-if="isLoading" small variant="dark" class="mr-2"></b-spinner>
                    <span>Sign in</span>
                </b-button>
                <error-display v-model="errors" ident="sso"></error-display>


                <b-button @click="onRegister" :disabled="this.isLoading" block
                          class="mt-3 text-left d-flex align-items-center"
                          variant="outline-secondary">
                    <span>Register new organisation</span>
                </b-button>

            </div>
        </div>
    </div>
</template>

<script>
    import {containsErrors, isEmail} from "../../modules/helpers/helpers";
    import {ssoCheck, login, getResource} from "../../modules/api/methods";
    import {ssoAuthorize, ssoAuthorizeHint} from "../../modules/api/endpoints";
    import ErrorDisplay from "../../components/form_inputs/ErrorDisplay";

    export default {
        name: 'login',
        data: function () {
            return {
                isLoading: false,
                errors: {},
            }
        },
        created() {
            if (this.$route.query.sso_error !== undefined) {
                this.errors = {sso: [this.$route.query.sso_error]}
            }
        },
        components: {
            ErrorDisplay
        },
        methods: {
            onSignIn: function () {
                this.isLoading = true;
                window.location = ssoAuthorizeHint("");
            },
            onRegister: function () {
                this.$router.push({name : 'register'}).catch(error => {})
            },
        },
    }
</script>
