<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-1">{{ item.name }}</h5>
                <div v-if="item.deleted_at == null" >
                    <b-badge class="mb-1 mr-1" :variant="item.workspace_id == null ? 'light' : 'info'">{{
                        item.workspace_id == null ? 'Non-workspace' : 'Workspace' }}
                    </b-badge>

                    <b-badge  v-b-tooltip.hover :title="dateToNice(item.setup_completed_at)" v-if="item.setup_completed_at != null"  class="mb-1 mr-1" variant="success">Launched via self service ({{ item.setup_completed_via != null ? item.setup_completed_via : "Unknown route" }})
                    </b-badge>
                </div>
                <div v-if="item.flight_path != null">
                    <b-badge :variant="item.flight_path_step.name == 'Launched' ? 'success' : 'danger'">{{ item.flight_path.name }} : {{ item.flight_path_step.name }}</b-badge>
                </div>

            </div>
            <div  class="col">
                <div style="font-size: 12px;" :class="expiryColor">
                    <strong>{{ expiry }}</strong>
                </div>
                <div v-if="item.deleted_at != null">
                    <b-badge variant="secondary">Archived on {{ archivedDate }}</b-badge>
                </div>
                <div v-if="item.deleted_at == null" >
                    <b-badge variant="success">{{ item.users_count }} users</b-badge>
                </div>

                <div v-if="item.deleted_at == null"  class="mt-1">
                    <strong :class="item.total_spend != null ? 'text-secondary' : 'text-warning'">{{ item.total_spend != null ? "£"+item.total_spend+ " revenue" : "No revenue" }}</strong>
                </div>
            </div>
            <div v-if="item.deleted_at == null" class="col-2 text-right">
                <b-dropdown   size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item
                            @click="(ev) => { ev.preventDefault(); $router.push({ name : 'organisationEdit', params : { id : this.item.id }}).catch(error => {}) }"
                            href="#">Edit organisation
                    </b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); archiveModel(item.id); }" href="#">
                        Archive organisation
                    </b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); $router.push({ name : 'organisationUserAdd', params : { organisationId : item.id, organisationName : item.name }}).catch(error => {}); }" href="#">
                        Create organisation user
                    </b-dropdown-item>
                    <b-dropdown-item @click="organisationUsers" href="#">
                        View organisation users
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div class="border p-3 rounded mt-2" v-if="item.setup_completed_at == null && item.flight_path == null">
            <h6 class="mb-1 font-weight-bold">Setup status:</h6>
            <b-badge v-b-tooltip.hover :title="dateToNice(item.created_at)" class="mb-1 mr-1" variant="success">
                <b-icon-check2 class="mr-1"></b-icon-check2>Opened product
            </b-badge>
            <b-badge v-b-tooltip.hover :title="dateToNice(item.updated_at)" class="mb-1 mr-1" :variant="item.address_line_1 != null ? 'success' : 'light'">
                <b-icon :icon="item.address_line_1 != null ? 'check2' : 'x'" class="mr-1"></b-icon>Entered address
            </b-badge>

            <b-badge v-b-tooltip.hover :title="item.setup_system_at != null ? dateToNice(item.setup_system_at) : 'Not completed yet'" class="mb-1 mr-1" :variant="item.setup_system_at != null ? 'success' : 'light'">
                <b-icon :icon="item.setup_system_at != null ? 'check2' : 'x'" class="mr-1"></b-icon>System setup
            </b-badge>

            <b-badge v-b-tooltip.hover :title="item.setup_users_at != null ? dateToNice(item.setup_users_at) : 'Not completed yet'" class="mb-1 mr-1" :variant="item.setup_users_at != null ? 'success' : 'light'">
                <b-icon :icon="item.setup_users_at != null ? 'check2' : 'x'" class="mr-1"></b-icon>User setup
            </b-badge>

            <b-badge v-b-tooltip.hover :title="item.setup_contract_at != null ? dateToNice(item.setup_contract_at) : 'Not completed yet'" class="mb-1 mr-1" :variant="item.setup_contract_at != null ? 'success' : 'light'">
                <b-icon :icon="item.setup_contract_at != null ? 'check2' : 'x'" class="mr-1"></b-icon>Contract
            </b-badge>

            <b-badge v-b-tooltip.hover :title="item.setup_payment_at != null ? dateToNice(item.setup_payment_at) : 'Not completed yet'" class="mb-1 mr-1" :variant="item.setup_payment_at != null ? 'success' : 'light'">
                <b-icon :icon="item.setup_payment_at != null ? 'check2' : 'x'" class="mr-1"></b-icon>Payment
            </b-badge>
        </div>
    </b-list-group-item>
</template>
<script>

    import moment from "moment"
    import {getResource} from "../../modules/api/methods";
    import {organisationArchive} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";

    export default {
        name: "OrganisationCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        computed: {
            archivedDate() {
                return moment(this.item.deleted_at).format("DD/MM/YYYY");
            },
            expiry() {
                let exp = moment(this.item.expires_at);
                if (exp.isBefore(moment())) {
                    return "License expired on " + exp.format("DD/MM/YYYY");
                } else if (exp.isBefore(moment().add("month", 1))) {
                    return "License will soon expire on " + exp.format("DD/MM/YYYY");
                } else if(exp.format("DD/MM/YYYY") !== "Invalid date") {
                    return "License expiry on " + exp.format("DD/MM/YYYY");
                }
            },
            expiryColor() {
                let exp = moment(this.item.expires_at);
                if (exp.isBefore(moment())) {
                    return "text-danger";
                } else if (exp.isBefore(moment().add("month", 1))) {
                    return "text-warning";
                } else {
                    return "text-success";
                }
            }
        },
        methods: {
            organisationUsers() {
                var url = "/dashboard/admin/users-admin?filters="+encodeURI(btoa(JSON.stringify({organisation_id : this.item.id})));
                this.$router.push(url);
            },
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY HH:mm");
            },
            archiveModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?. All users within this organisation will no longer be able to login.')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            getResource(organisationArchive(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>

