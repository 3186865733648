<template>
    <loader-panel :isLoading="isLoading">
        <div v-if="application != null">
            <div class="row align-items-center">
                <div class="col-2">
                    <image-view class="img-thumbnail" :src="application.candidate_image_upload_id"
                           ></image-view>
                </div>

                <div class="col-10">
                    <h2 class="text-dark mt-3">{{ application.first_name }} {{
                        application.middle_names }} {{ application.surname }} </h2>
                    <h3>
                        <div class="d-flex">
                            <div class="mr-2 flex-wrap" v-if="decisionValue === 'pass'">
                                <b-icon-check variant="success"></b-icon-check>
                            </div>

                            <div class="mr-2 flex-wrap" v-if="decisionValue === 'fail'">
                                <b-icon-x variant="danger"></b-icon-x>
                            </div>

                            <div class="flex-fill">
                                <p :class="decisionValue === 'fail' ? 'text-danger font-weight-bold' : 'text-success font-weight-bold' ">
                                    {{ decisionValue === 'pass' ? "Has the right to work within the UK" : "Does not have the right to work within the UK"}}</p>
                            </div>
                        </div>
                    </h3>
                </div>
            </div>

            <div v-if="decisionValue === 'pass'" class="mt-3">

                <b-form-group
                        label="If you have any concerns with the applicants information or documentation, please highlight them here:">
                    <b-form-textarea
                            v-model="notes"
                            type="text"
                            placeholder="..."
                    ></b-form-textarea>
                    <error-display v-model="errors" ident="notes"></error-display>
                </b-form-group>

                <div class="d-flex justify-content-end mt-3">

                    <a class="btn btn-danger ml-2" href="#" v-on:click="sendSupervisor">
                        Send to supervisor
                    </a>

                    <a v-if="canApprove()" class="btn btn-success ml-2" href="#" v-on:click="approve">
                        Approve application
                    </a>

                </div>

            </div>

            <div v-if="decisionValue === 'fail'" class="mt-3">

                <b-form-group
                        label="Any additional notes you want to make about this rejection:">
                    <b-form-textarea
                            v-model="notes"
                            type="text"
                            placeholder="..."
                    ></b-form-textarea>
                    <error-display v-model="errors" ident="notes"></error-display>
                </b-form-group>

                <div class="d-flex justify-content-end mt-3">

                    <a class="btn btn-danger ml-2" href="#" v-on:click="reject">
                        Reject and record application
                    </a>

                </div>

            </div>

        </div>

    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import ValidationInput from "../ValidationInput";
    import {saveResource, saveResourceWithHeaders} from "../../../modules/api/methods";
    import ImageView from "../ImageView";
    import ErrorDisplay from "../../form_inputs/ErrorDisplay";
    import {applicationStatus} from "../../../modules/api/endpoints";
    import {containsErrors} from "../../../modules/helpers/helpers";

    export default {
        name: 'web_app_decision',
        components: {
            ErrorDisplay,
            'image-view' : ImageView,
            ValidationInput,
            "loader-panel": LoaderPanel,
        },
        data: function () {
            return {
                notes: null,
                application: null,
                flaggingMode: null,
                isLoading: false,
                errors: {},
                status: 'in_progress',
                decisionValue: null
            }
        },
        computed: {},
        created() {
            //GET THE DECISION VALUE FROM THE LOCAL STORE
            this.decisionValue = this.$store.state.currentResult;
            this.application = this.$store.state.application;
        },
        methods: {
            canApprove() {
                if(this.$store.getters.isSupervisor) {
                    return true;
                }
                return this.$store.state.user.organisation.force_flagging !== 1;
            },
            sendSupervisor(ev) {
                ev.preventDefault();
                this.status = 'flagged';
                this.saveForm()
            },
            approve(ev) {
                ev.preventDefault();
                this.status = 'approved';
                this.saveForm()

            },
            reject(ev) {
                ev.preventDefault();
                this.status = 'rejected';
                this.saveForm()
            },
            saveForm() {
                this.isLoading = true;

                var postData = {
                    status: this.status,
                    notes: this.notes
                }
                saveResource(applicationStatus(this.application.id), postData).then(response => {
                    this.$router.push({name: 'web_app_application_list'}).catch(error => {})
                }).catch(error => {
                    if(containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error, this.saveForm)
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>
