<template>
    <b-form-group
            :class="errors !== undefined && errors.length > 0 ? 'is-invalid' : ''"
            :label="label">
        <slot>

        </slot>
        <div v-if="errors !== undefined && errors.length > 0" class="d-block invalid-feedback">
            <p v-for="(error, index) in errors" v-bind:key="index">{{ error }}</p>


        </div>

    </b-form-group>
</template>

<script>
    export default {
        name: "ValidationInput",
        props: {
            errors: [],
            label : null,
        },
    }
</script>

<style scoped>

</style>