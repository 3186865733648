<template>
    <loader-panel :isLoading="isLoading">



                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="mr-3 flex-wrap" v-if="photoTaken()">
                            <i class="fa fa-check text-success"></i>
                        </div>
                        <div class="flex-fill font-weight-bold">
                            <i class="fa fa-user-photo"></i> Capture or upload a clear candidate photo {{ photoRequired()
                            === true ? "(required)" : "(optional)" }}:
                        </div>
                        <image-uploader v-model="$store.state.application.candidate_image_upload_id"></image-uploader>

                    </div>
                </div>



            <div  v-if="photoTaken() || !photoRequired()"
                           class="d-block d-flex justify-content-end align-items-center mt-3">
                <b-button class="flex-wrap" variant="success" v-on:click="saveForm">
                    {{ !photoTaken() && !photoRequired() ? "Skip" : "Save and continue" }}
                </b-button>
            </div>




    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import ImageUploader from "../ImageUploader";
    import {saveResource} from "../../../modules/api/methods";
    import {applicationPhoto} from "../../../modules/api/endpoints";
    import {isGuid} from "../../../modules/helpers/helpers";

    export default {
        name: 'photo',
        data: function () {
            return {
                isLoading: false,
                application: null,
            }
        },
        components: {ImageUploader, LoaderPanel},
        created() {
            this.application = this.$store.state.application;
        },
        methods: {
            photo() {
                return this.$store.state.application.candidate_image_upload_id
            },
            photoTaken() {
                return this.$store.state.application.candidate_image_upload_id != null && isGuid(this.$store.state.application.candidate_image_upload_id);
            },
            photoRequired() {
                return this.$store.state.user.organisation.is_photo_required === 1;
            },
            saveForm(ev) {
                ev.preventDefault();

                if (!this.photoRequired() && !this.photoTaken()) {
                    //SKIP IT
                    this.$router.push({name: 'web_app_questions'}).catch(error => {});
                    return;
                }

                this.isLoading = true;
                var postData = {};
                postData.photo = this.photo();

                saveResource(applicationPhoto(this.application.id), postData).then(response => {
                    this.$store.dispatch('updateApplication', response.data.success.data).then(() => {
                        this.$router.push({name: 'web_app_questions'}).catch(error => {})
                    });
                }).catch(error => {
                    console.log(error);
                    this.$root.$children[0].handleApiError(error,this.saveForm)
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>
