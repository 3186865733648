<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-0">{{ item.name }}</h5>
                <div>
                    <b-badge v-if="item.input_type === 'free_entry'" variant="success">Free entry</b-badge>
                    <b-badge v-else-if="item.input_type === 'list'" variant="info">List with {{ item.tags_count }}
                        options
                    </b-badge>
                    <b-badge v-else-if="item.input_type === 'autocomplete'" variant="primary">Autocomplete with {{
                        item.tags_count }} suggestions
                    </b-badge>
                </div>
            </div>
            <div class="col text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2" id="tag-actions">
                    <b-dropdown-item
                            @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'tagTypeEdit', params : { id : this.item.id }}).catch(error => {}) }"
                            href="#">Edit tag type
                    </b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.deleteModel(item.id);}" href="#">Delete
                        tag type
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource} from "../../modules/api/methods";
    import {tagTypeDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";

    export default {
        name: "TagTypeCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            roleVariant: function (role) {
                return roleToVariant(role)
            },
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(tagTypeDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
