<template>
    <b-list-group-item class="user-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-1">{{ item.device_name }}</h5>
                <div>
                    <b-badge class="mb-1 mr-1" v-if="item.approved_at == null" variant="danger">Awaiting approval</b-badge>
                    <b-badge class="mb-1 mr-1" v-if="item.approved_at != null" variant="success">Approved on {{ approvalDate }}</b-badge>
                    <b-badge class="mb-1 mr-1" v-if="item.push_token != null" variant="info">Notifications</b-badge>
                </div>
            </div>
            <div class="flex-wrap text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item v-if="item.approved_at == null" @click="(ev) => { ev.preventDefault(); approveDevice(item.id); }" href="#">Approve device</b-dropdown-item>
                    <b-dropdown-item v-if="item.approved_at != null" @click="(ev) => { ev.preventDefault(); unapproveDevice(item.id); }" href="#">Unapprove device</b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); deleteDevice(item.id); }" href="#">Delete device</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource, saveResource} from "../../modules/api/methods";
    import {userDetail, userDeviceDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";
    import moment from "moment"

    export default {
        name: "UserDeviceCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods : {
            roleVariant : function(role) {
                return roleToVariant(role)
            },
            deleteDevice: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure? User will no longer be able to access the app with this device.')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(userDeviceDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            approveDevice : function(id) {
                this.updateDevice(id,{approved : 1})
            },
            unapproveDevice : function(id) {
                this.updateDevice(id,{approved : 0})
            },
            updateDevice: function (id,params) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            saveResource(userDeviceDetail(id),params).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        },
        computed : {
            approvalDate : function() {
                return moment(this.item.approved_at).format("DD/MM/YYYY");
            }
        }

    }
</script>

<style scoped>

</style>
