<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <frontend-layout>
        <template v-slot:main>
            <div class="p-4">
                <product-features></product-features>

                <nav v-if="!$store.getters.isAuthenticated"
                     class="d-flex justify-content-center align-items-center mb-3 mt-4">

                    <b-button variant="primary" class="px-5 mx-2" @click="onSignIn">
                        <b-icon-lock></b-icon-lock>
                        Sign in
                    </b-button>
                    <b-button variant="success" class="px-5 mx-2"
                              :to="{name : 'register'}">
                        <b-icon-pencil></b-icon-pencil>
                        Register
                    </b-button>
                </nav>

                <p class="mt-4 text-center">Simply sign in if an existing Access Workspace customer or Register to get set up with
                    our Right to Work Mobile App.</p>
            </div>

            <div v-if="!$store.getters.isAuthenticated" class="p-4 bg-white">
                <div class="container mb-4">
                    <h2 class="text-center mb-4">Clear and simple pricing</h2>
                    <pricing-display></pricing-display>
                </div>

            </div>

        </template>
    </frontend-layout>
</template>

<style scoped>
    .fitVids-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
    }

    .fitVids-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>

<script>

    import FrontendLayout from "../components/layouts/FrontendLayout";
    import ProductFeatures from "../components/marketing_content/ProductFeatures";
    import {ssoAuthorizeHint} from "../modules/api/endpoints";
    import PricingDisplay from "../components/setup/PricingDisplay";

    export default {
        name: 'home',
        components: {
            PricingDisplay,
            ProductFeatures,
            FrontendLayout

        },
        methods: {
            onSignIn: function () {
                this.isLoading = true;
                window.location = ssoAuthorizeHint("");
            },
        },
        mounted() {
            var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

            // Select videos
            var fitVids = document.querySelectorAll(players.join(","));

            // If there are videos on the page...
            if (fitVids.length) {
                // Loop through videos
                for (var i = 0; i < fitVids.length; i++) {
                    // Get Video Information
                    var fitVid = fitVids[i];
                    var width = fitVid.getAttribute("width");
                    var height = fitVid.getAttribute("height");
                    var aspectRatio = height / width;
                    var parentDiv = fitVid.parentNode;

                    // Wrap it in a DIV
                    var div = document.createElement("div");
                    div.className = "fitVids-wrapper";
                    div.style.paddingBottom = aspectRatio * 100 + "%";
                    parentDiv.insertBefore(div, fitVid);
                    fitVid.remove();
                    div.appendChild(fitVid);

                    // Clear height/width from fitVid
                    fitVid.removeAttribute("height");
                    fitVid.removeAttribute("width");
                }
            }
        }
    }
</script>
