<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-6">
                <div class="flex-wrap rounded p-4 d-block bg-white">
                    <b-spinner v-if="isLoading" small variant="dark" class="mr-2"></b-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getResource} from "../../modules/api/methods";
    import {userCurrent} from "../../modules/api/endpoints";
    import axios from "axios";
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: 'LoginSuccess',
        data: function () {
            return {
                isLoading: true,
            }
        },
        components: {},
        methods: {},
        mounted() {
            this.isLoading = true;

            //AUTO LOGOUT FROM HERE
            this.$store.commit('logout');

            let accessToken = this.$cookie.get('sso_access_token');
            let refreshToken = this.$cookie.get('sso_refresh_token');

            //GET USER AGENT FOR SSO VIA MOBILE

            let ua = this.$browserDetect.meta.ua;

            console.log("Access", accessToken);
            console.log("RefreshToken", refreshToken);

            let workspaceId = localStorage.getItem('workspaceUserId');
            console.log("WORKSPACE IS ", workspaceId);

            if (accessToken !== null) {
                let jsonConfig = {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                        Accept: "application/json",
                        WorkspaceUserId: workspaceId
                    }
                };

                axios.get(userCurrent, jsonConfig)
                    .then(resp => {
                        localStorage.removeItem('workspaceUserId');
                        var errorResponse = resp.data !== undefined ? resp.data : ((resp.response !== undefined && resp.response.data !== undefined) ? resp.response.data : null);
                        console.log("ERROR RESPONSE IS ", errorResponse);
                        if (errorResponse != null && errorResponse.user !== undefined) {
                            console.log("LOGGING IN HERE");
                            this.$store.dispatch('login', {
                                user: errorResponse.user,
                                token: accessToken,
                                refreshToken: refreshToken
                            }).then(() => {
                                this.$router.replace({'name': 'workspaceSelect'}).then(() => {}).catch((err) => {});;
                            });
                            return;
                        }

                        if (containsErrors(resp.response)) {
                            console.log("CONTAINS ERRORS IN REPSONSE");
                            this.$router.replace({'name': 'home'}).then(() => {
                                this.$root.$children[0].showToastMessage("Ooops", resp.response.data.errors.global.join("\n"), "danger");
                            }).catch((err) => {});
                            return;
                        } else if (containsErrors(resp)) {
                            console.log("CONTAINS ERRORS IN ROOT");
                            this.$router.replace({'name': 'home'}).then(() => {
                                this.$root.$children[0].showToastMessage("Ooops", resp.data.errors.global.join("\n"), "danger");
                            }).catch((err) => {});
                            return;
                        }

                        if (resp.data.success !== undefined) {
                            console.log("IM IN HERE", resp.data.success.user);
                            if (ua !== "identity_sso_mobile") {
                                this.$store.dispatch('login', {
                                    user: resp.data.success.user,
                                    token: accessToken,
                                    refreshToken: refreshToken
                                }).then(() => {
                                    //CHECK FOR MULTIPLE ORGS
                                    if (!this.$store.getters.isWorkspace && this.$store.state.user.organisation_users != null && this.$store.state.user.organisation_users.length > 1) {
                                        this.$router.replace({'name': 'workspaceSelect'}).catch((err) => {});
                                    } else {
                                        if (resp.data.success.user.organisation != null) {
                                            //SET THE INITIAL ORGANISATION ID
                                            //COULD STILL BE IN SETUP SO FORWARD TO THERE IF SO
                                            if (this.$store.getters.isOrgSetup) {
                                                if (!this.$store.getters.isWorkspace) {
                                                    this.$root.$children[0].showToastMessage("Login success", "Your login was successful", "success");
                                                }
                                                this.$router.replace({
                                                    'path': '/dashboard',
                                                    query: {session_organisation_id: resp.data.success.user.organisation.id}
                                                }).catch((err) => {});
                                            } else {
                                                this.$root.$children[0].showToastMessage("Welcome to Access Right to Work", "Please complete setup to start using Access Right to Work", "success");

                                                this.$router.replace({
                                                    'path': '/setup/welcome',
                                                    query: {session_organisation_id: resp.data.success.user.organisation.id}
                                                }).catch((err) => {});
                                            }
                                        } else {
                                            //THEY NEED SETTING UP
                                            this.$router.replace({
                                                name: 'setup_welcome'
                                            }).catch((err) => {

                                            });
                                        }
                                    }
                                }).catch((EX) => {
                                    console.log("EXCEPTION IS OF LOGIN", EX);
                                    this.$store.commit('logout');
                                    this.$router.replace({'name': 'home'}).then(() => {
                                        this.$root.$children[0].showToastMessage("Ooops", "Unable to login with Access, please try again", "danger");
                                    }).catch((err) => {});
                                });
                            }
                        } else {
                            if (ua !== "identity_sso_mobile") {
                                this.$store.commit('logout');
                                this.$router.replace({'name': 'home'}).then(() => {
                                    this.$root.$children[0].showToastMessage("Ooops", "Unable to login with Access, please try again", "danger");
                                }).catch((err) => {});
                            }
                        }
                    })
                    .catch((err) => {

                        if (err.response.data !== undefined && err.response.data.migration_link !== undefined) {
                            window.location = err.response.data.migration_link;
                            return err;
                        } else if (err.data !== undefined && err.data.migration_link !== undefined) {
                            window.location = err.data.migration_link;
                            return err;
                        }

                        var errorResponse = err.data !== undefined ? err.data : ((err.response !== undefined && err.response.data !== undefined) ? err.response.data : null);
                        console.log("ERROR RESPONSE IS ", errorResponse);
                        if (errorResponse != null && errorResponse.user !== undefined) {
                            this.$store.dispatch('login', {
                                user: errorResponse.user,
                                token: accessToken,
                                refreshToken: refreshToken
                            }).then(() => {
                                this.$router.replace({'name': 'workspaceSelect'}).then(() => {
                                    this.$bvToast.toast('Please select a workspace organisation to proceed with', {
                                        title: 'Select workspace',
                                        variant: 'danger',
                                        solid: false
                                    })
                                }).catch((err) => {});
                            });
                            return;
                        }
                        if (containsErrors(err.response)) {
                            this.$root.$children[0].showToastMessage("Ooops", err.response.data.errors.global.join("\n"), "danger");
                        } else if (containsErrors(err)) {
                            this.$root.$children[0].showToastMessage("Ooops", err.data.errors.global.join("\n"), "danger");
                        } else if (ua !== "identity_sso_mobile") {
                            console.log("ERROR", err);
                            this.$store.commit('logout');
                            this.$router.replace({'name': 'home'}).then(() => {
                                this.$root.$children[0].showToastMessage("Ooops", "Unable to login with Access, please try again", "danger");
                            }).catch((err) => {});
                        }

                    }).finally(() => {
                    if (ua !== "identity_sso_mobile") {
                        this.isLoading = false;
                    }
                    //AFTER A LOGIN LETS SET THE WORKSPACE ID BACK TO NULL TO ALLOW TO LOGIN TO MULTIPLE
                    localStorage.removeItem('workspaceUserId');
                });
            } else {
                this.$store.commit('logout');
                this.$router.replace({'name': 'home'}).then(() => {
                    this.$root.$children[0].showToastMessage("Ooops", "Unable to login with Access, please try again", "danger");
                }).catch((err) => {});
            }


        }
    }
</script>
