<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-2">{{ item.title }}</h5>
                <div class="text-muted" style="font-size: 14px;">
                    {{ strippedContent() | truncate(300,'...') }}
                </div>
                <div class="font-weight-bold mt-1">
                    <span v-if="item.is_compulsory == 1"
                          class="badge badge-danger mr-1">Compulsory</span>
                    <span v-if="item.is_live == 1"
                          class="badge badge-success mr-1">This announcement has been published</span>
                    <span v-if="item.is_live != 1"
                          class="badge badge-warning mr-1">This announcement is still in draft</span>

                    <span v-if="item.is_workspace == 1" class="badge badge-info mr-1">Workspace</span>
                    <span v-if="item.is_web == 1" class="badge badge-primary mr-1">Web</span>
                    <span v-if="item.is_mobile == 1" class="badge badge-dark mr-1">Mobile</span>
                    <span class="badge badge-light">
                    Acknowledged by {{ item.views_count }} users
                        </span>
                </div>

                <div class="mt-2">

                </div>

            </div>
            <div class="col-2 text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item
                            @click="(ev) => { ev.preventDefault(); showAnnouncement(item); }"
                            href="#">Preview Announcement
                    </b-dropdown-item>
                    <b-dropdown-item
                            @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'announcementEdit', params : { id : this.item.id }}).catch(error => {}) }"
                            href="#">Edit Announcement
                    </b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); deleteModel(item.id); }" href="#">Delete
                        Announcement
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {deleteResource} from "../../modules/api/methods";
    import {announcementDetail, faqDetail, nationalityGroupDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";


    export default {
        name: "AnnouncementCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            strippedContent() {
                let regex = /(<([^>]+)>)/ig;
                return this.item.content.replace(regex, "");
            },
            showAnnouncement(announcement) {
                this.$root.$children[0].displayAnnouncement(announcement);
            },
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?.')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(announcementDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
