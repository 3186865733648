<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <div style="font-size: 14px;">
                    <p class="mb-0">Client Name: <strong>{{ item.name }}</strong></p>
                    <p class="mb-0">Client ID: <strong>{{ item.email }}</strong></p>
                    <p class="mb-0" v-if="item.organisation != null">
                        Linked to organisation : <strong>{{ item.organisation.name }}</strong>
                    </p>
                </div>
                <div>
                    <b-badge v-if="isSuper()" variant="danger">Super admin access</b-badge>
                </div>
            </div>
            <div class="col text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item
                            @click="newSecret(item.id)"
                            href="#">Generate new client secret
                    </b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.deleteModel(item.id);}" href="#">Delete
                        client
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource, getResource} from "../../modules/api/methods";
    import {clientDetail, tagTypeDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";

    export default {
        name: "ClientCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            isSuper: function () {
                return this.item.roles.filter((obj) => {
                    return obj.super === 1
                }).length > 0;
            },
            newSecret: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure? This will break all api integrations with this client until the new secret is used. Proceed with caution')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            getResource(clientDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$bvModal.msgBoxOk(resp.data.success.data.secret, {title : "New client secret - this will only be shown once", size : "lg"})
                                    .then(value => {

                                    })
                                    .catch(err => {
                                        console.log(err);
                                    });
                            }).catch((err) => {
                                console.log(err);
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure? This will break all api integrations with this client. Proceed with caution')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(clientDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
