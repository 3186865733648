<template>
    <div class="px-3">
        <div class="p-3 bg-white rounded mt-3 border">
            <b-form-group
                    class="text-primary"
                    label="Search our comprehensive support database"
            >
                <b-form-input v-model="keywords" size="lg" placeholder="Search by keyword"
                              debounce="500"></b-form-input>
            </b-form-group>
            <b-form-group
                    class="mb-0"
                    label="Only show me FAQs related to"
                    label-size="sm">
                <div v-if="isLoadingCategories" class="d-flex align-items-center border p-2 rounded">
                    <b-spinner small variant="primary" class="mr-2"></b-spinner>
                    Loading filters
                </div>
                <div v-else>
                    <div class="bg-light p-3 rounded">
                        <b-form-checkbox-group
                                text-field="name"
                                id="checkbox-group-1"
                                v-model="selectedCategories"
                                :options="categories"
                                name="flavour-1"
                        ></b-form-checkbox-group>
                    </div>
                </div>
            </b-form-group>
        </div>

        <surround-loader :is-loading="isLoading">
            <h5 v-if="!isLoading" class="mt-4 px-2">{{ resultsHeader() }}</h5>
            <div v-if="results.length > 0">
                <div class="faq-cell" v-for="item in results" @click="() => { $router.currentRoute.name == 'support' ? $router.push({name:'faqView', params: {id : item.id}}).catch(error => {}) : $router.push({name:'DashboardFaqView', params: {id : item.id}}).catch(error => {}); } ">
                    <div class="bg-white rounded mt-3">
                        <div class="pl-3 pb-1 pr-3 pt-3">
                            <h5 class="mb-2">
                                <text-highlight :queries="queries()">{{ item.question }}</text-highlight>
                            </h5>
                            <div class="text-muted" style="font-size: 14px;">
                                <text-highlight :queries="queries()">{{ strippedContent(item) | truncate(300,'...') }}
                                </text-highlight>
                            </div>
                        </div>
                        <div v-if="item.support_categories.length > 0"
                             class="mt-2 d-flex align-items-center border-top p-3">
                            <span style="font-size: 14px;" class="mr-2">Related to:</span>
                            <b-badge class="mr-1" v-for="category in item.support_categories" v-bind:key="category.id"  variant="info">{{
                                category.name }}
                            </b-badge>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <b-pagination class="mt-2"
                                  v-if="totalRows > perPage"
                                  v-model="currentPage"
                                  :per-page="perPage"
                                  :total-rows="totalRows"></b-pagination>
                </div>

            </div>
            <div v-else-if="!isLoading">
                <div class="p-3 bg-white mt-3 text-muted">
                    Sorry no results for your filter
                </div>
            </div>
        </surround-loader>
        <b-modal :no-close-on-backdrop="true" size="lg" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false">
                    <b-icon-x-circle></b-icon-x-circle>
                </a>
            </div>
            <router-view></router-view>
        </b-modal>
    </div>
</template>

<style scoped>
    .faq-cell {
        cursor: pointer;
    }
    .faq-cell:hover {
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
    }
</style>

<script>
    import {supportCategories, faqs} from "../../modules/api/endpoints";
    import {getResource, postEndpointCancellable} from "../../modules/api/methods";
    import axios from 'axios'
    import SurroundLoader from "../SurroundLoader";
    import TextHighlight from 'vue-text-highlight';

    export default {
        name: "SupportFaqs",
        components: {SurroundLoader, 'text-highlight': TextHighlight},
        data: function () {
            return {
                showModal: false,
                totalRows: 0,
                perPage: 0,
                currentPage: 1,
                isLoading: false,
                cancelSource: null,
                pageSelected: 0,
                results: [],
                keywords: null,
                isLoadingCategories: false,
                categories: [],
                selectedCategories: [] //AND ARRAY OF IDS
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if (newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal && this.$router.currentRoute.name !== 'support' && this.$router.currentRoute.name === 'faqView') {
                    this.$router.replace({'name': 'support'}).catch((err) => {});
                } else if (!newVal && this.$router.currentRoute.name !== 'DashboardSupport' && this.$router.currentRoute.name === 'DashboardFaqView') {
                    this.$router.replace({'name': 'DashboardSupport'}).catch((err) => {});
                }
            },
            currentPage: function (newVal, oldVal) {
                this.loadResults();
            },
            keywords: function (newVal, oldVal) {
                this.loadResults();
            },
            selectedCategories: function (newVal, oldVal) {
                this.loadResults();
            }
        },
        created() {
            this.loadCategories();
            this.loadResults();
        },
        methods: {
            resultsHeader() {
                var selectedNames = [];
                _.each(this.selectedCategories, (catId) => {

                    var cat = this.categories.filter((obj) => {
                        return obj.value === catId;
                    });
                    if (cat.length > 0) {
                        selectedNames.push(cat[0].name);
                    }
                });
                if (this.keywords != null && this.keywords.trim(" ").length > 0 && selectedNames.length > 0) {
                    return "Showing results relating to \"" + selectedNames.join(" or ") + "\" containing \"" + this.keywords + "\"";
                } else if (selectedNames.length > 0) {
                    return "Showing results relating to \"" + selectedNames.join(" or ") + "\"";
                } else if (this.keywords != null && this.keywords.trim(" ").length > 0) {
                    return "Showing results containing \"" + this.keywords + "\"";
                } else {
                    return "Showing all results";
                }
            },
            queries() {
                if (this.keywords != null) {
                    return this.keywords.split(" ");
                }
                return [];
            },
            strippedContent(item) {
                let regex = /(<([^>]+)>)/ig;
                return item.answer.replace(regex, "");
            },
            loadCategories() {
                this.isLoadingCategories = true;
                getResource(supportCategories).then((resp) => {
                    this.categories = resp.data.success
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadCategories);
                }).finally(() => {
                    this.isLoadingCategories = false;
                });
            },
            cancelRequest() {
                if (this.cancelSource) {
                    this.cancelSource.cancel('Cancelled');
                    this.isLoading = true;
                }
            },
            loadResults() {
                this.isLoading = true;
                this.cancelRequest();
                this.cancelSource = axios.CancelToken.source();
                var postData = {};
                if (this.keywords != null && this.keywords.trim(" ").length > 0) {
                    postData['keywords'] = this.keywords;
                }
                if (this.selectedCategories.length > 0) {
                    postData['support_category_ids'] = this.selectedCategories;
                }
                postData.page = this.currentPage;
                postEndpointCancellable("faqs", postData, this.cancelSource.token).then((resp) => {
                    this.results = resp.data.success.data;
                    this.perPage = resp.data.success.per_page;
                    this.currentPage = resp.data.success.current_page;
                    this.totalRows = resp.data.success.total;
                    this.pageSelected = 0;
                    this.isLoading = false;
                    if (this.currentPage !== 1 && this.results.length === 0) {
                        this.currentPage = 1;
                    }
                }).catch((error) => {
                    if (error !== undefined && error.message !== "Cancelled") {
                        this.isLoading = false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
