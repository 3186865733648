<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <b-form-group
            label-size="sm"
            :label="fieldVal.title"
    >
        <div class="d-flex align-items-center">
            <div class="flex-fill">
                <b-form-input @input="() => { this.$emit('field_updated',fieldVal)}" debounce="500"
                              v-model="fieldVal.value" v-if="fieldVal.type === 'free_entry'"></b-form-input>

                <v-select
                        placeholder="Search"
                        :reduce="option => option.value" @input="() => { this.$emit('field_updated',fieldVal)}"
                        v-if="fieldVal.options_endpoint" :filterable="false" label="name" v-model="fieldVal.value"
                        :options="loadedOptions" @search="fetchOptions"></v-select>

                <v-select @input="() => { this.$emit('field_updated',fieldVal)}" label="name" v-model="fieldVal.value"
                          :reduce="option => option.value"
                          :options="fieldVal.options"
                          v-else-if="fieldVal.type === 'option'"></v-select>

                <v-select @input="() => { this.$emit('field_updated',fieldVal)}" label="name" v-model="fieldVal.value"
                          :reduce="option => option.value"
                          :options="fieldVal.options"
                          :multiple="true"
                          v-else-if="fieldVal.type === 'option_multi'"></v-select>

                <date-range-picker
                        opens="right"
                        @update="() => { this.$emit('field_updated',fieldVal)}"
                        @input="() => { this.$emit('field_updated',fieldVal)}"
                        class="d-block"
                        v-if="fieldVal.type === 'date_range'"
                        ref="picker"
                        :auto-apply="true"
                        v-model="fieldVal.value"
                        :dateRange="fieldVal.value != null ? fieldVal.value : {}"
                        :locale-data="localeData"
                        date-util="moment"
                ></date-range-picker>
            </div>
            <b-icon-x-circle @click="() => {fieldVal.value = {startDate : null, endDate : null}; this.$emit('field_updated',fieldVal);}"
                             v-if="fieldVal.type === 'date_range' && fieldVal.value !== null && fieldVal.value.startDate !== null"
                             class="flex-wrap ml-2 clickable" variant="primary"></b-icon-x-circle>

            <b-icon-x-circle @click="() => {fieldVal.value = null; this.$emit('field_updated',fieldVal);}"
                             v-if="fieldVal.type !== 'date_range' && fieldVal.value !== null && fieldVal.value.startDate !== null && fieldVal.value !== []"
                             class="flex-wrap ml-2 clickable" variant="primary"></b-icon-x-circle>
        </div>
    </b-form-group>
</template>


<script>

    import vSelect from 'vue-select'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import axios from "axios";
    import ApiSelectInput from "./ApiSelectInput";

    export default {
        name: "ApiFormInput",
        components: {
            ApiSelectInput,
            "v-select": vSelect,
            DateRangePicker,
        },
        props: {
            field: null,
        },
        data: function () {
            return {
                loadedOptions: [],
                fieldVal: this.field,
                localeData: {
                    direction: 'ltr',
                    format: 'DD/MM/YYYY',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    firstDay: 0
                }
            }
        },
        methods: {
            fetchOptions(search, loading) {
                loading(true);
                var postData = {};
                postData[this.field.options_endpoint_param] = search;

                axios.post(this.field.options_endpoint, postData, {
                    headers: {
                        Accept: "application/json"
                    }
                })
                    .then(resp => {
                        if (resp.data.success !== undefined) {
                            this.loadedOptions = resp.data.success;
                        } else {
                            throw "oops";
                        }
                        loading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        loading(false);
                    });

            }
        }
    }
</script>

<style scoped>

</style>
