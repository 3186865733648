
<script>
    import _ from 'lodash';
    import {getResource} from "../../modules/api/methods";
    import {userImpersonate} from "../../modules/api/endpoints";
    export default {
        data: function () {
            return {

            }
        },
        methods : {
            impersonateUser: function (id) {

                window.localStorage.removeItem('workspaceUserId');

                this.isLoading = true;
                getResource(userImpersonate(id)).then((resp) => {
                    this.$store.dispatch('impersonate', resp.data.success).then((res) => {
                        this.$router.replace({'name': 'dashboard'});
                    });
                    this.isLoading = false;
                }).catch((err) => {
                    this.isLoading = false;
                    this.$root.$children[0].handleApiError(err, () => {
                        return this.impersonateUser(id)
                    });
                })
            },
            leaveImpersonation() {
                //VERIFY WHICH TYPE OF TOKEN AS COULD BE OPEN ID TOKEN
                this.$store.dispatch('endImpersonation').then((res) => {
                    var url = "/dashboard/admin/users-admin?filters="+encodeURI(btoa(JSON.stringify({organisation_id : res.organisation.id})));
                    this.$router.push(url);
                });

            },
        }
    }
</script>
