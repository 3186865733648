<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <frontend-layout>
        <template v-slot:main>
            <div class="p-4">
                <h1 class="mb-0">Features</h1>
            </div>

            <div class="bg-white">
                <div class="row">
                    <div class="col-12 col-md-6 p-5 text-center">

                            <b-iconstack font-scale="4" class="mb-4">
                                <b-icon-file-earmark-text style="color: #DDD;" animation="cylon" stacked></b-icon-file-earmark-text>
                                <b-icon-search scale="0.5" shift-v="-3"  shift-h="3" stacked variant="primary"></b-icon-search>
                            </b-iconstack>

                        <h4>Document Checks</h4>
                        <p class="text-muted">You are able to perform a validation check for documents containing an MRZ
                            code, for example, passports, national IDs cards and visas.</p>
                        <p class="text-muted">You have the option to run a document check which will check the validity
                            of the document and also check the document against a law enforcement database of known
                            fraudulent documents.</p>
                    </div>
                    <div class="col-12 col-md-6 p-5 text-center">
                        <b-iconstack font-scale="4" class="mb-4">
                            <b-icon-file-earmark-text style="color: #DDD;" stacked></b-icon-file-earmark-text>
                            <b-icon-check scale="0.7" shift-v="-3" animation="fade" shift-h="-4" stacked variant="primary"></b-icon-check>
                            <b-icon-check scale="0.7" shift-v="-3" animation="fade" shift-h="4" stacked variant="success"></b-icon-check>
                        </b-iconstack>

                        <h4>Application Approval</h4>
                        <p class="text-muted">Two tier approval process which requires Supervisors to be the only people
                            who approve the Right to Work applications. </p>
                    </div>
                    <div class="col-12 col-md-6 p-5 text-center">
                        <b-iconstack font-scale="4" class="mb-4">
                            <b-icon-shield-lock style="color: #DDD;" stacked></b-icon-shield-lock>
                            <b-icon-gear-wide-connected scale="0.3" shift-v="-3" animation="spin"  stacked variant="success"></b-icon-gear-wide-connected>
                        </b-iconstack>
                        <h4>IP Whitelisting</h4>
                        <p class="text-muted">Whitelist IP Addresses for the web portal to ensure users cannot log in
                            using an IP address that is not specified. This allows you to have additional security
                            within your business.</p>
                    </div>
                    <div class="col-12 col-md-6 p-5 text-center">
                        <b-iconstack font-scale="4" class="mb-4">
                            <b-icon-file-earmark-text style="color: #DDD;"  shift-v="2" stacked></b-icon-file-earmark-text>
                            <b-icon-file-earmark-text scale="0.3" animation="fade" variant="info" shift-h="-4" shift-v="-8" stacked></b-icon-file-earmark-text>
                            <b-icon-file-earmark-text scale="0.3" animation="fade" variant="info"  shift-v="-8" stacked></b-icon-file-earmark-text>
                            <b-icon-file-earmark-text scale="0.3" animation="fade" variant="info"  shift-h="4" shift-v="-8"  stacked></b-icon-file-earmark-text>
                        </b-iconstack>
                        <h4>Additional Documents Types</h4>
                        <p class="text-muted">Allows you to capture any additional documents that you require.
                            Instructions can be added to each document type, so the user is aware of what needs to be
                            captured.</p>
                    </div>

                </div>
            </div>
        </template>
    </frontend-layout>
</template>


<script>


    import FrontendLayout from "../components/layouts/FrontendLayout";

    export default {
        name: 'features',
        components: {
            FrontendLayout

        },
        created() {

        }
    }
</script>
