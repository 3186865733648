<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-6">
                <div class="d-flex mb-3 align-items-center justify-content-center text-primary">
                    <b-spinner small variant="primary" class="mr-2"></b-spinner>
                    Loading Access Right to Work
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import {ssoAuthorizeSilent} from "../../modules/api/endpoints";

    export default {
        name: 'workspace_login',
        data: function () {
            return {

            }
        },
        props : {
            instanceId : null
        },
        components: {

        },
        methods: {
            checkWorkspace() {
                if (this.instanceId != null) {
                    this.$store.commit('setWorkspace',true);
                    console.log("INSTANCE ID IS",this.instanceId);
                    localStorage.setItem('workspaceUserId', this.instanceId);
                    window.location = ssoAuthorizeSilent;

                } else {
                    this.$store.commit('setWorkspace',false);
                }
            }
        },
        computed: {

        },
        created() {
            this.checkWorkspace()
        }
    }
</script>
