<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h5 class="mb-2">{{ item.question }}</h5>
                <div class="text-muted" style="font-size: 14px;">
                    {{ strippedContent() | truncate(300,'...') }}
                </div>
                <div v-if="item.support_categories.length > 0" class="mt-2">
                    <b-badge class="mr-1" v-for="(category,catKey) in item.support_categories" v-bind:key="category.id" variant="info">{{ category.name }}</b-badge>
                </div>
                <div v-else class="mt-2">
                    <b-badge class="mr-1" variant="danger">No categories defined</b-badge>
                </div>
            </div>
            <div class="col-2 text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.$router.push({ name : 'faqEdit', params : { id : this.item.id }}).catch(error => {}) }" href="#">Edit FAQ</b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); deleteModel(item.id); }" href="#">Delete FAQ</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import {deleteResource} from "../../modules/api/methods";
    import {faqDetail, nationalityGroupDetail} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";


    export default {
        name: "FaqCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            strippedContent() {
                let regex = /(<([^>]+)>)/ig;
                return this.item.answer.replace(regex, "");
            },
            deleteModel: function (id) {
                this.$bvModal.msgBoxConfirm('Are you sure?.')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(faqDetail(id)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }

    }
</script>

<style scoped>

</style>
