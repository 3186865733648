<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <b-card no-body>

        <b-tabs pills card vertical lazy end>
            <template v-slot:tabs-start>
                <b-button class="btn btn-secondary mb-3 mt-2" v-on:click="newApplication"><i
                        class="fa fa-plus mr-1"></i>New application
                </b-button>
            </template>
            <b-tab title="Claimed applications" active>
                <application-select
                        v-bind:selected-app="this.$route.name === 'web_app_start_app' && this.$route.params.applicationId != null ? this.$route.params.applicationId : null"
                        claimed="true"></application-select>
            </b-tab>
            <b-tab title="Shared applications">
                <application-select claimed="false"></application-select>
            </b-tab>
        </b-tabs>
    </b-card>

</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import ApplicationSelect from "../ApplicationSelect";

    export default {
        name: 'application_list',
        data: function () {
            return {
                isLoading: false
            }
        },
        components: {
            ApplicationSelect,
            "loader-panel": LoaderPanel,
        },
        methods: {
            resetApplication: function () {
                this.$store.dispatch('resetApplication').then(() => {
                });
            },
            newApplication: function (ev) {
                ev.preventDefault();
                this.resetApplication();
                //CLEAR THE CURRENT STORE APPLICATION AND SWITCH TO INPUT FORM
                this.$router.push({name: "web_app_basic_info"}).catch(error => {})
            }
        },
        mounted() {
            //RESET THE APPLICATION WHEN ENTERING THIS PAGE
            this.resetApplication();
        }

    }
</script>
