<template>
    <loader-panel :isLoading="isLoading">
        <b-card no-body class="bg-white">
            <b-card-body>
                <b-card-sub-title>
                    <div class="mt-1 text-primary">
                        <i class="fa fa-question-circle"></i>
                        Please answer all of the following:
                    </div>
                </b-card-sub-title>
            </b-card-body>

            <b-list-group flush>

                <b-list-group-item v-for="(question,qKey) in questions" v-bind:key="qKey">
                    <div class="d-flex">
                        <div class="mr-3 flex-wrap" v-if="question.answer != null">
                            <b-icon-check variant="success"></b-icon-check>
                        </div>
                        <div class="flex-fill">
                            <p class="font-weight-bold">{{ question.question }}</p>
                            <div>
                                <b-select class="form-control" v-model="question.answer">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </b-select>
                                <div class="alert alert-danger mt-1" v-if="question.answer === 'no'">
                                    By answering this question as no will result in this application being rejected.
                                </div>
                            </div>
                        </div>
                    </div>

                </b-list-group-item>

            </b-list-group>

            <b-card-footer v-if="questionsFinished" class="d-block d-flex justify-content-end align-items-center">
                <b-button class="flex-wrap" :variant="questionsFailed ? 'danger' : 'success'" v-on:click="saveForm">{{ questionsFailed ? 'Save & reject application' : 'Save and continue' }}</b-button>
            </b-card-footer>

        </b-card>
    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import LoaderPanel from "../LoaderPanel";
    import {getResource, saveResource} from "../../../modules/api/methods";
    import {applicationQuestions, questions} from "../../../modules/api/endpoints";

    export default {
        name: 'questions',
        components: {
            LoaderPanel
        },
        data: function () {
            return {
                isLoading: false,
                questions: [],
                application : null,
            }
        },
        created() {
            this.application = this.$store.state.application;
            this.loadQuestions()
        },
        computed: {
            questionsFinished() {
                var isCompleted = true;
                for (var j = 0; j < this.questions.length; j++) {
                    var question = this.questions[j];
                    if (question.answer !== 'yes' && question.answer !== 'no') {
                        isCompleted = false;
                    }
                }
                return isCompleted;
            },
            questionsFailed() {
                var hasFailed = false;
                for (var j = 0; j < this.questions.length; j++) {
                    var question = this.questions[j];
                    if (question.answer === 'no') {
                        hasFailed = true;
                    }
                }
                return hasFailed;
            },
        },
        methods: {
            loadQuestions() {
                this.isLoading = true;
                //CHANGE FOR API CALL
                saveResource(questions, {nationality_id: this.application.nationality_id}).then(response => {
                    this.questions = response.data.success.data
                }).catch(error => {
                    this.$root.$children[0].handleApiError(error,this.loadQuestions)
                }).finally(() => {
                    this.isLoading = false
                });
            },
            saveForm(ev) {
                ev.preventDefault();
                this.isLoading = true;
                var postData = {
                    questions: this.questions
                };

                saveResource(applicationQuestions(this.application.id), postData).then(response => {
                    this.$store.dispatch('updateApplication', response.data.success.data).then(() => {
                        if(this.questionsFailed) {
                            this.$root.$children[0].rejectApplication();
                        } else {
                            this.$router.push({name: 'web_app_student'}).catch(error => {})
                        }
                    });


                }).catch(error => {
                    this.$root.$children[0].handleApiError(error,this.saveForm)
                }).finally(() => {
                    this.isLoading = false
                });

            },
        }
    }
</script>
