<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>

        <surround-loader :is-loading="isLoading">

            <div v-if="error != null" class="no-results d-flex align-items-center text-muted">
                <b-icon-exclamation-triangle class="mr-2"></b-icon-exclamation-triangle>
                <span class="flex-fill">
                    There has been an error loading these results.
                    </span>
                <b-icon-arrow-clockwise @click="loadResults"
                                        class="text-primary ml-2 clickable"></b-icon-arrow-clockwise>
            </div>

            <div v-else>
                <div v-if="results.length == 0 && !isLoading"
                     class="no-results d-flex align-items-center text-muted">
                    <b-icon-x-circle class="mr-2"></b-icon-x-circle>
                    <span class="flex-fill">
                    No results for your filter
                    </span>
                    <b-icon-arrow-clockwise @click="loadResults"
                                            class="text-primary ml-2 clickable"></b-icon-arrow-clockwise>

                </div>
                <div v-else>
                    <div v-if="cellType === 'applications' && !isLoading">

                        <div v-if="!hideBatchActions" class="pl-3 mb-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="flex-fill d-flex align-items-center">
                                    <b-form-checkbox
                                            id="batch-actions"
                                            size="sm"
                                            value="1"
                                            v-model="pageSelected"
                                            unchecked-value="0">
                                        Select page ({{ results.length }})
                                    </b-form-checkbox>

                                    <b-form-checkbox
                                            id="batch-actions-all"
                                            v-if="totalRows > results.length"
                                            size="sm"
                                            class="ml-3"
                                            v-model="dataSelected"
                                            value="1"
                                            unchecked-value="0">
                                        Select whole dataset ({{ totalRows }})
                                    </b-form-checkbox>

                                    <div :id="isArchived ? 'batch-actions-dropdown-archived' : 'batch-actions-dropdown'"
                                         v-if="pageSelected == 1 || dataSelected == 1 || selectedApplications.length > 0">
                                        <b-dropdown size="sm" text="With selected" class="ml-3">
                                            <b-dropdown-item @click="batchAction('archive')" v-if="!isArchived">Archive
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="batchAction('delete')" v-if="isArchived">Delete
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="batchAction('pdf')">Download PDF</b-dropdown-item>
                                            <b-dropdown-item v-if="!isArchived" @click="batchAction('hold')">Put on
                                                hold
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>

                                <b-button :id="cellType === 'applications' ? 'application-csv' : null"
                                          @click="batchAction('csv')" size="sm" variant="success">
                                    <b-icon-cloud-download></b-icon-cloud-download>
                                    Download to CSV
                                </b-button>

                            </div>
                        </div>
                        <b-list-group>
                            <b-list-group-item class="d-flex align-items-center" v-for="(item,key) in results"
                                               v-bind:key="key">
                                <div v-show="!hideBatchActions" class="flex-wrap pr-3 text-center" style="width: 50px">
                                    <b-form-checkbox
                                            :name="'application_ids[]'"
                                            :value="item.id"
                                            v-model="selectedApplications"
                                            unchecked-value="0">
                                    </b-form-checkbox>
                                </div>
                                <application-cell class="flex-fill"
                                                  :show-expiry="showExpiry"
                                                  v-on:delete="loadResults"
                                                  :item="item"></application-cell>
                            </b-list-group-item>
                        </b-list-group>
                    </div>

                    <div v-if="cellType === 'transactions' && !isLoading">

                        <div class="pl-3 mb-2">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-button @click="downloadExcel" size="sm" variant="success">
                                    <b-icon-cloud-download></b-icon-cloud-download>
                                    Download to CSV
                                </b-button>
                            </div>
                        </div>
                        <b-table bordered striped hover :items="results"
                                 :fields="['id','created_at','cost','application','user','transaction_type',]">
                            <template v-slot:head(id)="data">
                                Transaction ID
                            </template>
                            <template v-slot:head(created_at)="data">
                                Occurred
                            </template>
                            <template v-slot:head(cost)="data">
                                Debit
                            </template>
                            <template v-slot:head(application)="data">
                                Application ID
                            </template>
                            <template v-slot:head(transaction_type)="data">
                                Reason
                            </template>
                            <template v-slot:cell(application)="data">
                                <router-link
                                        :to="{name : 'applicationDetail', params: { 'applicationId' : data.value.id} }">
                                    {{ data.value.ref }}
                                </router-link>
                            </template>
                            <template v-slot:cell(cost)="data">
                                £{{ data.value }}
                            </template>
                            <template v-slot:cell(user)="data">
                                {{ data.value.name }}
                            </template>
                            <template v-slot:cell(transaction_type)="data">
                                {{ transactionTypeToNice(data.value) }}
                            </template>
                            <template v-slot:cell(created_at)="data">
                                {{ dateToNice(data.value) }}
                            </template>
                        </b-table>

                    </div>


                    <b-list-group v-if="cellType === 'applications_simple'">

                        <application-simple-cell :claimed="claimed" v-for="(item,key) in results" class="flex-fill"
                                                 v-bind:key="key"
                                                 v-on:delete="loadResults"
                                                 :item="item"></application-simple-cell>

                    </b-list-group>

                    <b-list-group v-if="cellType === 'users'">
                        <user-cell v-for="(item,key) in results" v-bind:key="key"
                                   v-on:delete="loadResults"
                                   :item="item"></user-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'usersPending'">
                        <user-pending-cell v-for="(item,key) in results" v-bind:key="key"
                                           v-on:delete="loadResults"
                                           :item="item"></user-pending-cell>
                    </b-list-group>


                    <b-list-group v-if="cellType === 'workspace-users'">
                        <workspace-user-cell v-for="(item,key) in results" v-bind:key="item.id"
                                             v-on:delete="onWorkspaceDelete"
                                             :item="item"></workspace-user-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'invoices'">
                        <invoice-cell v-for="(item,key) in results" v-bind:key="key"
                                      v-on:delete="loadResults"
                                      :item="item"></invoice-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'tag_types'">
                        <tag-type-cell v-for="(item,key) in results" v-bind:key="key"
                                       v-on:delete="loadResults"
                                       :item="item"></tag-type-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'additional_documents'">
                        <additional-document-cell v-for="(item,key) in results" v-bind:key="key"
                                                  v-on:delete="loadResults"
                                                  :item="item"></additional-document-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'nationalities'">
                        <nationality-cell v-for="(item,key) in results" v-bind:key="key"
                                          v-on:delete="loadResults"
                                          :item="item"></nationality-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'nationality-groups'">
                        <nationality-group-cell v-for="(item,key) in results" v-bind:key="key"
                                                v-on:delete="loadResults"
                                                :item="item"></nationality-group-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'faqs'">
                        <faq-cell v-for="(item,key) in results" v-bind:key="key"
                                  v-on:delete="loadResults"
                                  :item="item"></faq-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'announcements'">
                        <announcement-cell v-for="(item,key) in results" v-bind:key="key"
                                  v-on:delete="loadResults"
                                  :item="item"></announcement-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'document-types'">
                        <document-type-cell v-for="(item,key) in results" v-bind:key="key"
                                            v-on:delete="loadResults"
                                            :item="item"></document-type-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'questions'">
                        <question-cell v-for="(item,key) in results" v-bind:key="key"
                                       v-on:delete="loadResults"
                                       :item="item"></question-cell>
                    </b-list-group>
                    <div v-if="cellType === 'organisations'">
                        <div class="pl-3 mb-2">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-button @click="downloadExcel" size="sm" variant="success">
                                    <b-icon-cloud-download></b-icon-cloud-download>
                                    Organisation excel export
                                </b-button>
                            </div>
                        </div>
                        <b-list-group >
                            <organisation-cell v-for="(item,key) in results" v-bind:key="key"
                                               v-on:delete="loadResults"
                                               :item="item"></organisation-cell>
                        </b-list-group>
                    </div>


                    <b-list-group v-if="cellType === 'user_devices'">
                        <user-device-cell v-for="(item,key) in results" v-bind:key="key"
                                          v-on:delete="loadResults"
                                          :item="item"></user-device-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'organisation_logs'">
                        <organisation-log-cell v-for="(item,key) in results" v-bind:key="key"
                                               v-on:delete="loadResults"
                                               :item="item"></organisation-log-cell>
                    </b-list-group>

                    <b-list-group v-if="cellType === 'clients'">
                        <client-cell v-for="(item,key) in results" v-bind:key="key"
                                     v-on:delete="loadResults"
                                     :item="item"></client-cell>
                    </b-list-group>


                    <div class="d-flex justify-content-center">
                        <b-pagination class="mt-2"
                                      v-if="totalRows > perPage"
                                      v-model="currentPage"
                                      :per-page="perPage"
                                      :total-rows="totalRows"></b-pagination>
                    </div>
                    <div v-if="!isLoading" class="mb-4 mt-2 text-center">Total results : {{ totalRows }}</div>
                </div>
            </div>
        </surround-loader>
    </div>
</template>

<script>

    import {getResource, postEndpointCancellable} from "../modules/api/methods";
    import ApplicationCell from "./applications/ApplicationCell";
    import UserCell from "./users/UserCell";
    import TagTypeCell from "./tag_types/TagTypeCell";
    import AdditionalDocumentCell from "./additional_documents/AdditionalDocumentCell";
    import NationalityCell from "./nationalities/NationalityCell";
    import NationalityGroupCell from "./nationality_groups/NationalityGroupCell";
    import DocumentTypeCell from "./document_types/DocumentTypeCell";
    import QuestionCell from "./questions/QuestionCell";
    import OrganisationCell from "./organisations/OrganisationCell";
    import UserDeviceCell from "./users/UserDeviceCell";
    import OrganisationLogCell from "./organisation_logs/OrganisationLogCell";
    import ApplicationSimpleCell from "./web_app/ApplicationSimpleCell";
    import saveState from "vue-save-state";
    import axios from 'axios'
    import {
        applicationsExcel,
        organisationsExcel,
        serialize,
        transactionsExcel,
        userSingleUse
    } from "../modules/api/endpoints";
    import moment from 'moment';
    import InvoiceCell from "./invoices/InvoiceCell";
    import FaqCell from "./support/FaqCell";
    import SurroundLoader from "./SurroundLoader";
    import WorkspaceUserCell from "./users/WorkspaceUserCell";
    import ClientCell from "./api_clients/ClientCell";
    import UserPendingCell from "./users/UserPendingCell";
    import AnnouncementCell from "./announcements/AnnouncementCell";

    export default {
        mixins: [saveState],
        name: "PaginatedResultLoader",
        components: {
            AnnouncementCell,
            UserPendingCell,
            ClientCell,
            WorkspaceUserCell,
            SurroundLoader,
            FaqCell,
            InvoiceCell,
            ApplicationSimpleCell,
            OrganisationLogCell,
            UserDeviceCell,
            OrganisationCell,
            QuestionCell,
            DocumentTypeCell,
            NationalityGroupCell,
            NationalityCell, AdditionalDocumentCell, UserCell, ApplicationCell, TagTypeCell
        },
        props: {
            showExpiry: {
                type: Boolean,
                default: false,
            },
            cellType: null,
            endpoint: null,
            filters: {},
            hideBatchActions: {
                type: Boolean,
                default: false
            },
            claimed: null
        },
        data: function () {
            return {
                dataSelected: 0,
                pageSelected: 0,
                selectedApplications: [],
                filtersData: {},
                results: [],
                totalRows: 0,
                perPage: 0,
                currentPage: 1,
                isLoading: false,
                cancelSource: null,
                error: null,
            }
        },
        created: function () {
            console.log("LOADING " + this.endpoint + " from created");
            this.loadResults();
        },
        watch: {
            dataSelected: function (newVal, oldVal) {
                if (newVal == 1) {
                    this.pageSelected = 0
                    this.selectedApplications = this.results.map(function (item) {
                        return item.id
                    })
                } else if (this.pageSelected != 1) {
                    this.selectedApplications = [];
                }
                this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
            },
            pageSelected: function (newVal, oldVal) {
                if (newVal == 1) {
                    this.dataSelected = 0
                    this.selectedApplications = this.results.map(function (item) {
                        return item.id
                    })
                } else if (this.dataSelected != 1) {
                    this.selectedApplications = [];
                }
                this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
            },
            selectedApplications: function (newVal, oldVal) {
                if (newVal.length < oldVal.length) {
                    this.pageSelected = 0;
                    this.dataSelected = 0;
                }
                window.setTimeout(() => {
                    this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
                }, 500);

            },
            currentPage: function (newVal, oldVal) {
                console.log("LOADING " + this.endpoint + " from page");
                this.loadResults();
            },

            filters: {
                deep: true,
                immediate: false,
                handler(oldVal, newVal) {
                    console.log("LOADING " + this.endpoint + " from filters");
                    this.loadResults();
                }
            }
        },
        computed: {
            isArchived: function () {
                for (var i = 0; i < this.filters.length; i++) {
                    let filter = this.filters[i];
                    if (filter.value !== undefined && filter.value !== null) {
                        if (filter.api_key == 'status' && filter.value == 'archived') {
                            return true;
                        }
                    }
                }
                return false
            }
        },
        methods: {
            onWorkspaceDelete() {
                this.$emit('delete');
                console.log("LOADING " + this.endpoint + " from emit delete");
                this.loadResults();
            },
            getSaveStateConfig() {
                return {
                    'cacheKey': 'paginatedResult-' + this.endpoint,
                    'saveProperties': ['currentPage']
                };
            },
            cancelRequest() {
                if (this.cancelSource) {
                    this.cancelSource.cancel('Cancelled');
                    this.isLoading = true;
                }
            },
            loadResults() {
                console.log("LOADING ", this.endpoint);
                this.error = null;
                this.isLoading = true;
                this.cancelRequest();
                this.cancelSource = axios.CancelToken.source();
                var postData = {};
                for (var i = 0; i < this.filters.length; i++) {
                    let filter = this.filters[i];
                    if (filter.value !== undefined && filter.value !== null) {
                        postData[filter.api_key] = filter.value;
                    }
                }
                postData.page = this.currentPage;
                postEndpointCancellable(this.endpoint, postData, this.cancelSource.token).then((resp) => {
                    console.log("RRAPGINATES RESPONSE", resp);
                    this.results = resp.data.success.data;
                    this.perPage = resp.data.success.per_page;
                    this.currentPage = resp.data.success.current_page;
                    this.totalRows = resp.data.success.total;
                    this.selectedApplications = [];
                    this.dataSelected = 0;
                    this.pageSelected = 0;
                    this.isLoading = false;
                    if (this.currentPage !== 1 && this.results.length === 0) {
                        this.currentPage = 1;
                    }
                }).catch((error) => {
                    if (error !== undefined && error.message !== "Cancelled") {
                        this.error = error;
                        console.log("PAGINATED ERRO", error);
                        this.isLoading = false;
                    }
                }).finally(() => {
                    this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
                })
            },
            batchAction(action) {
                var params = {action: action};

                var postData = {};
                for (var i = 0; i < this.filters.length; i++) {
                    let filter = this.filters[i];
                    if (filter.value !== undefined && filter.value !== null) {
                        postData[filter.api_key] = filter.value;
                    }
                }
                if (this.dataSelected != 1 && action != 'csv') {
                    postData.application_ids = this.selectedApplications;
                }
                params.filters = postData;
                console.log("PARAMS", params);
                this.$router.push({
                    name: 'applicationBatch',
                    params: {inputData: JSON.stringify(params)}
                }).catch(error => {
                })
            },
            downloadExcel() {
                var postData = {};
                for (var i = 0; i < this.filters.length; i++) {
                    let filter = this.filters[i];
                    if (filter.value !== undefined && filter.value !== null) {
                        postData[filter.api_key] = filter.value;
                    }
                }
                this.isLoading = true;
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {

                    postData.token = resp.data.success.token;

                    var query = serialize(postData, null);
                    if (postData.uploaded_at != null) {
                        if (new moment(postData.uploaded_at.startDate).isValid()) {
                            query = query + "&" + encodeURIComponent('uploaded_at[startDate]') + "=" + new moment(postData.uploaded_at.startDate).format("YYYY-MM-DD");
                        }
                        if (new moment(postData.uploaded_at.endDate).isValid()) {
                            query = query + "&" + encodeURIComponent('uploaded_at[endDate]') + "=" + new moment(postData.uploaded_at.endDate).format("YYYY-MM-DD");
                        }

                    }
                    console.log("QUERY IS", query);
                    if (this.cellType === 'applications') {
                        window.open(applicationsExcel(query), '_blank');
                    } else if (this.cellType === 'organisations') {
                        window.open(organisationsExcel(query), '_blank');
                    } else {
                        window.open(transactionsExcel(query), '_blank');
                    }


                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY HH:mm");
            },
            transactionTypeToNice(transactionType) {
                return transactionType === 'application' ? 'New application' : transactionType === 'doc_check' ? "Document check" : "";
            }
        }

    }
</script>

<style scoped>
    .table-bordered, .table-bordered td, .table-bordered th {
        font-size: 0.8rem;
    }

</style>
