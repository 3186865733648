<template>
    <div v-if="!$store.getters.isWorkspace" class="d-flex flex-column flex-fill bg-light">
        <header class="w-100 bg-white p-2 py-1 d-flex flex-row align-items-center top-nav flex-wrap"
                style="min-height:65px;">
            <div class="flex-fill d-flex align-items-center justify-content-start">
                <div class="flex-wrap ml-2 mr-3 border-right pr-3">
                    <router-link :to="{name : 'home'}">
                        <img src="../../assets/logo.png" style="width: 220px"/>
                    </router-link>
                </div>
            </div>
            <div class="flex-fill d-flex align-items-center justify-content-end mr-4 border-right pr-3">

            </div>
            <div class="flex-wrap">
                <b-button-group v-if="!$store.getters.isAuthenticated" class="mr-2" size="sm">
                    <b-button variant="outline-success" class="px-3"
                              :to="{name : 'register'}">
                        <b-icon-arrow-right-short></b-icon-arrow-right-short>
                        Register
                    </b-button>
                    <b-button variant="outline-primary" class="px-3" @click="onSignIn">
                        <b-icon-arrow-right-short></b-icon-arrow-right-short>
                        Sign in
                    </b-button>
                </b-button-group>
                <router-link v-else-if="$store.getters.isAuthenticated && !$store.getters.isOrgSetup"
                             class="btn btn-outline-success mr-2 btn-sm" :to="{name : 'dashboard'}">
                    <b-icon-arrow-right-short></b-icon-arrow-right-short>
                    Continue setup
                </router-link>
                <router-link v-else-if="$store.getters.isAuthenticated && $store.getters.isOrgSetup"
                             class="btn btn-outline-success mr-2 btn-sm" :to="{name : 'dashboard'}">
                    <b-icon-arrow-right-short></b-icon-arrow-right-short>
                    Dashboard
                </router-link>

                <b-btn variant="outline-primary" v-if="$store.getters.isAuthenticated"
                             class="mr-2 btn-sm" @click="logout">
                    Sign out
                </b-btn>

            </div>
        </header>

        <div class="flex-fill d-block">
            <slot name="main">
            </slot>
        </div>

        <footer class="bg-secondary p-4 text-white">
            <div class="row align-items-center pb-4">
                <div class="col-md-9 px-4">
                    <div>
                        <img class="" style="width: 250px" src="../../assets/logo_white.png"/>
                    </div>
                </div>

                <div class="col-md-3 px-4">
                    <div class="d-flex align-items-center">
                        <a class="flex-fill w-50"
                           href="https://play.google.com/store/apps/details?id=com.theaccessgroup.accessrtw"
                           target="_blank">
                            <img class="w-100" src="../../assets/google_link.png"/>
                        </a>
                        <a class="flex-fill w-50" href="https://apps.apple.com/uy/app/access-rtw/id1509944704"
                           target="_blank"><img
                                class="w-100" src="../../assets/apple_link.png"/></a>
                    </div>

                </div>
            </div>
            <div class="bg-secondary border-top border-dark text-muted">
                <div class="mt-4">© 2020 The Access Group</div>
            </div>
        </footer>

    </div>
    <div v-else class="d-flex flex-column flex-fill bg-light">

    </div>
</template>

<script>
    import UserMenu from "../menus/UserMenu";
    import {ssoAuthorizeHint} from "../../modules/api/endpoints";

    export default {
        name: "FrontendLayout",
        components: {
            'user-menu': UserMenu
        },
        data: function () {
            return {}
        },
        created() {
            if (this.$store.getters.currentToken != null) {
                console.log("refreshing");
                this.$store.dispatch('refreshUser');
            }
        },
        methods: {
            onSignIn: function () {
                this.isLoading = true;
                window.location = ssoAuthorizeHint("");
            },
            logout() {
                if(!this.$store.getters.isWorkspace) {
                    //VERIFY WHICH TYPE OF TOKEN AS COULD BE OPEN ID TOKEN
                    this.$store.commit('logout');
                    this.$router.replace({'name': 'home'}).then(() => {
                        this.$root.$children[0].showLogoutMessage();
                    }).catch((err) => {

                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
