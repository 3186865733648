<template>
    <div>
        <div class="image-zoom position-fixed d-flex justify-content-center align-items-center" v-if="enlarged === true">
            <b-icon-x-circle-fill @click="close" class=" mr-1 mt-1 h2 image-zoom-close"
                                  variant="primary"></b-icon-x-circle-fill>


                <div v-if="src != null" class="flex-fill p-3 h-100 d-flex align-items-center justify-content-center">
                    <image-view style="max-height: 100%; max-width: 100%" :disable-enlarge="true" :src="src" size="large"></image-view>
                </div>
                <div v-if="secondarySrc != null" class="flex-fill p-3 h-100 d-flex align-items-center justify-content-center">
                    <image-view style="max-height: 100%; max-width: 100%" :disable-enlarge="true" :src="secondarySrc" size="large"></image-view>
                </div>
            </div>
    </div>
</template>

<script>

    export default {
        name: "image-zoom",
        components: {
            'image-view': () => import('./ImageView.vue')
        },
        props: {
            value: null,
            src: null,
            secondarySrc: null,
        },
        data: function () {
            return {
                enlarged: this.value
            }
        },
        mounted: function () {

        },
        watch: {
            value: function (newVal, oldVal) {
                this.enlarged = newVal;
                this.$emit('input', newVal);
            }
        },
        methods: {
            close: function () {
                this.enlarged = false;
                this.$emit('input', false);
            }
        }
    }
</script>

<style>
    .image-zoom-close {
        position: fixed;
        top: 0;
        right: 0;
    }

    .image-zoom {
        z-index: 9999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
    }
</style>
