<template>
    <loader-panel small="true" :isLoading="isLoading">
        <div class="text-right">
            <div>
                <image-view class="img-thumbnail mb-2 d-inline-block" style="width: 150px;" size="thumb"
                            v-if="src != null && src.length > 10"
                            :src="src"
                            @click="imageIndex = 0"></image-view>
            </div>
            <b-btn v-on:click="updateImage">{{ button_label != null ? button_label : "Upload image"}}</b-btn>
        </div>
        <div>

        </div>
        <b-modal scrollable size="lg" v-model="showPopup" title="Upload image/PDF" :hide-footer="image == null"
                 footer-class="d-block">
            <div slot="default">
                <div class="form-group" v-if="error != null">
                    <div class="alert alert-danger">
                        {{ error }}
                    </div>
                </div>

                <div v-if="!expiryShown">

                    <canvas id="pdf-canvas" style="opacity: 0" height="0"></canvas>

                    <div v-if="image == null && inputType !== 'camera'">
                        <b-form-group label="Upload image or PDF from computer:">
                            <b-form-file
                                    accept="application/pdf,image/png,image/jpg,image/jpeg"
                                    @change="loadFile"
                                    v-model="file"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-form-group>

                        <div class="p-3 text-center">
                            <div class="badge badge-primary">
                                OR
                            </div>
                        </div>

                        <div class="p-3 text-center">
                            <a @click="selectCamera" href="#" class="btn btn-primary">
                                Capture from camera
                            </a>
                        </div>

                    </div>

                    <div v-show="inputType === 'camera' && image == null">
                        <div class="form-group" v-if="cameraError != null">
                            <div class="alert alert-danger">
                                {{ cameraError }}
                            </div>
                        </div>
                        <vue-web-cam
                                class="bg-dark"
                                ref="webcam"
                                :device-id="deviceId"
                                width="100%"
                                @started="onStarted"
                                @stopped="onStopped"
                                @error="onError"
                                @cameras="onCameras"
                                @camera-change="onCameraChange"
                        />
                        <b-form-group
                                size="sm"
                                label="Select camera"
                        >
                            <b-select size="sm" v-model="camera">
                                <option
                                        v-for="device in devices"
                                        :key="device.deviceId"
                                        :value="device.deviceId"
                                >{{ device.label }}
                                </option>
                            </b-select>
                        </b-form-group>


                        <!--                        <vue-web-cam v-on:onPhoto="onPhoto" v-on:onReady="onReady" capture="photo" on class="mb-4">-->
                        <!--                        </vue-web-cam>-->
                        <div class="d-flex justify-content-between">
                            <b-button class="flex-wrap" v-on:click="updateImage">Cancel</b-button>
                            <b-button variant="success" class="flex-wrap" v-on:click="onCapture">Capture</b-button>
                        </div>

                    </div>

                    <div v-if="image != null">
                        <cropper
                                classname="cropper mb-3"
                                :src="image"
                                @change="onCrop"
                        ></cropper>
                    </div>

                    <div v-if="pdf != null && pdf.numPages > 1">
                        <b-form-group label="Select page to import:">
                            <b-select
                                    @change="selectPage"
                                    v-model="currentPage">
                                <option :value="i" v-bind:key="i" v-for="i in pdf.numPages">Page {{ i }}</option>
                            </b-select>
                        </b-form-group>
                    </div>

                </div>

                <div v-if="expiryShown">
                    <b-form-group label="Please enter the expiry date for this document:">

                        <masked-input
                                v-on:input="(event) => this.$emit('expiryChanged', {captureId : this.captureId, expiry : event})"
                                class="form-control" v-model="expiryDate" mask="11/11/1111" placeholder="dd/mm/yyyy"/>

                        <div class="alert alert-danger mt-1" v-if="expiryError != null">
                            {{ expiryError }}
                        </div>

                    </b-form-group>
                </div>

            </div>

            <div class="d-block d-flex justify-content-between align-items-center" slot="modal-footer">
                <b-button class="flex-wrap" v-on:click="updateImage">Choose new image</b-button>
                <b-button class="flex-wrap" variant="success" v-on:click="uploadImage">Crop and save</b-button>
            </div>

        </b-modal>


    </loader-panel>
</template>

<script>
    import {Cropper} from 'vue-advanced-cropper'
    import * as PDFJS  from 'pdfjs-dist'
    import {WebCam} from "vue-web-cam";
    import LoaderPanel from "../../components/web_app/LoaderPanel";
    import moment from 'moment'
    import MaskedInput from "vue-masked-input/src/MaskedInput";
    import VueGallery from 'vue-gallery';
    import {saveResource} from "../../modules/api/methods";
    import {upload} from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";
    import ImageView from "./ImageView";

    export default {
        name: "ImageUploader",
        data: function () {
            return {
                imageIndex: null,
                isLoading: false,
                showPopup: false,
                image: null,
                file: null,
                currentPage: 0,
                pdf: null,
                error: null,
                cameraError: null,
                croppedCanvas: null,
                inputType: null,
                src: this.value,
                expiryDate: this.expiry,
                expiryShown: false,
                expiryError: null,
                camera: null,
                deviceId: null,
                devices: []
            }
        },
        components: {
            'image-view': ImageView,
            MaskedInput,
            'gallery': VueGallery,
            'vue-web-cam': WebCam,
            Cropper,
            'loader-panel': LoaderPanel,
        },
        props: {
            captureId: null,
            expiry: null,
            expiryRequired: false,
            value: null,
            button_label: null
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                // Once we have a list select the first one
                const [first, ...tail] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            },
            inputType: function (newValue, oldValue) {
                console.log("INPUT TYPE", oldValue, newValue);
                if(newValue == "camera") {
                    console.log("NEW VALUE IS CAMERA");
                    if(this.$refs.webcam !== undefined) {
                        this.$refs.webcam.stop();
                        this.$refs.webcam.start();
                    }
                } else {
                    if(this.$refs.webcam !== undefined) {
                        this.$refs.webcam.stop();
                    }
                }
            },
        },
        methods: {

            onCapture() {
                this.isLoading = true;
                this.image = this.$refs.webcam.capture();
                this.isLoading = false;

            },
            onStarted(stream) {
                console.log("On Started Event", stream);
            },
            onStopped(stream) {
                console.log("On Stopped Event", stream);
            },
            onStop() {
                this.$refs.webcam.stop();
            },
            onStart() {
                this.$refs.webcam.start();
            },
            onCameras(cameras) {
                this.devices = cameras;
                console.log("On Cameras Event", cameras);
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;
                console.log("On Camera Change Event", deviceId);
            },
            customFormatter(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            blobToBase64(blob, callback) {
                var reader = new FileReader();
                reader.onload = function () {
                    var dataUrl = reader.result;
                    callback(dataUrl);
                };
                reader.readAsDataURL(blob);
            },
            onReady(photo) {
                this.isLoading = true;
                this.blobToBase64(photo, (base64) => {
                    this.image = base64;
                    this.isLoading = false;
                })
            },
            onPhoto() {
            },
            viewExisting() {

            },
            selectCamera(ev) {
                ev.preventDefault()
                this.inputType = 'camera'
            },
            uploadImage(ev) {
                ev.preventDefault();


                if (this.expiryRequired && this.expiryDate == null) {
                    this.expiryShown = true;
                    return;
                } else if (this.expiryRequired) {

                    //VALIDATE EXPIRY
                    var date = moment(this.expiryDate, 'DD/MM/YYYY', true);
                    if (!date.isValid()) {
                        this.expiryShown = true;
                        this.expiryError = "Please enter a valid expiry date in dd/mm/yyyy format";

                        return;
                    } else if (date.isBefore(new Date())) {
                        this.expiryShown = true;
                        this.expiryError = "Expiry date must be in the future";

                        return;
                    }
                }

                this.isLoading = true;
                var postData = {
                    data: this.croppedCanvas.toDataURL()
                };
                saveResource(upload, postData).then(response => {

                    this.src = response.data.success.id;
                    this.$emit('input', this.src);
                    this.$emit('on_upload', this.src);
                    this.showPopup = false;

                }).catch(error => {
                    if (containsErrors(error)) {
                        this.errors = error.data.errors
                    }
                    this.isLoading = false;
                    ////this.$root.$children[0].handleApiError(error,this.uploadImage)
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            onCrop({coordinates, canvas}) {
                console.log(coordinates);
                this.croppedCanvas = canvas;
            },
            selectPage() {
                this.pdf.getPage(this.currentPage).then((page) => {
                    var scale = 1.5;
                    var viewport = page.getViewport({scale: scale});

                    // Prepare canvas using PDF page dimensions
                    var canvas = document.getElementById('pdf-canvas');
                    var context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    var renderTask = page.render(renderContext);
                    renderTask.promise.then(() => {
                        this.image = canvas.toDataURL();
                        this.error = null;
                    }).catch((error) => {
                        this.onError(error);
                    });

                }).catch((error) => {
                    this.onError(error);
                });
            },
            loadFile(ev) {
                this.file = ev.target.files[0];
                const reader = new FileReader();
                var isPdf = false;
                reader.onload = (e) => {
                    if (e.target.result === undefined || e.target.result == null) {
                        this.onError("Invalid file uploaded");
                    }
                    try {
                        if (isPdf) {
                            PDFJS.GlobalWorkerOptions.workerSrc = "/build/pdf.worker.js";
                            var loadingTask = PDFJS.getDocument({data: e.target.result});
                            loadingTask.promise.then((pdf_doc) => {
                                this.pdf = pdf_doc;
                                if (this.pdf.numPages === 1) {
                                    this.currentPage = 1;
                                    this.selectPage();
                                }
                            }).catch(error => {
                                this.onError(error);
                            });

                        } else {
                            this.image = e.target.result;
                            this.error = null;
                        }
                    } catch (ex) {
                        console.log(ex);
                        this.onError("We are unable to process this file, please ensure it is not corrupt.");
                    }

                };
                if (this.file.type.toLowerCase() == "image/png" ||
                    this.file.type.toLowerCase() == "image/jpg" ||
                    this.file.type.toLowerCase() == "image/jpeg" ||
                    this.file.type.toLowerCase() == "image/gif"
                ) {
                    isPdf = false;
                    reader.readAsDataURL(this.file);
                } else if (this.file.type.toLowerCase() == "application/pdf") {
                    isPdf = true;
                    reader.readAsBinaryString(this.file);
                }
            },
            onError(error) {
                this.image = null;
                this.pdf = null;
                this.currentPage = 0;
                if(error == "NotAllowedError: Permission denied") {
                    this.cameraError = "Permission to camera has been rejected. You will not be able to capture from this device's cameras until this is has been accepted.";
                } else {
                    this.error = error;
                }

            },
            updateImage() {
                this.expiryDate = null;
                this.image = null;
                this.pdf = null;
                this.currentPage = 0;
                this.showPopup = true;
                this.error = null;
                this.inputType = null;
                this.expiryShown = false;
            },
            closePopup() {
                this.showPopup = false;
            }
        }
    }
</script>

<style scoped>
    #pdf-canvas {
        display: none;
    }

    .blueimp-gallery .close {
        color: #fff;
    }

    .blueimp-gallery-display {
        display: none;
    }
</style>
