<template>
    <div style="">
        <vue-tree-navigation class="side-nav" :items="menuItems"></vue-tree-navigation>
    </div>
</template>

<script>

    export default {
        name: "LeftMenu",
        components: {},

        data: function () {
            return {}
        },
        computed: {
            menuItems: function () {
                let items = [
                    {name: "Dashboard", path: "/dashboard/"},
                    {name: "Applications", path: "/dashboard/applications"},
                    {name: "Expiring documents", path: "/dashboard/expiring-documents"},
                ];

                if (this.$store.getters.isAdmin) {
                    items.push({
                        name: "Admin menu",
                        children: [
                            {name: "Manage users", path: "/dashboard/admin/users"},
                            {name: "Manage tag types", path: "/dashboard/admin/tags"},
                            {name: "Additional document types", path: "/dashboard/admin/additional-documents"},
                            {name: "Additional features", path: "/dashboard/admin/additional-features"},
                            {name: "Config audit log", path: "/dashboard/admin/config-log"},
                        ]
                    });
                }
                if (this.$store.getters.isBilling) {
                    items.push({
                        name: "Billing",
                        children: [
                            {name: "Transactions", path: "/dashboard/admin/transactions"},
                            {name: "Invoices", path: "/dashboard/admin/invoices"},
                            {name: "Billing details", path: "/dashboard/admin/billing"},
                        ]
                    });
                }

                if (this.$store.getters.isSuper) {
                    items.push({
                        name: "Access admin menu",
                        children: [
                            {name: "Announcements", path: "/dashboard/admin/announcements"},
                            {name: "Analytics", path: "/dashboard/admin/analytics"},
                            {name: "Manage nationalities", path: "/dashboard/admin/nationalities"},
                            {name: "Manage nationality groups", path: "/dashboard/admin/nationality-groups"},
                            {name: "Manage faqs", path: "/dashboard/admin/faqs"},
                            {name: "Manage document types", path: "/dashboard/admin/document-types"},
                            {name: "Manage questions", path: "/dashboard/admin/questions"},
                            {name: "Manage users", path: "/dashboard/admin/users-admin"},
                            {name: "Manage organisations", path: "/dashboard/admin/organisations"},
                            {name: "General settings", path: "/dashboard/admin/settings"},
                            {name: "API clients", path: "/dashboard/admin/api-clients"},
                        ]
                    });
                }

                items.push({
                    name: "Support",
                    children: [
                        {name: "FAQs", path: "/dashboard/support"},
                        {name: "Training media", path: "/dashboard/support/training-media"},
                        {name: "Manual", external: "https://docs.accessrtw.co.uk/"},
                    ]
                });


                return items;
            }
        }

    }
</script>

<style scoped>

</style>
