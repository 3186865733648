<template>
    <b-list-group-item class="tag-type-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <h4 class="mb-0">£{{ item.transactions_total }}</h4>
                <div class="text-muted mb-1">
                    <b-badge variant="light">
                        x{{ item.application_count }} applications
                    </b-badge>
                    <b-badge variant="light ml-1">
                        x{{ item.doc_check_count }} document checks
                    </b-badge>
                </div>

                <div class="d-flex align-items-center font-weight-bold mb-1">
                    <span style="font-size:14px;" class="mb-0 text-muted">Payment status:</span>
                    <b-badge class="ml-2" :variant="item.status === 'invoiced' || item.status === 'paid' ? 'success' : item.status === 'failed' ? 'danger' : 'info'">{{ item.status.toUpperCase() }}</b-badge>
                </div>
                <div class="d-flex align-items-center font-weight-bold">
                    <span style="font-size:14px;" class="mb-0 text-muted">Date issued: {{ dateToNice(item.created_at) }}</span>

                </div>
            </div>
            <div class="col-2 text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2">
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); download(item.id,'csv'); }" href="#">Download transactions CSV</b-dropdown-item>
                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); download(item.id,'pdf'); }" href="#">Download PDF</b-dropdown-item>
                    <b-dropdown-item v-if="item.status !== 'paid'" @click="(ev) => { ev.preventDefault(); payInvoice(item.id); }" href="#">Pay invoice</b-dropdown-item>
                </b-dropdown>
                <b-button size="sm" class="mr-2" variant="outline-danger" v-if="item.status === 'failed'" @click="(ev) => { ev.preventDefault(); payInvoice(item.id); }" href="#">Pay invoice</b-button>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>

    import CellLoader from "../ui/CellLoader";
    import {deleteResource, getResource} from "../../modules/api/methods";
    import {
        invoicePay,
        invoicePDF, invoices,
        nationalityDetail,
        serialize,
        transactionsExcel,
        userSingleUse
    } from "../../modules/api/endpoints";
    import moment from "moment";
    export default {
        name: "InvoiceCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY");
            },
            payInvoice(invoiceId) {
                this.isLoading = true;
                getResource(invoicePay(invoiceId)).then((resp) => {
                    if(resp.data.success.data.status === 'paid') {
                        this.$bvToast.toast('Invoice has now been paid.', {
                            title: 'Success',
                            variant: 'success',
                            solid: false
                        });
                    } else {
                        this.$bvToast.toast('Payment has failed, please update your payment details and try again.', {
                            title: 'Payment failed',
                            variant: 'danger',
                            solid: false
                        });
                    }
                    this.$emit('delete');
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            download(invoiceId,type) {
                var postData = {'batch_id' : invoiceId};

                this.isLoading = true;
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {
                    postData.token = resp.data.success.token;
                    var query = serialize(postData,null);
                    if(type === 'csv') {
                        window.open(transactionsExcel(query), '_blank');
                    } else {
                        window.open(invoicePDF(query), '_blank');
                    }

                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
        }

    }
</script>

<style scoped>

</style>
