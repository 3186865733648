<template>
    <div>
        <div v-if="isLoading" :style="this.small ? 'min-height: 100px' : 'min-height:500px'" class="d-flex align-items-center justify-content-center">
            <b-spinner variant="primary" :style="this.small ? '' : 'width: 4rem; height: 4rem;'" label="Loading"></b-spinner>
        </div>
        <div v-if="!isLoading">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: "LoaderPanel",
        data: function () {
            return {}
        },
        props: {
            isLoading: false,
            small: false
        }
    }
</script>
