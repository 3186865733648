<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <loader-panel :is-loading="isLoading">

        <div class="alert alert-info mb-3" v-if="selectedDocument == null">
            Please select 1 of the {{ documents.length }} documents to complete for <strong>{{
            application.first_name }}</strong>
        </div>
        <div class="mb-3" v-if="selectedDocument != null">
            <a href="#" v-on:click="(event) => { event.preventDefault(); selectDocument(null); }"><i
                    class="fa fa-arrow-left"></i> Complete different document</a>
        </div>
        <b-card no-body v-for="(document,key) in documents" v-bind:key="key" class="mb-3 bg-light"
                :style="selectedDocument !== document.id && selectedDocument != null ? 'display:none' : 'display:block'">
            <b-card-body>
                <b-card-title class="d-flex ">
                    <div class="flex-fill">
                        {{ document.name }}


                    </div>
                    <div class="flex-wrap">
                        <a href="javascript:void(null)" v-on:click="selectDocument(document.id)"
                           class="btn btn-sm btn-primary"
                           v-if="selectedDocument == null">
                            Complete document
                        </a>
                    </div>
                </b-card-title>
                <b-card-sub-title>
                    List type {{ document.list_type }}
                </b-card-sub-title>

                <b-card no-body v-if="selectedDocument === document.id" class="bg-white mt-3" sub-title="">
                    <b-card-body>
                        <b-card-sub-title>
                            <div class="mt-1 text-primary">
                                <i class="fa fa-file-upload"></i>
                                Documents required:
                            </div>
                        </b-card-sub-title>
                    </b-card-body>

                    <b-list-group flush>

                        <b-list-group-item v-for="(capture,captureKey) in document.captures" v-bind:key="capture.id">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="mr-3 flex-wrap" v-if="captureComplete(capture)">
                                    <b-icon-check variant="success"></b-icon-check>
                                </div>
                                <div class="flex-fill">
                                    <strong>{{ capture.name }}</strong>
                                    <div class="text-muted font-italic">
                                        {{ capture.instructions }}
                                    </div>

                                </div>
                                <div class="flex-wrap">
                                    <image-uploader :expiryRequired="capture.expires === 1"
                                                    :captureId="capture.id"
                                                    @expiryChanged="expiryUpdated"
                                                    :ref="'capture-'+capture.id"
                                                    v-on:on_upload="onUpload"
                                                    v-model="capture.selected"
                                                    :expiry="capture.expiryDate"
                                    ></image-uploader>
                                    <div v-if="capture.expiry === '1' && capture.expiryDate != null"
                                         class="text-danger font-weight-bold mt-2 text-center">
                                        Expiry: {{ capture.expiryDate }}
                                    </div>
                                </div>


                            </div>
                        </b-list-group-item>

                    </b-list-group>
                </b-card>

                <b-card no-body v-if="selectedDocument === document.id && document.questions.length > 0"
                        class="bg-white mt-3">
                    <b-card-body>
                        <b-card-sub-title>
                            <div class="mt-1 text-primary">
                                <i class="fa fa-question-circle"></i>
                                Please answer all of the following:
                            </div>
                        </b-card-sub-title>
                    </b-card-body>

                    <b-list-group flush>

                        <b-list-group-item v-for="(question,qKey) in document.questions" v-bind:key="qKey">
                            <div class="d-flex">
                                <div class="mr-3 flex-wrap" v-if="question.answer != null">
                                    <b-icon-check variant="success"></b-icon-check>
                                </div>
                                <div class="flex-fill">
                                    <p class="font-weight-bold">{{ question.question }}</p>
                                    <div>
                                        <b-select class="form-control" v-model="question.answer">
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </b-select>
                                        <div class="alert alert-danger mt-1" v-if="question.answer === 'no'">
                                            By answering this question as no will result in this application being
                                            rejected.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </b-list-group-item>

                    </b-list-group>

                </b-card>

                <b-card no-body v-if="selectedDocument === document.id" class="bg-white mt-3">
                    <b-card-body>
                        <b-card-sub-title>
                            <div class="mt-1 text-primary">
                                <i class="fa fa-search"></i>
                                Any name differences?
                            </div>
                        </b-card-sub-title>
                    </b-card-body>

                    <b-list-group flush>

                        <b-list-group-item>
                            <div class="d-flex">
                                <div class="mr-3 flex-wrap" v-if="nameCheckComplete(document)">
                                    <b-icon-check variant="success"></b-icon-check>
                                </div>
                                <div class="flex-fill">
                                    <p class="font-weight-bold">Are there any name differences between the captured
                                        document
                                        and
                                        the
                                        information previously supplied?
                                    </p>
                                    <div>
                                        <b-select class="form-control" v-model="document.name_diff">
                                            <option value="false">No differences</option>
                                            <option value="true">Yes</option>
                                        </b-select>
                                        <div v-if="document.name_diff === 'true'" class="d-flex alert alert-danger">
                                            <div class="flex-fill">
                                                Please capture an image of a document supporting this change in name,
                                                e.g.
                                                Marriage
                                                Certificate
                                            </div>
                                            <image-uploader v-on:on_upload="onUpload"
                                                            v-model="document.name_diff_id"
                                                            class="flex-wrap"></image-uploader>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </b-list-group-item>

                    </b-list-group>

                </b-card>

            </b-card-body>

            <b-card-footer v-if="documentFinished(document)"
                           class="d-block d-flex justify-content-end align-items-center">
                <b-button class="flex-wrap" :variant="questionsFailed(document) ? 'danger' : 'success'"
                          v-on:click="saveForm">{{ questionsFailed(document) ? 'Save & reject application' : 'Save and continue'
                    }}
                </b-button>
            </b-card-footer>

        </b-card>
    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import ImageUploader from "../ImageUploader";
    import LoaderPanel from "../LoaderPanel";
    import moment from 'moment'
    import {saveResource} from "../../../modules/api/methods";
    import {applicationDocuments, documentTypes} from "../../../modules/api/endpoints";
    import _ from 'lodash'

    export default {
        name: 'documents',
        data: function () {
            return {
                application: {},
                isLoading: false,
                documents: [],
                selectedDocument: null,
                form: {
                    captures: {}
                },
            }
        },
        components: {
            "image-uploader": ImageUploader,
            "loader-panel": LoaderPanel,
        },
        mounted() {
            this.application = this.$store.state.application;
            //CHECK CORRECT PROGRESS
            if (this.application.id === undefined) {
                this.$router.replace({"name": "web_app_basic_info"});
                return;
            }
            this.loadDocuments();
        },
        methods: {
            expiryUpdated(val) {
                if (val.captureId !== undefined) {
                    var date = moment(val.expiry, 'DD/MM/YYYY', true);
                    if (date.isValid()) {
                        //UPDATE THE CAPTURE
                        for (var i = 0; i < this.documents.length; i++) {
                            for (var j = 0; j < this.documents[i].captures.length; j++) {
                                if (this.documents[i].captures[j].id === val.captureId) {
                                    this.documents[i].captures[j].expiryDate = val.expiry;
                                }
                            }
                        }
                    }
                }
            },
            saveForm(ev) {
                ev.preventDefault();
                this.isLoading = true;
                var postData = {};

                for (var i = 0; i < this.documents.length; i++) {
                    var document = this.documents[i];
                    if (this.selectedDocument == document.id) {
                        postData.document = document;
                        break;
                    }
                }

                saveResource(applicationDocuments(this.application.id), postData).then(response => {
                    this.$store.dispatch('updateApplication', response.data.success.data).then(() => {
                        if(this.questionsFailed(postData.document)) {
                            this.$root.$children[0].rejectApplication();
                        } else {
                            this.$router.push({name: 'web_app_photo'}).catch(error => {})
                        }
                    });

                }).catch(error => {
                    //this.$root.$children[0].handleApiError(error, this.saveForm)
                }).finally(() => {
                    this.isLoading = false
                });

            },
            questionsFailed(document) {
                var hasFailed = false;

                _.each(document.questions,function(question) {
                    if (question.answer === 'no') {
                        hasFailed = true;
                    }
                });

                return hasFailed;
            },
            documentFinished(document) {
                var isCompleted = true;
                for (var i = 0; i < document.captures.length; i++) {
                    var capture = document.captures[i];
                    if (!this.captureComplete(capture)) {
                        isCompleted = false;
                    }
                }

                for (var j = 0; j < document.questions.length; j++) {
                    var question = document.questions[j];
                    if (question.answer !== 'yes' && question.answer !== 'no') {
                        isCompleted = false;
                    }
                }

                if (!this.nameCheckComplete(document)) {
                    isCompleted = false;
                }

                return isCompleted;
            },
            nameCheckComplete(document) {
                if (document.name_diff === 'true') {
                    if (document.name_diff_id != null) {
                        return true;
                    }
                } else if (document.name_diff === 'false') {
                    return true;
                }
                return false;
            },
            captureComplete(capture) {
                if (capture.selected != null) {
                    return true;
                }
                return false;
            },
            selectDocument(documentId) {
                this.selectedDocument = documentId
            },
            onUpload() {
            },
            loadDocuments() {
                this.isLoading = true;
                //CHANGE FOR API CALL
                saveResource(documentTypes, {nationality_id: this.application.nationality_id}).then(response => {
                    this.documents = response.data.success.data
                    //CHECK FOR SELECTED IN APPLICATION
                    if (this.application.captures != null && this.application.captures.length > 0 && this.application.captures[0].document_type !== undefined) {
                        this.selectedDocument = this.application.captures[0].document_type.id;
                        var vm = this;
                        //NEED TO LOOP THROUGH APPLICATION AND FILL DATA
                        var docsFinal = [];
                        var selectedFound = false;
                        _.each(this.documents, function (doc) {
                            if (doc.id === vm.selectedDocument) {
                                selectedFound = true;
                            }
                            var capturesFinal = [];
                            _.each(doc.captures, function (capture) {
                                _.each(vm.application.captures, function (myCapture) {
                                    if (myCapture.document_type_capture_id === capture.id) {
                                        capture.selected = myCapture.image_upload_id;
                                    }
                                });
                                capturesFinal.push(capture);
                            });
                            doc.captures = capturesFinal;
                            if (vm.application.name_change_image_upload_id != null) {
                                doc.name_diff = 'true';
                                doc.name_diff_id = vm.application.name_change_image_upload_id;
                            } else {
                                doc.name_diff = 'false';
                            }
                            docsFinal.push(doc);
                        });
                        this.documents = docsFinal;
                        if (!selectedFound) {
                            this.selectedDocument = null;
                        }
                    }


                }).catch(error => {
                    this.$root.$children[0].handleApiError(error, this.loadDocuments)
                }).finally(() => {
                    this.isLoading = false
                });
            }
        }
    }
</script>
