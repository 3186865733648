<template>
    <div class="position-relative" :style="isLoading ? 'min-height: 100px;' : ''">
        <div>
            <slot>

            </slot>
        </div>
        <div class="position-absolute bg-white d-flex align-items-center justify-content-center" style="top: 0; bottom: 0; left: 0; right: 0; opacity: 0.8; z-index: 10;" v-if="isLoading">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
    </div>

</template>

<script>
    import ApiFormInput from "./form_inputs/ApiFormInput"
    import {getEndpoint} from "../modules/api/methods";
    import _ from "lodash";

    export default {
        name: "SurroundLoader",
        props: {
            isLoading: false,
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style scoped>

</style>
