<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="mt-1" v-if="errorOutput.length > 0">
            <p style="font-size: 12px;" :class="key === 0 ? 'text-danger mb-0' : 'text-danger mt-0 mb-0'"
               v-for="(obj,key) in errorOutput" v-bind:key="key">
                {{ obj }}
            </p>
        </div>
    </div>
</template>


<script>

    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import _ from 'lodash'

    export default {
        name: "ErrorDisplay",
        components: {},
        props: {
            value: null,
            ident: null,
        },
        data: function () {
            return {
                errors: this.value,
                errorOutput: []
            }
        },
        created() {
            this.errorsOutput()
        },
        watch: {
            value: function (newVal, oldVal) {
                this.errorsOutput()
            }
        },
        methods: {
            errorsOutput: function () {
                var final = [];
                if (this.value !== undefined && this.ident != null && typeof this.value[this.ident] !== 'undefined') {
                    _.each(this.value[this.ident], function (obj) {
                        final.push(obj)
                    });

                }
                var count = 0;
                while (typeof this.value[this.ident + "." + count] !== 'undefined') {
                    _.each(this.value[this.ident + "." + count], function (obj) {
                        final.push(obj)
                    });
                    count++;
                }

                this.errorOutput = final;
            }
        },
        computed: {}
    }
</script>

<style scoped>

</style>
