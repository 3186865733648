
export const containsErrors = (response) => {
    return typeof response !== 'undefined' &&  typeof response.data !== 'undefined' &&  typeof response.data.errors !== 'undefined'
}

export const isEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return (true)
    }
    return (false)
}
export const isGuid = (guid) => {
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid))
    {
        return (true)
    }
    return (false)
}
export const appendObjectsTo = (objects,array,key = null) => {
    if(key == null) {
        key = 'id';
    }
    var finalArray = array;
    var primaryKeys = [];
    for(var i = 0; i < array.length; i++) {
        let obj = array[i];
        if(obj[key] !== undefined) {
            primaryKeys.push(obj[key]);
        }
    }
    for(i = 0; i < objects.length; i++) {
        let obj = objects[i];
        if(obj[key] !== undefined) {
            if(!primaryKeys.includes(obj[key])){
                finalArray.push(obj);
            }
        }
    }
    return finalArray;
}
export const roleToVariant = (status) => {
    switch(status) {
        case "App access":
            return "info";
        case "Admin":
            return "warning";
        case "Super":
            return "danger";
        case "Billing":
            return "success";
        default:
            return "dark";
    }
}

export const logToVariant = (status) => {
    switch(status) {
        case "login":
            return "success";
        case "login_failed":
            return "danger";
        case "logout":
            return "success";
        case "edit_tag":
            return "warning";
        case "delete_tag":
            return "danger";
        case "add_tag":
            return "success";
        case "edit_additional_doc":
            return "warning";
        case "add_additional_doc":
            return "success";
        case "delete_additional_doc":
            return "danger";
        case "add_user":
            return "success";
        case "edit_user":
            return "warning";
        case "delete_user":
            return "danger";
        case "json_downloaded":
            return "info";
        case "flight_path_step":
            return "warning";
            case "additional_features":
            return "warning";
        default:
            return "light";
    }
}

export const logToText = (status) => {
    switch(status) {
        case "login":
            return "Successful login";
        case "login_failed":
            return "Failed login";
        case "logout":
            return "Logout";
        case "edit_tag":
            return "Tag type edited";
        case "delete_tag":
            return "Tag type deleted";
        case "add_tag":
            return "Tag type added";
        case "edit_additional_doc":
            return "Additional document edited";
        case "add_additional_doc":
            return "Additional document added";
        case "delete_additional_doc":
            return "Additional document deleted";
        case "add_user":
            return "User added";
        case "edit_user":
            return "User edited";
        case "delete_user":
            return "User deleted";
        case "json_downloaded":
            return "Json export downloaded";
        case "flight_path_step":
            return "Flight path step confirmed";
        case "additional_features":
            return "Additional features edited";
        default:
            return "";
    }
}


export const statusToText = (status) => {
    switch(status) {
        case "in_progress":
            return "In progress";
        case "approved":
            return "Approved";
        case "flagged":
            return "Awaiting approval";
        case "rejected":
            return "Rejected";
        case "archived":
            return "Archived";
        default:
            return "Unknown";
    }
}
export const statusToVariant = (status) => {
    switch(status) {
        case "in_progress":
            return "info";
        case "approved":
            return "success";
        case "flagged":
            return "warning";
        case "rejected":
            return "danger";
        case "archived":
            return "light";
        default:
            return "dark";
    }
}
export const statusIcon = (status) => {
    switch(status) {
        case "in_progress":
            return "pencil";
        case "approved":
            return "check";
        case "flagged":
            return "alert-triangle";
        case "rejected":
            return "x";
        case "archived":
            return "folder";
        default:
            return "alert-circle";
    }
}

export const statusIsComplete = (status) => {
    if(status === "approved" || status === "flagged" || status === "rejected"){
        return true;
    }
    return false;
}

export const applicationActionToText = (action) => {
    switch(action) {
        case "pdf_batch":
            return "Downloaded as part of PDF batch";
        case "application_archived":
            return "Application archived";

        case "application_archive_at":
            return "Application set to auto archive";

        case "doc_check_manual":
            return "Manual doc check requested";

        case "doc_check_auto":
            return "Automated doc check requested";

        case "application_approved":
            return "Application approved";

        case "application_flagged":
            return "Application sent to supervisor";

        case "application_quick_edit":
            return "Application quick edit";

        case "application_rejected":
            return "Application rejected";

        case "application_started":
            return "Application started";

        case "application_uploaded_mobile":
            return "Application uploaded via mobile app";

        case "application_uploaded_web":
            return "Application uploaded via web app";

        case "application_on_hold":
            return "Application has been placed on hold";

        case "application_off_hold":
            return "Application has been taken off hold";

    }
    return action.replace("_"," ");
}
export const applicationActionShouldShowNotes = (action) => {
    switch(action) {
        case "pdf_batch":
            return false;
        case "application_archived":
            return false;

        case "application_archive_at":
            return true;

        case "doc_check_manual":
            return false;

        case "doc_check_auto":
            return false;

        case "application_approved":
            return false;

        case "application_flagged":
            return true;

        case "application_quick_edit":
            return true;

        case "application_rejected":
            return true;

        case "application_started":
            return false;

        case "application_uploaded_mobile":
            return false;

        case "application_uploaded_web":
            return false;

        case "application_on_hold":
            return false;

        case "application_off_hold":
            return false;

        default:
            return true;
    }
}
