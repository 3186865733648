<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-6">
                <div class="d-flex mb-3 align-items-center">
                    <img class="flex-wrap" src="../../assets/logo.png" style="width: 220px"/>
                    <h5 class="flex-fill m-0 ml-3 border-left pl-3">Account restricted</h5>
                </div>

                <div class="flex-wrap rounded p-4 d-block bg-white">
                    <p>Your account access has been revoked. Please contact your administrator to correct this.</p>

                    <div class="d-flex justify-content-between mt-4">
                        <b-button @click="() => {
                        this.$store.commit('logout');
                    this.$router.replace({'name': 'login'}); }" variant="outline-primary">Logout
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'awaitingSetup',
        data: function () {
            return {}
        },
        components: {},
        methods: {},
        computed: {},
        created() {

        }
    }
</script>
