<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="application-cell">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row no-gutters">
            <div class="col-5">
                <h5 class="mb-2">{{ item.first_name }} {{ item.middle_names }} {{ item.surname }}</h5>
                <h6 class="mb-1">ID: {{ item.ref }}, Ref: {{ item.job_ref }}</h6>
                <div v-if="!showExpiry">
                    <b-badge class="mr-1 mb-1" variant="light" v-for="(tag,tagKey) in item.tags" v-bind:key="tagKey">{{
                        tag.tag_type != null ? tag.tag_type.name : "unknown" }} : {{ tag.tag_value }}
                    </b-badge>
                </div>
                <div>
                    <b-badge variant="secondary" v-if="item.is_student === 1">
                        <b-icon icon="book"></b-icon>
                        STUDENT
                    </b-badge>
                    <b-badge v-if="item.archived_at != null" class="m-1 text-uppercase font-weight-bold pr-2"
                             style="font-size:12px;" variant="light">
                        <b-icon icon="folder"></b-icon>
                        Archived
                    </b-badge>
                    <application-status-badge class="m-1" :status="item.status"></application-status-badge>

<!--                    <b-badge class="m-1" :variant="item.users_count > 0 ? 'info' : 'danger'">-->
<!--                        <b-icon-people></b-icon-people>-->
<!--                        {{ item.users_count }} users assigned-->
<!--                    </b-badge>-->

                    <b-badge class="m-1" variant="warning" v-if="item.notes != null && item.notes.trim().length > 0">
                        Contains notes
                    </b-badge>

                    <b-badge v-if="item.archived_at == null && item.auto_archive_at != null" class="m-1"
                             variant="light">Auto archive on {{ dateToNice(item.auto_archive_at) }}
                    </b-badge>

                </div>
            </div>

            <div class="col-4" style="font-size: 12px">
                <div>
                    <strong>Nationality :</strong> {{ item.nationality != null ? item.nationality.name : "" }}
                </div>
                <div v-if="item.completed_at != null">
                    <strong>Uploaded on :</strong> {{ item.completed_at }}
                </div>
            </div>

            <div class="col-3 text-right">
                <b-button variant="outline-primary" size="sm" class="m-2" @click="onApplicationClick">
                    <b-icon-chevron-right></b-icon-chevron-right>
                    View application
                </b-button>
                <application-action-dropdown
                        v-if="!showExpiry"
                        :exclude-items="['pdf','json','edit']"
                        v-on:delete="() => { this.$emit('delete') }" :item="item"
                                             v-model="isLoading"></application-action-dropdown>
            </div>
        </div>
        <div class="mt-3 w-100" v-if="showExpiry">
            <b-table bordered striped hover :items="expiringCaptures"
                     :fields="['document_type','expiry']">
                <template v-slot:cell(document_type)="data">
                    {{ data.value.name }}
                </template>
                <template v-slot:cell(expiry)="data">
                    <b-badge :variant="expiryColor(data.value)">
                        {{ dateToNice(data.value) }}
                    </b-badge>
                </template>
            </b-table>
        </div>

    </div>
</template>
<script>
    import ApplicationStatusBadge from "./ApplicationStatusBadge";
    import {statusIsComplete} from "../../modules/helpers/helpers";
    import CellLoader from "../ui/CellLoader";
    import ApplicationActionDropdown from "./ApplicationActionDropdown";
    import moment from "moment";

    export default {
        name: "ApplicationCell",
        components: {ApplicationActionDropdown, CellLoader, ApplicationStatusBadge},
        props: {
            item: {},
            showExpiry: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                isLoading: false,
            }
        },
        computed: {

            isComplete: function () {
                return statusIsComplete(this.item.status)
            },
            expiringCaptures: function() {
                return this.item.captures.filter(function(obj) {
                    return obj.expiry !== null;
                });
            }
        },
        methods: {
            expiryColor(date){
                var duration =  moment.duration(moment(date).diff(new moment()));
                if(duration.asDays() < 0){
                    return 'danger';
                } else if(duration.asDays() > 30) {
                    return 'success';
                } else if(duration.asDays() > 0) {
                    return 'warning';
                }
                return 'light';
            },
            onApplicationClick() {
                console.log("clicked");
                this.$router.push({name: 'applicationDetail', params: {applicationId: this.item.id}}).catch(error => {})
            },
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY");
            },
        }

    }
</script>

<style scoped>

</style>
