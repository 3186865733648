import { render, staticRenderFns } from "./DocumentTypeCell.vue?vue&type=template&id=5066971d&scoped=true&"
import script from "./DocumentTypeCell.vue?vue&type=script&lang=js&"
export * from "./DocumentTypeCell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5066971d",
  null
  
)

export default component.exports