<template>
    <div>
        <div class="container mt-2">
            <div class="p-3 d-flex align-items-center">
                <img v-if="!this.$store.getters.isWorkspace" src="./../assets/logo.png" width="220" class="mb-2"/>
                <h1 :class="this.$store.getters.isWorkspace ? 'h5 px-3' : 'h5 ml-3 border-left px-3'">
                    Configuration wizard
                </h1>

                <div v-if="!this.$store.getters.isWorkspace" class="flex-fill text-right">

                    <div>Logged in as <strong>{{ this.$store.state.user.email }}</strong> | <a href="#" @click="logout" size="sm" >Logout</a></div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="">
                    <div class="row no-gutters row-deck">
                        <div class="col-3 bg-light p-3 border-right">
                            <b-nav vertical pills>
                                <b-nav-item :to="{ name: 'setup_welcome'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_welcome'">Welcome
                                </b-nav-item>

                                <b-nav-item :disabled="false" :to="{ name: 'setup_company_details'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_company_details'">
                                    Company details
                                </b-nav-item>

                                <b-nav-item :disabled="this.$store.state.user.organisation == null || this.$store.state.user.organisation.address_line_1 == null" :to="{ name: 'setup_system_setup'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_system_setup'">
                                    System setup
                                </b-nav-item>

                                <b-nav-item :disabled="this.$store.state.user.organisation == null || this.$store.state.user.organisation.setup_system_at == null" :to="{ name: 'setup_users_setup'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_users_setup'">
                                    User setup
                                </b-nav-item>

                                <b-nav-item :disabled="this.$store.state.user.organisation == null || this.$store.state.user.organisation.setup_users_at == null" :to="{ name: 'setup_contract'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_contract'">Privacy
                                    agreement & contract
                                </b-nav-item>

                                <b-nav-item :disabled="this.$store.state.user.organisation == null || this.$store.state.user.organisation.setup_contract_at == null" :to="{ name: 'setup_payment'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_payment'">
                                    Payment
                                </b-nav-item>


                                <b-nav-item :disabled="this.$store.state.user.organisation == null || this.$store.state.user.organisation.setup_payment_at == null"
                                            :to="{ name: 'setup_launch'}"
                                            v-bind:active="this.$router.currentRoute.name === 'setup_launch'">
                                    Launch
                                </b-nav-item>

                            </b-nav>
                        </div>
                        <div class="col-9">
                            <router-view/>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
    import Vue from "vue";

    export default {
        name: 'setup',
        created() {
            let org = this.$store.state.user.organisation;
            if(org != null && org.setup_payment_at != null) {
                this.$router.push({name:'setup_launch'}).catch(error => {});
                return
            }
            if(org != null && org.setup_contract_at != null) {
                this.$router.push({name:'setup_payment'}).catch(error => {});
                return
            }
            if(org != null && org.setup_users_at != null) {
                this.$router.push({name:'setup_contract'}).catch(error => {});
                return
            }
            if(org != null && org.setup_system_at != null) {
                this.$router.push({name:'setup_users_setup'}).catch(error => {});
                return
            }
            if(org != null && org.address_line_1 != null) {
                this.$router.push({name:'setup_system_setup'}).catch(error => {});
                return
            }
            this.$router.push({name:'setup_welcome'}).catch(error => {});
        },
        methods: {
            logout() {
                if(!this.$store.getters.isWorkspace) {
                    //VERIFY WHICH TYPE OF TOKEN AS COULD BE OPEN ID TOKEN
                    this.$store.commit('logout');
                    this.$router.replace({'name': 'home'}).then(() => {
                        this.$root.$children[0].showLogoutMessage();
                    }).catch((err) => {});
                }
            },
        },
        components: {},
    }
</script>

