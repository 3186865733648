<template>
    <loader-panel :isLoading="isLoading">
        <div class="text-left">

            <b-form-group class="mb-4"
                          label="Job reference *:">
                <b-form-input
                        v-model="application.job_ref"
                        type="text"
                        placeholder="Enter job reference"
                ></b-form-input>
                <error-display v-model="errors" ident="job_ref"></error-display>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group label="First name *:">
                        <b-form-input
                                v-model="application.first_name"
                                type="text"
                                placeholder="Candidate first name"
                        ></b-form-input>
                        <error-display v-model="errors" ident="first_name"></error-display>
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group
                            label="Middle names:">
                        <b-form-input
                                v-model="application.middle_names"
                                type="text"
                                placeholder="Candidate middle names"
                        ></b-form-input>
                        <error-display v-model="errors" ident="middle_names"></error-display>
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group
                            label="Surname *:">
                        <b-form-input
                                v-model="application.surname"
                                type="text"
                                placeholder="Candidate surname"
                        ></b-form-input>
                        <error-display v-model="errors" ident="surname"></error-display>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col v-if="this.$store.state.user.organisation.doc_check_mode !== 'off'">
                    <b-form-group
                            :label="this.$store.state.user.organisation.doc_check_mode !== 'off' ? 'Dob *:' : 'Dob:'">
                        <masked-input class="form-control"
                                      v-model="application.dob"
                                      mask="11/11/1111"
                                      placeholder="dd/mm/yyyy"
                        />
                        <error-display v-model="errors" ident="dob"></error-display>
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group
                            label="Nationality *:">
                        <b-select
                                v-model="application.nationality_id"
                        >
                            <optgroup v-for="(group,key) in nationalities" v-bind:label="group.name" v-bind:key="key">
                                <option v-for="(nationality,nationalityKey) in group.nationalities"
                                        v-bind:key="nationalityKey" :value="nationality.id">
                                    {{ nationality.name }}
                                </option>
                            </optgroup>
                        </b-select>

                        <error-display v-model="errors" ident="nationality_id"></error-display>
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="mt-4">
                <b-col v-for="tag in tags" v-bind:key="tag.id">

                    <b-form-group
                            :label="tag.name+(tag.required == 1 ? ' *' : '')+':'">
                        <b-form-input v-if="tag.input_type === 'free_entry'"
                                      v-model="tag.tag_value"
                                      type="text"
                                      :placeholder="tag.name"
                        ></b-form-input>

                        <vue-simple-suggest v-if="tag.input_type === 'autocomplete'"
                                            v-model="tag.tag_value"
                                            :list="tag.tags"
                                            :styles="autoCompleteStyle"
                                            value-attribute="id"
                                            :nullable-select="true"
                                            :filter-by-query="true">
                        </vue-simple-suggest>

                        <v-select v-if="tag.input_type === 'list'"
                                  value-field="id"
                                  text-field="tag_value"
                                  :filterable="true"
                                  :options="tag.tags"
                                  v-model="tag.tag_value">
                        </v-select>

                        <error-display v-model="errors" :ident="'tags.'+tag.id"></error-display>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="text-right mt-1">
                <div class="text-muted py-3">
                    * denotes required fields
                </div>
                <input type="hidden" v-if="application.id != null"
                       v-model="application.id"/>
                <b-button @click="saveForm" variant="success">Save and continue</b-button>
            </div>
        </div>
    </loader-panel>
</template>

<script>
    // @ is an alias to /src

    import VueSimpleSuggest from 'vue-simple-suggest'
    import ValidationInput from '../ValidationInput';
    import LoaderPanel from "../LoaderPanel";

    import 'vue-simple-suggest/dist/styles.css'
    import MaskedInput from "vue-masked-input/src/MaskedInput";
    import {getResource, saveResource} from "../../../modules/api/methods";
    import {appConfig, applicationSave} from "../../../modules/api/endpoints";
    import ErrorDisplay from "../../form_inputs/ErrorDisplay";
    import {containsErrors} from "../../../modules/helpers/helpers"; // Optional CSS
    import moment from 'moment';
    import vSelect from "vue-select"

    export default {
        name: 'basic_info',
        data: function () {
            return {
                isLoading: false,
                application: {},
                nationalities: [],
                tags: [],
                errors: {},
                autoCompleteStyle: {
                    vueSimpleSuggest: "position-relative",
                    inputWrapper: "",
                    defaultInput: "form-control",
                    suggestions: "position-absolute list-group z-1000",
                    suggestItem: "list-group-item"
                }
            }
        },
        components: {
            ErrorDisplay,
            MaskedInput,
            'validation-input': ValidationInput,
            'vue-simple-suggest': VueSimpleSuggest,
            'loader-panel': LoaderPanel,
            "v-select": vSelect
        },
        created() {
            this.loadForm();
            this.application = this.$store.state.application;
            if (this.application.dob != null && moment(this.application.dob, "YYYY-MM-DD").format("YYYY-MM-DD") === this.application.dob) {
                this.application.dob = moment(this.application.dob).format("DD/MM/YYYY");
                if(this.application.dob === "Invalid date"){
                    this.application.dob = null;
                }
            }
        },
        methods: {
            saveForm() {
                this.errors = {};
                this.isLoading = true
                var postData = this.application;
                console.log(postData);
                postData.tags = {};
                _.each(this.tags, function (tag) {
                    postData.tags[tag.id] = tag.tag_value;
                });
                saveResource(applicationSave, postData).then(response => {
                    this.$store.dispatch('updateApplication', response.data.success.data).then(() => {
                        this.$router.push({name: 'web_app_documents'}).catch(error => {})
                    });

                }).catch(error => {
                    console.log(error);
                    if (containsErrors(error)) {
                        this.errors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error, this.saveForm)
                    }
                }).finally(() => {
                    this.isLoading = false
                });
            },
            loadForm() {
                this.isLoading = true;

                getResource(appConfig).then(response => {
                    console.log(response);
                    this.nationalities = response.data.success.nationalities;
                    var tags = response.data.success.tags;
                    var tagFinal = [];
                    var app = this.$store.state.application;
                    //PROCESS TAGS
                    _.each(tags, function (tag) {
                        var row = {};
                        row.id = tag.id;
                        row.name = tag.name;
                        row.input_type = tag.input_type;
                        row.required = tag.required;
                        var selectedVal = app.tags !== undefined ? app.tags.filter(row => row.tag_type_id === tag.id) : [];
                        if (selectedVal.length > 0) {
                            row.tag_value = selectedVal[0].tag_value;
                        } else {
                            row.tag_value = null;
                        }
                        if (tag.tags != null) {
                            var options = [];
                            _.each(tag.tags, function (option) {
                                options.push(option.tag_value);
                            });
                            row.tags = options;
                        } else {
                            row.tags = [];
                        }
                        tagFinal.push(row);
                    });

                    this.tags = tagFinal;
                }).catch(error => {
                    console.log(error);
                    this.$root.$children[0].handleApiError(error, this.loadForm)
                }).finally(() => {
                    this.isLoading = false
                });
            }
        },
    }
</script>

