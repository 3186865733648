<template>
    <div style="max-height: 100%; max-width: 100%" class="d-flex align-items-center justify-content-center flex-column">
        <div v-if="isLoading" class="d-flex align-items-center justify-content-center w-100 position-relative"
             style="padding-bottom: 100%">
            <b-spinner style="position: absolute; left:50%; top: 50%; margin-left:-10px; margin-top:-10px;"
                       variant="primary" small label="Loading"></b-spinner>
        </div>
        <div id="image-enlarge-container" class="overflow-auto flex-fill position-relative ">
            <div v-if="disableEnlarge == null" class="image-enlarge-actions d-flex flex-column justify-content-between">
                <a class="flex-fill d-flex justify-content-center flex-column clickable" @click="downloadImage">
                    <span>
                    <b-icon-cloud-download></b-icon-cloud-download> Download
                        </span>
                </a>
                <a class="flex-fill d-flex justify-content-center flex-column enlarge clickable" @click="enlargeImage">
                    <span>
                    <b-icon-arrows-fullscreen></b-icon-arrows-fullscreen> Enlarge
                        </span>
                </a>
            </div>
            <img style="max-height: 100%; max-width: 100%" v-if="!isLoading && this.data != null"
                 v-on:click="enlargeImage" :src="this.data"
            />
            <img v-else-if="!isLoading" style="max-height: 100%; max-width: 100%"
                 src="../../assets/image_placeholder.png"
            />
        </div>


        <image-zoom v-if="disableEnlarge == null" v-model="enlarged" :src="src"
                    :secondary-src="secondarySrc"></image-zoom>
    </div>
</template>

<script>

    import {getResource} from "../../modules/api/methods";
    import {downloadUpload, getUpload, userSingleUse} from "../../modules/api/endpoints";
    import ImageZoom from "./ImageZoom";


    export default {
        name: "image-view",
        components: {
            'image-zoom': ImageZoom
        },
        data: function () {
            return {
                enlarged: false,
                isLoading: false,
                data: null,
                sizeUsed: this.size
            }
        },
        props: {
            size: null,
            src: null,
            secondarySrc: null,
            disableEnlarge: null
        },
        mounted: function () {
            if (this.sizeUsed == null) {
                this.sizeUsed = 'thumb';
            }
            if (this.src != null && this.src.length > 0) {
                this.loadImage();
            }
        },
        watch: {
            src: function (newVal, oldVal) {
                this.data = null;
                if (newVal != null) {
                    this.loadImage();
                }
            }
        },
        methods: {
            downloadImage() {
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {

                    window.open(downloadUpload(this.src,'large',resp.data.success.token), '_blank');

                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            enlargeImage() {
                if (this.disableEnlarge != null) return;
                console.log("enlargind");
                this.enlarged = true;
            },
            loadImage() {
                this.isLoading = true;
                getResource(getUpload(this.src, this.sizeUsed)).then((resp) => {
                    this.data = "data:image/jpeg;base64," + resp.data.success.base_64;
                }).catch((err) => {

                }).finally(() => {
                    this.isLoading = false;
                })
            }
        }
    }
</script>
