export const baseApi = () => {
    return baseUrl() + "api/"
}
export const baseUrl = () => {
    let hostname = location.hostname;
    if (hostname === "rtw.com") {
        return "https://api.rtw.com/";
    } else if (hostname === "staging.app.accessrtw.co.uk") {
        return "https://staging.api.accessrtw.co.uk/";
    } else if (hostname === "app.stagingrtw.accessacloud.com") {
        return "https://api.stagingrtw.accessacloud.com/";
    } else if (hostname === "preprod.app.accessrtw.co.uk") {
        return "https://preprod.api.accessrtw.co.uk/";
    } else if (hostname === "app.preprodrtw.accessacloud.com") {
        return "https://api.preprodrtw.accessacloud.com/";
    } else if (hostname === "demo.app.accessrtw.co.uk") {
        return "https://demo.api.accessrtw.co.uk/";
    } else if (hostname === "app.demortw.accessacloud.com") {
        return "https://api.demortw.accessacloud.com/";
    } else if (hostname === "app.accessrtw.co.uk") {
        return "https://api.accessrtw.co.uk/";
    } else if (hostname === "app.rtw.accessacloud.com") {
        return "https://api.rtw.accessacloud.com/";
    }
    return "https://api.accessrtw.co.uk/";
}
export const login = baseApi() + "token";
export const register = baseApi() + "register";
export const userValidate = (token) => {
    return baseApi() + "user-validate/" + token
};
export const ssoCheck = baseApi() + "sso-check";
export const ssoAuthorize = baseUrl() + "openid/authorize";
export const ssoAuthorizeSilent = baseUrl() + "openid/authorize/silent";
export const ssoAuthorizeHint = (email) => {
    return baseUrl() + "openid/authorize/" + email
};
export const ssoAuthorizeRegister = (email) => {
    return baseUrl() + "openid/authorize/register"
};

export const applicationFilters = baseApi() + "application-filters";
export const applications = baseApi() + "applications";

export const serialize = function (obj, prefix) {
    var str = [],
        p;
    for (p in obj) {

        console.log("Prop is ", p);
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            console.log("KEY IS", k);
            if (v !== null && typeof v === "object") {

            } else {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }

        }
    }
    return str.join("&");
}

export const transactionsExcel = (params) => {
    return baseApi() + "billing/transactions/excel?" + params;
};
export const invoicePDF = (params) => {
    return baseApi() + "billing/invoices/pdf?" + params;
};
export const paymentMethods = baseApi() + "billing/payment-methods";
export const setupIntent = baseApi() + "billing/setup-intent";
export const invoices = baseApi() + "billing/invoices";
export const invoicePay = (invoiceId) => {
    return baseApi() + "billing/invoices/" + invoiceId + "/pay"
};
export const unbilled = baseApi() + "billing/unbilled";

export const applicationsExcel = (params) => {
    return baseApi() + "applications/excel?" + params;
};
export const organisationsExcel = (params) => {
    return baseApi() + "organisations/excel?" + params;
};

export const applicationsCount = baseApi() + "applications/count";
export const applicationDetail = (applicationId) => {
    return baseApi() + "applications/" + applicationId
};

export const applicationUnarchive = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/unarchive"
};
export const applicationCancelArchive = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/cancel-archive"
};

export const applicationSave = baseApi() + "applications/save";
export const applicationDocuments = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/documents"
};
export const applicationStudent = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/student"
};
export const applicationUsers = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/users"
};
export const applicationAdditionalDocuments = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/additional-documents"
};
export const applicationQuestions = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/questions"
};
export const applicationPhoto = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/photo"
};
export const applicationClaim = (applicationId, claim) => {
    return baseApi() + "applications/" + applicationId + "/claim/" + claim
};
export const applicationStatus = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/status"
};
export const applicationOnHold = (applicationId, onHold) => {
    return baseApi() + "applications/" + applicationId + "/hold/" + onHold
};

export const applicationDocCheck = (applicationId) => {
    return baseApi() + "applications/" + applicationId + "/doc-check"
};
export const applicationPdf = (applicationId, token) => {
    return baseApi() + "applications/" + applicationId + "/pdf?token=" + token
};
export const applicationJson = (applicationId, token) => {
    return baseApi() + "applications/" + applicationId + "/json?token=" + token
};
export const applicationBatch = (action) => {
    return baseApi() + "applications/batch/" + action
};
export const applicationStats = baseApi() + "application-stats";
export const getUpload = (uploadId, size) => {
    return baseApi() + "upload/" + uploadId + "/" + size;
};
export const downloadUpload = (uploadId, size, token) => {
    return baseApi() + "upload-download/" + uploadId + "/" + size + "?token=" + token;
};
export const upload = baseApi() + "upload";

export const userCurrent = baseApi() + "user";
export const userCurrentNotifications = baseApi() + "user/notifications";
export const userCurrentPassword = baseApi() + "user/update-password";
export const userDetail = (id) => {
    return baseApi() + "users/" + id
};

export const userPasswordReset = (id) => {
    return baseApi() + "users/" + id + "/password-reset"
};
export const userResendLink = (id) => {
    return baseApi() + "users/" + id + "/resend-link"
};
export const tokenValidate = (token) => {
    return baseApi() + "token-validate?token=" + token
};
export const userPasswordToken = (token) => {
    return baseApi() + "user/password/token?token=" + token
};
export const userSingleUse = (isAdmin) => {
    return baseApi() + "user/single-use-token/" + isAdmin
};

export const clientSave = baseApi() + "api-clients/save";
export const clientDetail = (id) => {
    return baseApi() + "api-clients/" + id
};

export const usersDropdown = baseApi() + "users/dropdown";
export const userSave = baseApi() + "users/save";
export const users = baseApi() + "users";
export const userDeviceDetail = (id) => {
    return baseApi() + "user-device/" + id
};
export const userImpersonate = (id) => {
    return baseApi() + "users/" + id + "/impersonate"
};

export const tagTypeDetail = (id) => {
    return baseApi() + "tag-types/" + id
};
export const tagTypeSave = baseApi() + "tag-types/save";

export const additionalDocuments = baseApi() + "additional-documents";
export const additionalDocumentDetail = (id) => {
    return baseApi() + "additional-documents/" + id
};
export const additionalDocumentSave = baseApi() + "additional-documents/save";

export const documentTypes = baseApi() + "document-types";
export const documentTypeDetail = (id) => {
    return baseApi() + "document-types/" + id
};
export const documentTypeSave = baseApi() + "document-types/save";


export const organisationCurrent = baseApi() + "organisation";
export const organisationSetupCompanyDetails = baseApi() + "organisation/setup/company-details";
export const organisationSetupSystemSetup = baseApi() + "organisation/setup/system-setup";
export const organisationSetupUserSetup = baseApi() + "organisation/setup/user-setup";

export const organisationSetupContract = baseApi() + "organisation/setup/contract";
export const organisationSetupPayment = baseApi() + "organisation/setup/payment";
export const organisationSetupLaunch = baseApi() + "organisation/setup/launch";
export const organisationSetupUsers = baseApi() + "organisation/setup/users";
export const organisationSetupUserNameChange = (id) => {return baseApi() + "organisation/setup/users/"+id };
export const workspaceUsers = baseApi() + "workspace/users";
export const workspaceUserAdd = baseApi() + "workspace/users/add";
export const workspaceUserDelete = (id) => {
    return baseApi() + "workspace/users/" + id
};
export const organisationSetupUsersDelete = (id) => {
    return baseApi() + "organisation/setup/users/" + id
};
export const organisationDetail = (id) => {
    return baseApi() + "organisations/" + id
};
export const organisationsDropdown = baseApi() + "organisations/dropdown";
export const organisationArchive = (id) => {
    return baseApi() + "organisations/" + id + "/archive"
};
export const organisationSave = baseApi() + "organisations/save";

export const nationalityGroups = baseApi() + "nationality-groups/dropdown";
export const nationalityGroupDetail = (id) => {
    return baseApi() + "nationality-groups/" + id
};
export const nationalityGroupSave = baseApi() + "nationality-groups/save";

export const nationalityDetail = (id) => {
    return baseApi() + "nationalities/" + id
};
export const nationalitySave = baseApi() + "nationalities/save";

export const supportCategories = baseApi() + "support-categories/dropdown";
export const faqDetail = (id) => {
    return baseApi() + "faqs/" + id
};
export const faqSave = baseApi() + "faqs/save";
export const faqs = baseApi() + "faqs";

export const announcementDetail = (id) => {
    return baseApi() + "announcements/" + id
};
export const announcementDismiss = (id) => {
    return baseApi() + "announcements/" + id + "/dismiss"
};
export const announcementsDue = baseApi() + "announcements-due";
export const announcementSave = baseApi() + "announcements/save";
export const announcements = baseApi() + "announcements";

export const costModel = baseApi() + "cost-model";
export const basicSettings = baseApi() + "basic-settings";
export const accountCode = (token) => { return baseApi() + "billing/account-code/"+token };
export const tour = baseApi() + "tour";

export const questions = baseApi() + "questions";
export const questionDetail = (id) => {
    return baseApi() + "questions/" + id
};
export const questionSave = baseApi() + "questions/save";
export const appConfig = baseApi() + "app-config";
export const flightpaths = baseApi() + "flight-paths/dropdown";
export const flightpathConfirm = baseApi() + "flight-path/confirm";

export const analyticsIsWorkspace = baseApi() + "analytics/is-workspace";
export const analyticsSignupDropOff = baseApi() + "analytics/signup-drop-off";
export const analyticsLaunchCount = baseApi() + "analytics/organisation-launch-count";
export const analyticsApplicationsCount = baseApi() + "analytics/applications-charged-count";
export const analyticsRevenue = baseApi() + "analytics/revenue";
export const analyticsRevenueTotal = baseApi() + "analytics/revenue-total";
