<template>
    <div class="">
        <div class="border-bottom">
            <div class="p-5">
                <h2 class="mb-3">Admin training</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="adminPlayerOptions"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                        <p>The Admin Training will show you how to setup your Right to Work system.
                        <p>
                        <p>The training will cover:</p>
                        <ul>
                            <li>How to login to the web application</li>
                            <li>Managing your users</li>
                            <li>Managing tags</li>
                            <li>Managing documents</li>
                            <li>Managing additional features</li>
                            <li>Viewing the config audit log</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class=" p-5 bg-white">
                <h2 class="mb-3">User training</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="userPlayerOptions"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                        <p>The User Training will show you how to use your Right to Work system and the product
                            functionality.</p>
                        <p>The training will cover:</p>

                        <ul>
                            <li>How to conduct Right to Work checks in the mobile application</li>
                            <li>The web application for Supervisors</li>
                            <li>How to conduct Right to Work checks in the web application</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="p-5 ">
                <h2 class="mb-3">Mobile only training</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="mobilePlayerOptions"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                        <p>The Mobile Training will show you a step by step guide on how to conduct Right to Work checks
                            with
                            your applicants on the mobile application.</p>
                    </div>
                </div>
            </div>

            <div class=" p-5 bg-white">
                <h2 class="mb-3">How to sign in to the system using Access Workspace</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video1Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 ">
                <h2 class="mb-3">How to start and complete an application</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video2Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 bg-white">
                <h2 class="mb-3">How to perform a document check</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video3Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 ">
                <h2 class="mb-3">Managing users existing Access Workspace customers</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video4Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 bg-white">
                <h2 class="mb-3">Managing users for non Access Workspace customers</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video5Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 ">
                <h2 class="mb-3">Billing tab</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video6Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>

            <div class="p-5 bg-white">
                <h2 class="mb-3">Support</h2>
                <div class="row align-items-center">
                    <div class="col-6">
                        <video-player class="video-player-box w-100 mt-3 mb-3"
                                      ref="videoPlayer"
                                      :options="video7Options"
                                      :playsinline="true"></video-player>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<style>
    .video-js {
        width: 100% !important;
    }
</style>

<script>
    import {videoPlayer} from 'vue-video-player'
    import 'video.js/dist/video-js.css';

    export default {
        name: "SupportMedia",
        components: {
            videoPlayer
        },
        data() {
            return {
                adminPlayerOptions: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://screening-screencasts.s3.eu-west-2.amazonaws.com/RTW/admin_training.mp4"
                    }],
                },
                userPlayerOptions: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://screening-screencasts.s3.eu-west-2.amazonaws.com/RTW/user_training.mp4"
                    }],
                },
                video1Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/How_to_log_in_to_the_system_using_workspace.mp4"
                    }],
                },
                video2Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/How_to_start_and_complete_an_application.mp4"
                    }],
                },
                video3Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/How_to_perform_a_document_check.mp4"
                    }],
                },
                video4Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/Managing_users_existing_workspace_customers.mp4"
                    }],
                },
                video5Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/Managing_users_non-workspace_customers.mp4"
                    }],
                },
                video6Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/Billing_tab.mp4"
                    }],
                },
                video7Options: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://rtw-training-videos.s3.eu-west-2.amazonaws.com/Support.mp4"
                    }],
                },
                mobilePlayerOptions: {
                    // videojs options
                    width: '100%',
                    height: 300,
                    muted: false,
                    language: 'en',
                    playbackRates: [1.0],
                    sources: [{
                        type: "video/mp4",
                        src: "https://screening-screencasts.s3.eu-west-2.amazonaws.com/RTW/mobile_training.m4v"
                    }],
                }
            }
        },
    }
</script>


