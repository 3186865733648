<template>
    <div class="flex-fill d-flex flex-column justify-content-center bg-light">
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-6">
                <div class="d-flex mb-3 align-items-center">
                    <img class="flex-wrap" src="../../assets/logo.png" style="width: 220px"/>
                    <h5 class="flex-fill m-0 ml-3 border-left pl-3">Select workspace</h5>
                </div>


                <surround-loader :is-loading="isLoading" class="flex-wrap rounded p-4 d-block bg-white">
                    <p>Please select an account to continue with.</p>

                    <div v-for="organisation in this.$store.state.user.organisation_users" v-bind:key="organisation.id">
                        <div class="p-2 px-3 font-weight-bold border rounded mb-2 clickable d-block">
                            <b-form-radio
                                    v-model="organisationId"
                                    class=""
                                    :value="organisation.organisation_id"
                            >{{ organisation.organisation_name }}
                            </b-form-radio>
                        </div>

                    </div>

                    <!--                    <div v-for="workspace in this.$store.state.user.workspace_organisations">-->
                    <!--                        <div v-if="workspace.admin == 1"-->
                    <!--                             class="p-2 px-3 font-weight-bold border rounded mb-2 clickable d-block">-->
                    <!--                            <b-form-radio-->
                    <!--                                    v-model="workspaceId"-->
                    <!--                                    class=""-->
                    <!--                                    :value="workspace.workspace_user_id"-->
                    <!--                            >{{ workspace.workspace_name }}-->
                    <!--                            </b-form-radio>-->
                    <!--                        </div>-->
                    <!--                        <div v-else class="p-2 px-3 font-weight-bold border rounded mb-2 d-block bg-light">-->
                    <!--                            {{ workspace.workspace_name }} - <span class="text-danger">Please contact an administrator of this organisation in order to setup Access Right to work</span>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div v-if="organisationId != null && this.$store.state.user.organisation_users.filter((obj) => { return obj.organisation_id === organisationId }).length > 0">
                        <div class="text-right">
                            <b-button @click="setup" variant="success">
                                Continue
                            </b-button>
                        </div>
                    </div>
                </surround-loader>
            </div>
        </div>
    </div>
</template>

<script>
    import {getResource} from "../../modules/api/methods";
    import {userCurrent} from "../../modules/api/endpoints";
    import SurroundLoader from "../../components/SurroundLoader";

    export default {
        name: 'workspaceSelect',
        data: function () {
            return {
                workspaceId: null,
                organisationId: null,
                isLoading: false,
                error: false,
            }
        },
        components: {SurroundLoader},
        methods: {
            setup() {
                this.isLoading = true;
                this.$router.replace({ path : '/workspace-select', query: { session_organisation_id : this.organisationId }}).then(() => {
                    //UPDATE THE USER
                    this.$store.dispatch('refreshUserCall').then((resp) => {
                        console.log("NEW USER",resp);
                        this.$router.push({path: '/dashboard', query: { session_organisation_id : this.organisationId }}).catch((err) => {});
                        this.isLoading = false;
                    }).catch((err) => {
                        this.isLoading = false;
                    });
                }).catch((err) => {
                    this.$store.dispatch('refreshUserCall').then(() => {
                        this.$router.push({path: '/dashboard', query: { session_organisation_id : this.organisationId }}).catch((err) => {});
                        this.isLoading = false;
                    }).catch((err) => {
                        this.isLoading = false;
                    });
                    this.isLoading = false;
                });



            }
        },
        computed: {},
        created() {
            this.organisationId = this.$store.state.organisationId;
        }
    }
</script>
