<template>
    <div class="user-cell p-3 border-bottom position-relative" :style="!hasAccess ? 'background-color:#ffe9ec;' : ''">
        <cell-loader :is-loading="isLoading"></cell-loader>
        <div class="row">
            <div class="col">
                <div class="d-flex align-items-center">
                    <h5 class="mb-1">{{ item.name }}
                    </h5>
                    <b-badge class="ml-2 mb-1" :variant="item.user_type == 'supervisor' ? 'dark' : 'info'">
                        {{ item.user_type }}
                    </b-badge>
                </div>
                <h6 class="mb-1">{{ item.email }}</h6>
                <div>
                    <div v-if="hasAccess">
                        <b-badge :variant="roleVariant(role.name)" class="mr-1 mb-1" v-for="(role,key) in item.roles"
                                 v-bind:key="key">{{ role.name }}
                        </b-badge>
                    </div>
                    <div v-else>
                        <b-badge variant="danger" size="lg">User has no access</b-badge>
                    </div>
                </div>

            </div>
            <div class="flex-wrap text-right">
                <b-dropdown size="sm" right text="Actions" variant="light" class="m-2" id="user-actions">
                    <b-dropdown-item
                            @click="(ev) => { ev.preventDefault(); this.$router.push({ name : (!this.$store.getters.isOrgSetup ? 'setupUserEdit' : (this.$router.currentRoute.name.indexOf('Admin') !== -1 ? 'userAdminEdit' : 'userEdit')), params : { userId : this.item.id }}).catch(error => {}) }"
                            href="#">Edit user
                    </b-dropdown-item>

                    <b-dropdown-item
                            v-if="this.$store.getters.isOrgSetup"
                            @click="resendLink(item.id)">Resend invite link
                    </b-dropdown-item>

                    <b-dropdown-item @click="(ev) => { ev.preventDefault(); this.deleteModel(item.id);}" href="#">Delete
                        user
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div v-if="item.confirmed !== 1" class="badge mt-2 badge-danger mb-0">
            This user needs to confirm their email address to login
        </div>
    </div>
</template>
<script>

    import {roleToVariant} from "../../modules/helpers/helpers";
    import {deleteResource, getResource} from "../../modules/api/methods";
    import {userDetail, userImpersonate, userPasswordReset, userResendLink} from "../../modules/api/endpoints";
    import CellLoader from "../ui/CellLoader";
    import impersonateMixin from "../mixins/impersonateMixin";

    export default {
        name: "UserPendingCell",
        components: {CellLoader},
        props: {
            item: {}
        },
        mixins : [impersonateMixin],
        data: function () {
            return {
                isLoading: false,
            }
        },
        methods: {
            roleVariant: function (role) {
                return roleToVariant(role)
            },
            deleteModel: function (userId) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            this.isLoading = true;
                            deleteResource(userDetail(userId)).then((resp) => {
                                this.isLoading = false;
                                this.$emit('delete');
                            }).catch((err) => {
                                this.isLoading = false;
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },

            resendLink: function (id) {
                this.isLoading = true;
                getResource(userResendLink(id)).then((resp) => {
                    this.isLoading = false;
                    this.$bvToast.toast('Invite link re-sent to user', {
                        title: 'Success',
                        variant: 'success',
                        solid: false
                    })
                }).catch((err) => {
                    this.isLoading = false;
                    this.$root.$children[0].handleApiError(err, () => {
                        return this.resendLink(id)
                    });
                })
            }
        },
        computed: {
            hasAccess: function () {
                var hasAccess = false;
                _.each(this.item.roles, function (role) {
                    if (role.app_access === 1) hasAccess = true;
                });
                return hasAccess;
            }
        }

    }
</script>

<style scoped>

</style>
